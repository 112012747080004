import { useState } from "react";
import { useData } from "../contexts/DataContext";
import { useEffect } from "react";
import { Loader } from "./Loader";
import { TabPanel, TabContext } from '@mui/lab';
import { Tabs, Tab } from '@mui/material';
import { toast } from "react-hot-toast";
const SecondBrain = () => {
    
    const [generatedSpoV, setGeneratedSpoV] = useState(null);

    const [isGeneratingSpoV, setIsGeneratingSpoV] = useState(false); 
    const [tabValue, setTabValue] = useState(0);
    const [condensedSpoV, setCondensedSpoV] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false); // State to control modal visibility

    const { activeProjectId, ProjectsAPI } = useData();

    useEffect(() => {
        const getLatestSpoV = async () => {
            const spovs = await ProjectsAPI.getSpoVs(activeProjectId);
            if(spovs) {
                setCondensedSpoV(spovs.filter(spov => spov.status === "ACCEPTED").map(spov => spov.text).join("\n\n"));
            }
        }
        if(activeProjectId) {
            getLatestSpoV();
        }
    }, [activeProjectId, ProjectsAPI]);

    const handleUserInputChange = (e) => {
        e.preventDefault();
        setGeneratedSpoV({...generatedSpoV, user_input: e.target.value});
    }

    const deleteSpoV = async () => {
        if (!showDeleteModal) return;
        await ProjectsAPI.deleteSpoV(activeProjectId);
        toast.success("Deleted successfully");
        setCondensedSpoV("");
        setShowDeleteModal(false);
    }

    const handleGenerateSpoV = async () => {
        if(generatedSpoV.user_input.trim() === "") {
            return;
        }
        setIsGeneratingSpoV(true);
        setGeneratedSpoV((prevState) => ({...prevState, ai_generated_spov: []}));
        const spov = await ProjectsAPI.generateSpoV(activeProjectId, generatedSpoV);
        if(spov.ai_generated_spov) {
            setGeneratedSpoV(spov);
        }
        setIsGeneratingSpoV(false); 
    }

    const handleSelection = (index, accepted) => {
        setGeneratedSpoV(prevState => ({
            ...prevState,
            ai_generated_spov: prevState.ai_generated_spov.map((spov, i) => i === index ? {...spov, status: accepted ? "ACCEPTED" : "REJECTED"} : spov)
        }));
    }

    const saveGeneratedSpoV = async () => {
        // Only save the accepted SPOVs
        const spov = await ProjectsAPI.saveSpoVs(activeProjectId, {user_input: generatedSpoV.user_input.trim(), ai_generated_spov: generatedSpoV.ai_generated_spov.filter(spov => spov.status === "ACCEPTED")});
        if(spov) {
            setCondensedSpoV((prevState) => spov.ai_generated_spov.filter(spov => spov.status === "ACCEPTED").map(spov => spov.text).join("\n\n") + "\n\n" + prevState);
        }
        toast.success("Saved successfully");
        setGeneratedSpoV(null);
        setTabValue(1);
    }


    return (  
        <div style={{ display: "flex", gap: "20px", justifyContent: "space-between", width: "100%", marginTop: "40px", borderRadius: "4px" }}>
            {isGeneratingSpoV && <div style={{ position: "absolute", top: "20%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 10 }}>
                <Loader />
            </div>}
            <div className="form-group" style={{ flex: 1 }}>
                <h2>Spiky Point of View</h2>
                <TabContext value={tabValue}>
                    <Tabs
                        value={tabValue}
                        onChange={(e, value) => setTabValue(value)}
                        sx={{
                            minHeight: '32px',
                            '& .MuiTab-root': {
                                minHeight: '32px',
                            },
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#5A155D',
                            }
                        }}
                    >
                        <Tab sx={{ textTransform: "none", padding: "0 12px" }} label="Add SPOV" />
                        <Tab sx={{ textTransform: "none", padding: "0 12px" }} label="View SPOV" />
                    </Tabs>
                    
                    {tabValue === 0 && (
                        <TabPanel sx={{ paddingLeft: "0", paddingRight: "0" }} value={0}>
                            <textarea
                                id="add-context"
                                value={generatedSpoV?.user_input || ""}
                                onChange={handleUserInputChange}
                                placeholder="Enter text to generate a SPOV."
                                rows={10}
                                maxLength="50000"
                                style={{ minHeight: "330px" }}
                            />
                            <button disabled={isGeneratingSpoV} type="button" style={{ fontWeight: "500" }} onClick={handleGenerateSpoV}>Generate SPOV</button>

                        </TabPanel>
                    )}

                    {tabValue === 1 && (
                        <TabPanel value={1}
                            sx={{ paddingLeft: "0", paddingRight: "0" }}
                        >
                            <textarea
                                readOnly
                                id="user-spov"
                                value={condensedSpoV}
                                placeholder="You will see the condensed SPOVs here which will be used as context by default."
                                rows={10}
                                maxLength="50000"
                                style={{ minHeight: "330px" }}
                            />
                            <button disabled={condensedSpoV.trim() === ""} type="button" style={{ fontWeight: "500" }} onClick={() => setShowDeleteModal(true)}>Delete</button>
                        </TabPanel>
                    )}
                </TabContext>
            </div>

            <div className="form-group" style={{ flex: 1, paddingTop: "54px"}}>
                <h2>AI Generated SPOV</h2>
                <div style={{ display: "flex", border: "1px solid #ccc", borderRadius: "4px", padding: "10px" }}>
                    <div style={{ maxHeight: "310px", overflowY: "auto", width: "100%" }}>
                        {generatedSpoV?.ai_generated_spov?.length > 0 ? generatedSpoV.ai_generated_spov.map((spov, index) => (
                            <div key={index} style={{ 
                                border: "1px solid #ccc", 
                                borderRadius: "4px", 
                                padding: "8px", 
                                backgroundColor: "#f8f8f8",
                                margin: index === 0 ? "0" : "8px 0",
                                ...(spov.status === "ACCEPTED" ? { border: "2px solid #5A155D" } : (spov.status === "REJECTED" ? { color: "lightgray" } : { border: "1px solid #ddd" })),
                            }}>
                                <div>
                                    <p style={{ paddingRight: "40px" }}>{spov.text}</p>
                                </div>
                                <div style={{ textAlign: "right", display: "flex", justifyContent: "flex-end" }}>
                                    <button type="button" style={{ fontSize: "8px", padding: "2px 4px", border: `${spov.status === "ACCEPTED" ? "1px solid #5A155D" : "1px solid #DCC4F8"}` }} onClick={() => handleSelection(index, true)}>Accept</button>
                                    <button type="button" style={{ fontSize: "8px", padding: "2px 4px", marginLeft: "4px", border: `${spov.status === "REJECTED" ? "1px solid #5A155D" : "1px solid #DCC4F8"}` }} onClick={() => handleSelection(index, false)}>Reject</button>
                                </div>
                                
                            </div>
                        )) : <div style={{ display: "flex", maxWidth: "100%", width: "100%", borderRadius: "4px", padding: "10px", justifyContent: "center" }}>No SPOV generated yet!</div> }
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "5px" }}>
                    {generatedSpoV?.ai_generated_spov?.length > 0 && <button type="button" style={{ fontWeight: "500" }}  onClick={saveGeneratedSpoV}>Save</button>}
                </div>

            </div>
            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <div className="_channel-modal">
                <div className="_channel-modal-content">
                    <h2>Confirm SPOV Deletion</h2>
                    <p>Are you sure you want to delete this SPOV?</p>
                    <p className="text-sm text-gray-600">
                        This action is permanent, and the SPOV will be deleted from the project.
                    </p>
                    <div className="_channel-modal-buttons">
                        <button
                            onClick={() => setShowDeleteModal(false)}
                            className="bg-gray-200 hover:bg-gray-300 text-gray-800"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={deleteSpoV}   
                            className="bg-red-500 hover:bg-red-600 text-white"
                        >
                            Delete
                        </button>
                    </div>
                </div>
                </div>
            )}
        </div>
    )
}

export default SecondBrain;