import { useState, useEffect } from 'react';
import useDrivePicker from '../drive-picker'
import { useData } from '@/contexts/DataContext';
import { Button } from "@/components/ui/button";
import { GoogleDriveIcon } from '@/components/ui/GoogleDriveIcon';
import { toast } from 'react-hot-toast';
import { useClerk } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';

const SECRET_KEY = import.meta.env.VITE_GOOGLE_API_KEY;

export function GoogleDrivePicker({ onFolderSelect }) {
  const { UsersAPI, googleStatus, handleAuthorizeGoogle } = useData();
  const [openPicker] = useDrivePicker();
  const [loading, setLoading] = useState(false);
  const { signOut } = useClerk();


  const handleOpenPicker = async () => {
    try {
      setLoading(true);
      const token = await UsersAPI.getGoogleOAuthToken();
      openPicker({
        clientId: "670169529294-bh35ocjutlqfu3p26fui10c2dolnig57.apps.googleusercontent.com",
        developerKey: SECRET_KEY,
        viewId: "DOCS",
        viewMimeTypes: "application/vnd.google-apps.folder",
        token: token,
        setSelectFolderEnabled: true,
        showUploadView: false,
        showUploadFolders: false,
        supportDrives: true,
        multiselect: false,
        callbackFunction: (data) => {
          setLoading(false);
          if (data.action === 'cancel') {
            console.log('User clicked cancel/close button')
          }
          console.log(data)
          if (data.action === 'picked' && data.docs && data.docs.length > 0) {
            onFolderSelect(data.docs[0].id);
          }
        },
      })
    } catch (error) {
      console.log("Error opening Google Drive picker:", error);
      if (error.message.includes("api.clerk") || error.message.includes("oauth2.googleapis.com") || error.message.includes("refresh_token")) {
        //const url = new URL(window.location.href).pathname;
        //console.log("Redirecting to sign in page");
        console.log("Authorizing Google anew");
        await handleAuthorizeGoogle();
        //await signOut({ redirectUrl: `/?redirect=${true}&from=${url}` });
      } else {
        toast.error(error.message);
      }
    }
  }


  return (
    <Button
      variant="outline"
      className={`w-full mb-2 ${loading ? "bg-gray-300 hover:bg-gray-300" : ""}`}
      onClick={googleStatus ? handleOpenPicker : handleAuthorizeGoogle}
    >
      <GoogleDriveIcon className={`mr-2 ${googleStatus ? "" : "grayscale"}`} width={20} height={20} />
      {googleStatus ? "Google Drive" : "Authorize"}
    </Button>
  );
}