import React, { useState, useRef, useEffect } from "react";
import { FaDownload } from "react-icons/fa";
import "../styles/BrainDumpMenu.scss"; // Import the styles
import ChatSwitch from "./ChatSwitch"; // Assuming ChatSwitch is the switch component used

const BrainDumpMenu = ({ onExport, showExportTarget = true, alignment = "right", projectId = null }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [exportTarget, setExportTarget] = useState("project");
  const [exportFormat, setExportFormat] = useState("txt");
  const [includeArtifacts, setIncludeArtifacts] = useState(true);
  const [includeSLMData, setIncludeSLMData] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const onExportClick = () => {
    if (typeof onExport === "function") {

      if (projectId !== null) {
        onExport(exportTarget.toLowerCase(),
          exportFormat.toLowerCase(),
          includeArtifacts,
          includeSLMData,
          projectId
        )
      }
      else {
        onExport(
          exportTarget.toLowerCase(),
          exportFormat.toLowerCase(),
          includeArtifacts,
          includeSLMData
        );
      }
    }
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (exportFormat === "txt") {
      setIncludeSLMData(false);
    }
  }, [exportFormat]);

  const handleSLMDataToggle = () => {
    setIncludeSLMData((prev) => {
      const newState = !prev;
      if (newState && exportFormat === "txt") {
        setExportFormat("json");
      }
      return newState;
    });
  };

  return (
    <div className="brain-dump-menu-container" ref={menuRef}>
      <button onClick={toggleMenu} className="brain-dump-button">
        <FaDownload className={`icon`} />
      </button>
      {isMenuOpen && (
        <div className={`brain-dump-menu ${alignment === "left" ? "brain-dump-menu-left" : ""}`}>
          {showExportTarget && (
            <div className="export-option">
              <div className="segmented-control">
                {[
                  { displayName: "Chat", value: "channel" },
                  { displayName: "Project", value: "project" }
                ].map(({ displayName, value }) => (
                  <button
                    key={value}
                    onClick={() => setExportTarget(value)}
                    className={`segmented-control-button ${
                      exportTarget === value ? "active-segment" : ""
                    }`}
                  >
                    {displayName}
                  </button>
                ))}
              </div>
            </div>
          )}
          <div className="export-option">
            <div className="segmented-control">
              {[
                { name: "Text", value: "txt" },
                { name: "JSON", value: "json" },
                { name: "XML", value: "xml" }
              ].map(({ name, value }, index) => (
                <button
                  key={value}
                  onClick={() => setExportFormat(value)}
                  className={`segmented-control-button ${
                    exportFormat === value || (index === 0 && exportFormat === "txt") ? "active-segment" : ""
                  }`}
                >
                  {name}
                </button>
              ))}
            </div>
          </div>
          <div className="export-option">
            <ChatSwitch
              onToggle={() => setIncludeArtifacts((prev) => !prev)}
              initialState={includeArtifacts}
              onLabel="Include Artifacts"
              offLabel="Exclude Artifacts"
            />
          </div>
          <div className="export-option">
            <ChatSwitch
              onToggle={handleSLMDataToggle}
              initialState={includeSLMData}
              onLabel="Include SLM Data"
              offLabel="Exclude SLM Data"
              disabled={exportFormat === "txt"}
            />
          </div>
          <button onClick={onExportClick} className="export-button">
            Download Chat(s)
          </button>
        </div>
      )}
    </div>
  );
};

export default BrainDumpMenu;