import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useData } from '../contexts/DataContext';

const ProjectCreate = () => {
  const [ name, setName ]             = useState('');
  const [ description, setDescription ] = useState('');
  const [ customInstructions, setCustomInstructions ] = useState(''); // New state for custom instructions

  const { ProjectsAPI } = useData();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newProject = await ProjectsAPI.addProject({ name, description, custom_instructions: customInstructions }); // Include customInstructions
    if (newProject?.project_id) {
      navigate(`/projects/${newProject.project_id}`);
    } else {
      console.error('Failed to create project');
    }
  };

  return (
    <div className="_project-create-container project-create-container" style={{ padding: '20px', backgroundColor: '#f0f2f5', borderRadius: '8px' }}>
      <h2 style={{ marginBottom: '20px', color: '#333' }}>Create New Project</h2>
      <form onSubmit={handleSubmit} className="project-create-form">
        <div className="_project-create-form-group">
          <label htmlFor="project-name" style={{ display: 'block', marginBottom: '5px', color: '#555' }}>Project Name</label>
          <input
            id="project-name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="input-field"
            style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}
          />
        </div>
        <div className="_project-create-form-group" style={{ marginTop: '15px' }}>
          <label htmlFor="project-description" style={{ display: 'block', marginBottom: '5px', color: '#555' }}>Description</label>
          <textarea
            id="project-description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="textarea-field"
            rows="4"
            style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}
          />
        </div>
        <div className="_project-create-form-group" style={{ marginTop: '15px' }}> {/* New form group for custom instructions */}
          <label htmlFor="custom-instructions" style={{ display: 'block', marginBottom: '5px', color: '#555' }}>Custom Instructions</label>
          <textarea
            id="custom-instructions"
            value={customInstructions}
            onChange={(e) => setCustomInstructions(e.target.value)}
            className="textarea-field"
            rows="10"
            style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}
            placeholder="Enter custom instructions for this project. You may also specify the the desired output format and style guide for Ephor to follow."
          />
        </div>
        <button 
          type="submit" 
          className="_project-create-submit-button"
          style={{ 
            marginTop: '20px', 
            padding: '10px 20px', 
            backgroundColor: '#4a90e2', 
            color: 'white', 
            border: 'none', 
            borderRadius: '4px', 
            cursor: 'pointer' 
          }}
        >
          Create Project
        </button>
      </form>
    </div>
  );
};

export default ProjectCreate;
