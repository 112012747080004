import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa'; // Chevron for the right

const ChatDropdown = ({ options, value, onChange, disabled, iconList, goesUp = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (!disabled) setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedOption = options.find(opt => opt.value === value);
  const selectedLabel = selectedOption?.label || "Select";

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="_custom-dropdown-button"
        disabled={disabled}
      >
        {/* Conditionally render the icon if passed for the selected option */}
        {selectedOption && iconList && iconList[selectedOption.value] && (
          <span className="mr-3">{iconList[selectedOption.value]}</span>
        )}
        {selectedLabel && <span>{selectedLabel}</span>}
        <span className={`ml-auto transform transition-transform ${isOpen ? "rotate-180" : ""}`}>
          <FaChevronDown />
        </span>
      </button>
      {isOpen && (
        <ul className={`_custom-dropdown-list ${goesUp ? 'up' : 'down'}`}>
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => handleSelect(option.value)}
              className="_custom-dropdown-list-item"
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ChatDropdown;
