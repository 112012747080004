import React, { useState } from 'react';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';

const FEEDBACK_CATEGORIES = [
  { id: 'inaccurate', label: 'Inaccurate', icon: '🎯' },
  { id: 'vague', label: 'Vague', icon: '❓' },
  { id: 'slow', label: 'Slow', icon: '🐢' },
  { id: 'other', label: 'Other', icon: 'ℹ️' },
];

const MessageFeedbackButton = ({ 
  messageId, 
  initialFeedback = {}, 
  onFeedbackSubmit,
  queryUserId,
  currentUserId
}) => {
  const [feedbackState, setFeedbackState] = useState(() => {
    if (initialFeedback.type === 'upvote') return 'upvoted';
    if (initialFeedback.type === 'downvote') return 'category-selected';
    return 'initial';
  });
  
  const [selectedCategory, setSelectedCategory] = useState(() => 
    initialFeedback.category ? 
      FEEDBACK_CATEGORIES.find(c => c.id === initialFeedback.category) : 
      null
  );
  
  const [otherFeedback, setOtherFeedback] = useState('');
  const [showModal, setShowModal] = useState(false);

  const canProvideFeedback = queryUserId === currentUserId;

  if (!canProvideFeedback) {
    return (
      <div className="feedback-buttons disabled">
        {feedbackState !== 'downvoted' && feedbackState !== 'category-selected' && (
          <button 
            disabled 
            className={`thumb-button ${feedbackState === 'upvoted' ? 'active' : ''}`} 
            aria-label="Thumbs up"
          >
            <FaThumbsUp size={16} />
          </button>
        )}

        {feedbackState !== 'upvoted' && (
          <button 
            disabled 
            className={`thumb-button ${feedbackState === 'downvoted' || feedbackState === 'category-selected' ? 'active negative' : ''}`} 
            aria-label="Thumbs down"
          >
            <FaThumbsDown size={16} />
          </button>
        )}

        {feedbackState === 'category-selected' && selectedCategory && (
          <span className="selected-category">
            {selectedCategory.label}
          </span>
        )}
      </div>
    );
  }

  const handleVote = (isUpvote) => {
    if ((isUpvote && feedbackState === 'upvoted') || 
        (!isUpvote && (feedbackState === 'downvoted' || feedbackState === 'category-selected'))) {
      setFeedbackState('initial');
      setSelectedCategory(null);
      setOtherFeedback('');
      onFeedbackSubmit({ messageId, type: 'remove' });
      return;
    }

    const newState = isUpvote ? 'upvoted' : 'downvoted';
    setFeedbackState(newState);
    
    if (isUpvote) {
      onFeedbackSubmit({ messageId, type: 'upvote' });
    }
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setShowModal(false);
    
    if (category.id === 'other') {
      setFeedbackState('other-input');
    } else {
      onFeedbackSubmit({
        messageId,
        type: 'downvote',
        category: category.id,
      });
      setFeedbackState('category-selected');
    }
  };

  const handleOtherFeedbackSubmit = () => {
    if (otherFeedback.trim()) {
      onFeedbackSubmit({
        messageId,
        type: 'downvote',
        category: 'other',
        details: otherFeedback,
      });
      setFeedbackState('category-selected');
    }
  };

  return (
    <div className="message-feedback-container">
      <div className="feedback-buttons">
        {feedbackState !== 'downvoted' && feedbackState !== 'category-selected' && (
          <button
            className={`thumb-button ${feedbackState === 'upvoted' ? 'active' : ''}`}
            onClick={() => handleVote(true)}
            aria-label="Thumbs up"
          >
            <FaThumbsUp size={16} />
          </button>
        )}

        {feedbackState !== 'upvoted' && (
          <>
            <button
              className={`thumb-button ${feedbackState === 'downvoted' || feedbackState === 'category-selected' ? 'active negative' : ''}`}
              onClick={() => {
                if (feedbackState === 'initial') {
                  handleVote(false);
                  setShowModal(true);
                } else if (feedbackState === 'downvoted' || feedbackState === 'category-selected') {
                  handleVote(false); // This will trigger the reset logic
                  setShowModal(false);
                }
              }}
              aria-label="Thumbs down"
            >
              <FaThumbsDown size={16} />
            </button>

            {feedbackState === 'downvoted' && !showModal && (
              <button
                className="send-feedback-button"
                onClick={() => setShowModal(true)}
              >
                Send feedback?
              </button>
            )}
          </>
        )}

        {feedbackState === 'category-selected' && selectedCategory && (
          <span className="selected-category">
            {selectedCategory.label}
          </span>
        )}
      </div>

      {showModal && (
        <div className="feedback-modal-backdrop">
          <div className="feedback-modal">
            <div className="modal-header">
              <h3>What went wrong?</h3>
              <button 
                className="close-button"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              >
                <IoClose size={20} />
              </button>
            </div>
            <div className="category-buttons">
              {FEEDBACK_CATEGORIES.map((category) => (
                <button
                  key={category.id}
                  className="category-button"
                  onClick={() => handleCategorySelect(category)}
                >
                  <span className="category-icon">{category.icon}</span>
                  {category.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      {feedbackState === 'other-input' && (
        <div className="other-feedback-input">
          <textarea
            placeholder="Please provide additional details..."
            maxLength={255}
            value={otherFeedback}
            onChange={(e) => setOtherFeedback(e.target.value)}
          />
          <button
            className="submit-other-feedback"
            onClick={handleOtherFeedbackSubmit}
          >
            Send feedback
          </button>
        </div>
      )}
    </div>
  );
};

export default MessageFeedbackButton; 