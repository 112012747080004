const SharpLightAttachment = () =>
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_82_408)">
      <path d="M13.5964 2.36602C12.5571 1.34297 10.8714 1.34297 9.83215 2.36602L2.97501 9.11601C1.30358 10.7613 1.30358 13.4262 2.97501 15.0715C4.64644 16.7168 7.35358 16.7168 9.02501 15.0715L14.4536 9.72773L14.8572 9.33047L15.6643 10.125L15.2607 10.5223L9.83215 15.866C7.71429 17.9508 4.28215 17.9508 2.16786 15.866C0.0535787 13.7812 0.0500073 10.4062 2.16786 8.32148L9.02501 1.57148C10.5107 0.108984 12.9179 0.108984 14.4036 1.57148C15.8893 3.03398 15.8893 5.40352 14.4036 6.86601L7.83929 13.3277C6.83215 14.3191 5.17501 14.2277 4.28215 13.1344C3.52144 12.1992 3.59644 10.8492 4.45715 10.002L9.88215 4.66523L10.2857 4.26797L11.0929 5.0625L10.6893 5.45977L5.26786 10.7965C4.81786 11.2395 4.77858 11.9426 5.17501 12.4312C5.63929 13.0008 6.50358 13.05 7.02858 12.5332L13.5964 6.07148C14.6357 5.04844 14.6357 3.38906 13.5964 2.36602Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_82_408">
        <rect width="16" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>

export default SharpLightAttachment;