import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { extractFilesFromArtifact } from "@/lib/artifacts";

const SandboxContext = createContext();

const ARTIFACT_API_URL = `${import.meta.env.ARTIFACT_API_URL}`;

export function SandboxProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const response = await axios.get(`${ARTIFACT_API_URL}/templates?name=react-ts`);
        setTemplate({
          name: "react-ts",
          files: response.data,
          description: "React with TypeScript",
        });
        setIsLoading(false);
        //console.log("template: ", response);
      } catch (error) {
        console.error("Error fetching Vite template:", error);
        setIsLoading(false);
      }
    };

    fetchTemplate();
  }, []);

  const publishArtifact = async (artifact, callback) => {
    console.log("publishArtifact", artifact);
    try {
      const response = await axios.post(`${ARTIFACT_API_URL}/preview`, {
        codeFiles: extractFilesFromArtifact(artifact.content),
      });
      console.log("publishArtifact response", response);
      callback(response.data.previewUrl);
    } catch (error) {
      console.error("Error publishing artifact:", error);
    }
  };

  const getTailwindCSS = async ({ templateName, additionalFiles }) => {
    const response = await fetch(`${ARTIFACT_API_URL}/utils/tailwindcss`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ templateName, additionalFiles }),
    });

    if (!response.ok) {
      throw new Error('Failed to generate Tailwind CSS');
    }

    const data = await response.json();
    return data.css;
  }

  return (
    <SandboxContext.Provider value={{ isLoading, template, publishArtifact, getTailwindCSS }}>
      {children}
    </SandboxContext.Provider>
  );
}

export function useSandbox() {
  return useContext(SandboxContext);
}
