import { LibraryUploadCards } from "@/components/LibraryUploadCards";
import { LibrarySourcesCard } from "@/components/LibrarySourcesCard";

import { LuHelpCircle } from "react-icons/lu";

import * as Tooltip from '@radix-ui/react-tooltip';
export default function Library() {
  return (
    <Tooltip.Provider>
      <div className="flex flex-col mx-auto">
        <Tooltip.Root>
          <div className="flex items-center gap-2">
            <p className="text-gray-600 mb-6">
            Link sources to get answers from EPHOR that leverage your data.
            </p>
            <Tooltip.Trigger>
              <LuHelpCircle className="text-gray-600 mb-6 w-5 h-5" />
            </Tooltip.Trigger>
            <Tooltip.Content className="text-sm bg-white p-4 rounded-md border border-gray-200 mb-6 z-50">
              <p >We currently support the following document types:
                <li>txt</li>
                <li>markdown</li>
                <li>html</li>
                <li>pdf</li>
                <li>google docs</li>
                <li>google sheets</li>
                <li>google slides</li>
                <li>csv</li>
                <li>json</li>
              </p>
            </Tooltip.Content>
          </div>
        </Tooltip.Root>
        <LibrarySourcesCard />
        <div className="flex flex-col mt-6 ">
          <LibraryUploadCards />
        </div>
      </div>
    </Tooltip.Provider>
  );
}