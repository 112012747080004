import React, { useState, useCallback, useEffect } from 'react';
import { useData } from '../contexts/DataContext';
import { FaChevronRight, FaTrophy, FaCopy } from 'react-icons/fa';
import { MdOutlineCompare, MdOutlineSpeed } from "react-icons/md";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import ChatSwitch from './ChatSwitch';
import { toast } from "react-hot-toast";
import { model_name_map, model_priority } from '../lib/constants';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, Label } from 'recharts';
import '../styles/Panel_SlmLeaderboard.scss';
import ContextUsageBar from './ContextUsageBar';

const SlmLeaderboard = () => {
    const [selectedSlm, setSelectedSlm] = useState(null);
    const [sortBy, setSortBy] = useState('similarity'); // New state for sorting
    const { 
        slmLeaderboardData, 
        setIsPanelVisible, 
        setSelectedPanel, 
        setHasAlreadyClosedSLM, 
        isStreaming,
        contextAnalysisData
    } = useData();

    const handleHide = () => {
        setSelectedPanel(null);
        setIsPanelVisible(false);
        if (isStreaming) {
            console.log('Closing SLM during stream');
            setHasAlreadyClosedSLM(true);
        }
    };

    const handleSortToggle = () => {
        setSortBy(prevSortBy => prevSortBy === 'similarity' ? 'time' : 'similarity');
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.success('Text copied to clipboard');
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    useEffect(() => {
        if (Object.keys(slmLeaderboardData).length === 0) {
            setSelectedSlm(null);
            return;
        }
        
        const validSlms = Object.values(slmLeaderboardData)
            .filter(slm => slm.content && !isError(slm.content));

        if (validSlms.length > 0 && (selectedSlm === null || !isStreaming)) {
            setSelectedSlm(validSlms[0]);
        }
    }, [slmLeaderboardData]);

    const sortedSlmData = useCallback(() => {
        const dataArray = Object.entries(slmLeaderboardData)
            .map(([name, details]) => ({ name, ...details }))
            .filter(slm => slm.content !== "" && !isError(slm.content));
        return dataArray.sort((a, b) => {
            // Push event to Google Tag Manager
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'sort_leaderboard',
                'changed_from': sortBy,
                'changed_to': sortBy === 'similarity' ? 'time' : 'similarity',
            });
            if (sortBy === 'similarity') {
                if (a.similarity === -1 && b.similarity === -1) {
                    return a.time === -1 ? 1 : b.time === -1 ? -1 : a.time - b.time;
                }
                if (a.similarity === b.similarity) {
                    return a.time === -1 ? 1 : b.time === -1 ? -1 : a.time - b.time;
                }
                return a.similarity === -1 ? 1 : b.similarity === -1 ? -1 : b.similarity - a.similarity;
            } else {
                if (a.time === -1 && b.time === -1) {
                    return a.similarity === -1 ? 1 : b.similarity === -1 ? -1 : b.similarity - a.similarity;
                }
                if (a.time === b.time) {
                    return a.similarity === -1 ? 1 : b.similarity === -1 ? -1 : b.similarity - a.similarity;
                }
                return a.time === -1 ? 1 : b.time === -1 ? -1 : a.time - b.time;
            }
        });
    }, [slmLeaderboardData, sortBy]);

    const chartData = sortedSlmData().map((slm, index) => ({
        name: model_name_map[slm.name] || slm.name,
        similarity: slm.similarity === -1 ? 0 : parseFloat(slm.similarity.toFixed(1)),
        time: slm.time === -1 ? 0 : parseFloat(slm.time.toFixed(1)),
        index: index + 1,
        slm: slm
    }));

    return (
        <div className="_slm-leaderboard">
            <div className="_buttons flex items-center">
                <div className="flex items-center">
                    <FaTrophy className="mr-2" />
                    <span className="font-bold">LM Leaderboard (beta)</span>
                </div>
                <div className="ml-auto mr-8 flex items-center">
                    <span className="mr-2">Sort by</span>
                    <ChatSwitch
                        onToggle={handleSortToggle}
                        initialState={sortBy === 'similarity'}
                        onLabel="Similarity"
                        offLabel="Time"
                        width="115px"
                        left="10px"
                        icon={sortBy === 'similarity' ? <MdOutlineCompare /> : <MdOutlineSpeed /> }
                    />
                </div>
                <button onClick={handleHide}>
                    <FaChevronRight />
                </button>
            </div>
            <div className="container max-h-[calc(100vh-150px)] overflow-y-auto p-2 mb-0">
                
                    {chartData.length === 0 ? (
                        <div className="leaderboard-loader-container p-2 pt-8 bg-gray-100 ">
                            <div className="loading-placeholder flex items-center justify-center h-full">
                                {isStreaming ? (
                                    <div className="skeleton-loader animate-pulse bg-gray-300 h-[200px] w-full flex items-center justify-center">
                                        <span className="text-gray-500">Leaderboard is loading...</span>
                                    </div>
                                ) : (
                                    <span className="text-gray-500">Leaderboard will appear here upon asking question.</span>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="leaderboard-container p-2 bg-gray-100 ">
                            <div className="leaderboard-content">
                                <ResponsiveContainer width="100%" height={Math.max(200, 150 + (10 * chartData.length))}>
                                    <BarChart layout="vertical" data={chartData} margin={{ top: 20, right: 40, left: 20, bottom: 15 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis type="number" xAxisId="time">
                                            <Label value="Time to generate (s)" offset={-10} position="insideBottom" />
                                        </XAxis>
                                        <XAxis type="number" xAxisId="similarity" orientation="top">
                                            <Label value="Similarity to Selected Model (%)" offset={-10} position="insideTop" />
                                        </XAxis>
                                        <YAxis dataKey="name" type="category" width={150} minTickGap={-200} tick={({ payload, ...rest }) => (
                                            <text {...rest} onClick={() => { 
                                                setSelectedSlm(chartData[payload.index].slm);
                                                const randomData = {
                                                    name: `Random ${Math.random().toFixed(2)}`,
                                                    similarity: Math.random() * 100,
                                                    time: Math.random() * 10,
                                                    index: chartData.length + 1,
                                                    slm: {}
                                                };
                                                chartData.push(randomData);
                                            }} style={{ cursor: 'pointer', fill: selectedSlm?.name === chartData?.[payload.index]?.slm?.name ? '#a246a6' : '#000', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                {payload.value}
                                            </text>
                                        )} />
                                        <Tooltip />
                                        <Bar dataKey="similarity" name="Similarity to Selected Model (%)" fill="#a246a6" xAxisId="similarity" onClick={(data) => setSelectedSlm(data.slm)} radius={[0, 10, 10, 0]}>
                                            {chartData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={selectedSlm === entry.slm ? '#d6b9fa' : '#a246a6'} />
                                            ))}
                                        </Bar>
                                        <Bar dataKey="time" name="Time to generate (s)" fill="#d6b9fa" xAxisId="time" onClick={(data) => setSelectedSlm(data.slm)} radius={[0, 10, 10, 0]}>
                                            {chartData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={selectedSlm === entry.slm ? '#a246a6' : '#d6b9fa'} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="legend flex justify-between mt-2 text-xs">
                                <div className="flex items-center">
                                    <span style={{ color: '#9d9d9d', fontSize: '0.75rem' }}>Click on a model name to view its response</span>
                                </div>
                                <div className="flex items-center">
                                    <div className="flex items-center mr-2">
                                        <div className="w-2 h-2 mr-1" style={{ backgroundColor: '#a246a6' }}></div>
                                        <span>Similarity</span>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="w-2 h-2 mr-1" style={{ backgroundColor: '#d6b9fa' }}></div>
                                        <span>Time</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                {contextAnalysisData && selectedSlm && (
                    <ContextUsageBar 
                        contextData={contextAnalysisData[selectedSlm.name]} 
                    />
                )}
                {selectedSlm && (
                    <div className="slm-details-full-width pt-2">
                        <div className="message ai-message flex items-start p-2" style={{ backgroundColor: '#f2f6ff', border: '1px solid #cad9fc', borderRadius: '8px' }}>
                            <div className="flex flex-col flex-grow">
                                <p className="user mb-2 text-gray-500 flex justify-between items-center">
                                    <span className="text-xs font-semibold">({model_name_map[selectedSlm.name] || selectedSlm.name})</span>
                                    <button 
                                        className="text-xs copy-btn"
                                        onClick={() => handleCopyToClipboard(selectedSlm.content)}
                                    >
                                        <FaCopy className="mx-3 my-1" size={14} />
                                    </button>
                                </p>
                                <div className="content overflow-y-auto react-content">
                                    <div className="text">
                                        <ReactMarkdown remarkPlugins={[remarkGfm]}>{selectedSlm.content}</ReactMarkdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

function isError(response) {
    const error_responses_arr = ['No response received from Sambanova.', 'Error: We are currently experiencing some issues with'];
    return error_responses_arr.some(errorPattern => response.startsWith(errorPattern));
}

export default SlmLeaderboard;