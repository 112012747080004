import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useData } from '../contexts/DataContext';
import { Loader } from './Loader';
import EphorLogo from './custom-icons/EphorLogo';

const Onboard = () => {
  const { ProjectsAPI, refreshAppData } = useData();
  const navigate = useNavigate();
  const location = useLocation();
  const navToProjectsRef = useRef(null);
  const pathParamJoinId = useParams().joinId;

  useEffect(() => {
    const handleOnboarding = async () => {
      const searchParams = new URLSearchParams(location.search);
      const joinId = searchParams.get('joinId');
      const inviteId = searchParams.get('inviteId');

      let response = {};

      if (pathParamJoinId) {
        response = await ProjectsAPI.joinViaLink(pathParamJoinId);
        console.log("join response", response);
      } else {
        response = await ProjectsAPI.onboard();

        console.log("onboard response", response);

        if (joinId) {
          response = await ProjectsAPI.joinViaLink(joinId);
          console.log("join response", response);
        } else if (inviteId) {
          response = await ProjectsAPI.acceptInvite(inviteId);
          console.log("invite response", response);
        }
      }

      navToProjectsRef.current = setTimeout(() => {
        const redirectUrl =
          joinId || inviteId || pathParamJoinId
            ? '/projects/' + response.project_id
            : '/projects';

        refreshAppData().then(() => {
          navigate(redirectUrl);
        });
      }, 1000);
    };

    handleOnboarding();
  }, [navigate, location]);

  return (
    <div style={{
      width: '100%',
      height: '80vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '50px'
    }}>
      <div style={{ height: '100px', width: '100px' }} >
        <EphorLogo />
      </div>
      <Loader />
    </div >
  );
};

export default Onboard;