import { useState, useRef, useCallback, useEffect } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Loader2 } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { LuRefreshCw } from "react-icons/lu";
import { LuChevronDown, LuChevronRight, LuExternalLink, LuArrowBigDown, LuArrowBigUp, LuTrash2 } from "react-icons/lu";
import { LuCircle, LuMinusCircle, LuCheckCircle, LuXCircle, LuLoader2, LuAlertCircle, LuHelpCircle } from "react-icons/lu";
import { HiOutlineStar, HiStar } from "react-icons/hi";
import { AiOutlineLoading } from "react-icons/ai";

import * as Tooltip from '@radix-ui/react-tooltip';
import { useData } from "@/contexts/DataContext";

import { toast } from "react-hot-toast";

export function LibrarySourcesCard({ drawerOpen }) {
  const columns = [
    {
      accessorKey: "file_name",
      header: "Name",
    },
    {
      accessorKey: "file_type",
      header: "Type",
    },
    {
      accessorKey: "updated_at",
      header: "Last updated",
    },
    {
      accessorKey: "starred",
      header: "Focus",
    },
    {
      accessorKey: "priority",
      header: "Priority",
    },
    {
      accessorKey: "buttons",
      header: "",
    }
  ]
  const drawerColumns = [
    {
      accessorKey: "file_name",
      header: "Name",
    },
    {
      accessorKey: "priority",
      header: "Priority",
    },
    {
      accessorKey: "buttons",
      header: "",
    }
  ]

  const itemTypeWithChildren = ['FOLDER','WEBSITE']

  const [loading, setLoading] = useState(false);
  const {
    handleItemUpdate,
    deleteDriveFolder,
    deleteSingleItem,
    blockPriorityUpdates,
    streamFolderSyncStatus,
    refreshLibraryItem,
    libraryData,
    LibraryAPI,
    streamLock,
    setStreamLock,
    handleAuthorizeGoogle,
  } = useData();
  const [expandedFolders, setExpandedFolders] = useState(new Set());
  const [deletingItems, setDeletingItems] = useState({});
  const [isUpdatePending, setIsUpdatePending] = useState(false);
  const [tempPriorityChanges, setTempPriorityChanges] = useState(0);
  const timerRef = useRef(null);
  const workingPriorityRef = useRef(0);
  const [refreshLock, setRefreshLock] = useState(false);
  const [flattenedData, setFlattenedData] = useState([]);

  useEffect(() => {
    setFlattenedData(libraryData.flatMap(folder => {
      const getChildren = () => {
        if (expandedFolders.has(folder.id) && folder.id.startsWith('Project__')) { 
          return folder.children.map(child => ({...child, id: `${folder.id}|${child.id}`})) 
        } else {
          return expandedFolders.has(folder.id) ? folder.children : []
        }
      }
      const {children, ...onlyFolder} = folder;
      return [onlyFolder, ...getChildren()];
    }));
  }, [libraryData, expandedFolders]);

  //useEffect(() => {
  //  console.log("streamLock", streamLock);
  //  if (streamLock) return;
  //  let inProgressFolderIds = [];
  //   for (const folder of libraryData) {
  //     if (folder.children.some(child => child.ingested === 'ProcessingInitiated')) {
  //       inProgressFolderIds.push(folder.id);
  //     }
  //   }
  //   if (inProgressFolderIds.length > 0) {
  //     for (const folderId of inProgressFolderIds) {
  //       console.log("RECONNECTING: folder sync", folderId);
  //       streamFolderSyncStatus(folderId, true);
  //     }
  //     setStreamLock(true);
  //   }
  // }, [libraryData, streamLock]);

  const handleFolderClick = (id) => {
    setExpandedFolders(prev => {
      const next = new Set(prev);
      if (next.has(id)) {
        next.delete(id);
      } else {
        next.add(id);
      }
      return next;
    });
  };

  const handlePriorityChange = useCallback((item, change) => {
    let newPriority;
    if (!isUpdatePending[item.id]) {
      newPriority = item.priority + change;
      workingPriorityRef.current = newPriority;
      setTempPriorityChanges(prev => ({ ...prev, [item.id]: newPriority }));
    } else {
      newPriority = Math.max(0, workingPriorityRef.current + change);
      workingPriorityRef.current = newPriority;
      setTempPriorityChanges(prev => ({ ...prev, [item.id]: newPriority }));
    }
    setIsUpdatePending(prev => ({ ...prev, [item.id]: true }));

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      let itemId = item.id;
      if (item.id.startsWith('Project__') && item.id.includes('|')) {
        itemId = item.id.split('|')[1];
      }
      handleItemUpdate(itemId, { priority: newPriority });
      setTempPriorityChanges({});
      workingPriorityRef.current = 0;
      setIsUpdatePending({});
    }, 2000);

  }, [isUpdatePending, handleItemUpdate]);

  const handleDelete = (id) => {
    // Placeholder function for handling delete
    setDeletingItems(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const confirmDelete = useCallback(async (id) => {
    setLoading(true);
    await deleteDriveFolder(id);
    setDeletingItems(prev => ({ ...prev, [id]: false }));
    setLoading(false);
  }, [deleteDriveFolder]);

  const confirmItemDelete = useCallback(async (itemId) => {
    setLoading(true);
    console.log("Deleting item", itemId);
    await deleteSingleItem(itemId);
    setDeletingItems(prev => ({ ...prev, [itemId]: false }));
    setLoading(false);
  }, [deleteSingleItem]);

  const handleStar = useCallback(async (id, existingStatus) => {
    let itemId = id;
    if (id.startsWith('Project__')) {
      itemId = id.split('|')[1];
    }
    await handleItemUpdate(itemId, { starred: !existingStatus });
  }, [handleItemUpdate]);

  const handleRefresh = async (id) => {
    if (!refreshLock) {
      setRefreshLock(true);
      streamFolderSyncStatus(id);
      setStreamLock(true);
      setTimeout(() => {
        setRefreshLock(false);
      }, 8000);
      try {
        await refreshLibraryItem(id);
      } catch (error) {
        console.log("Error refreshing library item", error);
        if (error.message.includes("api.clerk") || error.message.includes("oauth2.googleapis.com") || error.message.includes("refresh_token")) {
          //const url = new URL(window.location.href).pathname;
          //console.log("Redirecting to sign in page");
          console.log("Authorizing Google anew");
          await handleAuthorizeGoogle();
         // await signOut({ redirectUrl: `/?redirect=${true}&from=${url}` });
        } else {
          setRefreshLock(false);
          toast.error(error.message);
        }
      }
    }
  };

  const handleWorkflowyRefresh = async (namespace) => {
    try {
      console.log("Refreshing Workflowy for namespace:", namespace);
      await streamFolderSyncStatus(namespace, false, 'workflowy');
      await LibraryAPI.refreshWorkflowy(namespace);
    } catch (error) {
      console.log("Error triggering the backend event:", error);
    }
  };

  const table = useReactTable({
    data: flattenedData,
    columns: drawerOpen ? drawerColumns : columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const getStatusIcon = (status, folder = false) => {
    if (!folder) {
      switch (status) {
        case 'Processing':
          return (<span className="_ellipsis-anim">
            <LuCircle className="_library-item-status-icon" />
          </span>)
        case 'ProcessingInitiated':
          return (<AiOutlineLoading className="_library-item-status-icon loading" />)
        case 'Ingested':
          return <LuCheckCircle className="_library-item-status-icon success" />;
        case 'Incompatible':
          return <LuMinusCircle className="_library-item-status-icon" />;
        case 'Failed':
          return <LuXCircle className="_library-item-status-icon error" />;
        default:
          return <LuCircle className="_library-item-status-icon" />;
      }
    } else {
      switch (status) {
        case 'Processing':
          return (<LuLoader2 className="_library-item-status-icon spinner" />)
        case 'Ingested':
          return <LuCheckCircle className="_library-item-status-icon success" />;
        case "Status Sync timeout, check back later":
          return <LuAlertCircle className="_library-item-status-icon unknown" />;
        default:
          return <LuCircle className="_library-item-status-icon" />;
      };
    }
  }

  return (
      <div className={`_library-table-container ${drawerOpen ? 'drawer-open' : ''}`}>
        <Table>
          <TableHeader className="justify-center">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      <div className="flex items-center gap-2">
                      {!header.isPlaceholder && flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                        )}
                      {header.id === 'starred' && (
                        <Tooltip.Root>
                          <Tooltip.Trigger asChild>
                            <span className="text-gray-500 hover:text-gray-900">
                              <LuHelpCircle />
                            </span>
                          </Tooltip.Trigger>
                          <Tooltip.Content className="text-xs text-muted-foreground border border-gray-200 p-2 m-2 bg-white text-grey-600 z-50">
                            <p>
                            Select an item for deep retrieval. 
                            </p>
                            <p>
                              <strong>Use sparingly</strong> and <strong>deactivate</strong> when not needed. This is akin to attaching 
                              <br />
                              the entire document with each prompt to Ephor from this project.
                            </p>
                          </Tooltip.Content>
                        </Tooltip.Root>
                      )}
                      {header.id === 'priority' && (
                        <Tooltip.Root>
                          <Tooltip.Trigger asChild>
                            <span className="text-gray-500 hover:text-gray-900">
                              <LuHelpCircle />
                            </span>
                          </Tooltip.Trigger>
                          <Tooltip.Content className="text-xs text-muted-foreground border border-gray-200 p-2 m-2 bg-white text-grey-600 z-50">
                            <p>
                            Adjust the priority of an item to influence its retrieval order.
                            </p>
                            <p>
                              Higher priority items are ranked higher in retrieval. <br />
                              Use this to manage the importance of items in your project.
                            </p>
                          </Tooltip.Content>
                        </Tooltip.Root>
                      )}
                      </div>
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {cell.column.id === 'file_name' && (
                        <div className={`flex items-start gap-2 ${!itemTypeWithChildren.includes(row.original.file_type) ? 'pl-8' : 'pl-0'} ${drawerOpen ? 'text-xs' : ''}`}>
                          {itemTypeWithChildren.includes(row.original.file_type) && (
                            <>
                              {expandedFolders.has(row.original.id) ?
                                <LuChevronDown className="_library-item-folder-toggle" onClick={() => handleFolderClick(row.original.id)} /> :
                                <LuChevronRight className="_library-item-folder-toggle" onClick={() => handleFolderClick(row.original.id)} />
                              }
                            </>
                          )}
                          {(
                            <Tooltip.Root>
                              <Tooltip.Trigger>
                                <div className="flex items-start pt-0.5">
                                  {getStatusIcon(row.original.ingestion_status, itemTypeWithChildren.includes(row.original.file_type))}
                                </div>
                              </Tooltip.Trigger>
                              <Tooltip.Content className="text-xs text-gray-500 border border-gray-200 p-1 bg-white">
                                {row.original.ingestion_status}
                              </Tooltip.Content>
                            </Tooltip.Root>
                          )}
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </div>
                      )}
                      {cell.column.id === 'priority' && (
                        <div className="pl-2 flex items-center gap-2">
                          {blockPriorityUpdates ? (<div className="text-gray-500 text-xs">-</div>) : (
                            !itemTypeWithChildren.includes(row.original.file_type) && (
                              <div className='flex items-center gap-1'>
                                <button
                                  className='text-gray-500 hover:text-gray-700'
                                  onClick={() => handlePriorityChange(row.original, -1)}
                                  disabled={Object.keys(isUpdatePending).length !== 0 && !isUpdatePending[row.original.id] || ((row.original.priority || tempPriorityChanges[row.original.id] || 0) <= 0)}
                                >
                                  <LuArrowBigDown />
                                </button>
                                <span className={tempPriorityChanges[row.original.id] ? 'text-blue-500' : ''}>
                                  {(tempPriorityChanges[row.original.id] !== undefined) ? tempPriorityChanges[row.original.id] : row.original.priority}
                                </span>
                                <button
                                  className='text-gray-500 hover:text-gray-700'
                                  onClick={() => handlePriorityChange(row.original, 1)}
                                  disabled={Object.keys(isUpdatePending).length !== 0 && !isUpdatePending[row.original.id]}
                                >
                                  <LuArrowBigUp />
                                </button>
                              </div>
                            ))}
                          {itemTypeWithChildren.includes(row.original.file_type) && deletingItems[row.original.id] && (
                            <>
                              <div className="flex items-center text-xs text-gray-500 hover:text-gray-900 hover:underline " >
                                {loading ? <Loader2 className="ml-2 text-gray-400 animate-spin" /> : <p className="cursor-pointer" onClick={() => confirmDelete(row.original.id)}>Click to confirm</p>}
                              </div>
                            </>
                          )}
                          {row.original.file_type !== 'FOLDER' && row.original.id.startsWith('Project__') && row.original.id.includes('|') && deletingItems[row.original.id] && (
                            <>
                              <div className="flex items-center text-xs text-gray-500 hover:text-gray-900 hover:underline " >
                                {loading ? <Loader2 className="ml-2 text-gray-400 animate-spin" /> : <p className="cursor-pointer" onClick={() => confirmItemDelete(row.original.id.split('|')[1])}>Click to confirm</p>}
                              </div>
                            </>
                          )}
                        </div>
                      )} 
                      {cell.column.id == "starred" && (
                        <div className="flex items-center gap-2 justify-center">
                          {row.original.file_type !== 'FOLDER' && row.original.file_type !== 'WEBSITE' && (
                            <button className="text-gray-500 hover:text-gray-900" onClick={() => handleStar(row.original.id, row.original.starred)}>
                              {row.original.starred ? <HiStar className="w-[22px] h-[22px] text-yellow-500/80" /> : <HiOutlineStar className="w-[18px] h-[18px] text-gray-400" />}
                            </button>
                          )}
                        </div>
                      )}
                      {cell.column.id == "buttons" && (
                        <div className="flex items-center gap-2">
                          {itemTypeWithChildren.includes(row.original.file_type) && !row.original.id.startsWith('Project__') && (
                            <>
                            {row.original.file_type === 'FOLDER' && (
                              <button
                                className={`${!refreshLock ? 'text-gray-500 hover:text-gray-700 hover:bg-gray-200 p-1' : 'text-gray-200 hover:text-gray-200 p-1'}`}
                                onClick={() => handleRefresh(row.original.id)}
                                title="Refresh Folder"
                              >
                                <LuRefreshCw className="w-4 h-4" />
                              </button>
                            )}
                              <button
                                className={`text-gray-500 hover:text-gray-900 ${deletingItems[row.original.id] ? 'bg-gray-100 rounded-full p-1' : ''}`}
                                onClick={() => deletingItems[row.original.id] ? confirmDelete(row.original.id) : handleDelete(row.original.id)}
                                title={deletingItems[row.original.id] ? 'Confirm Delete' : 'Delete Source'}
                              >
                                <LuTrash2 />
                              </button>
                            </>
                          )}
                          {row.original.file_type === 'FOLDER' && row.original.id.startsWith('Project__') && (
                            <>
                              <button
                                className={`${!refreshLock ? 'text-gray-500 hover:text-gray-700 hover:bg-gray-200 p-1' : 'text-gray-200 hover:text-gray-200 p-1'}`}
                                onClick={() => handleWorkflowyRefresh(row.original.id)}
                                title="Refresh Folder"
                              >
                                <LuRefreshCw className="w-4 h-4" />
                              </button>
                              <button
                                className={`text-gray-500 hover:text-gray-900 ${deletingItems[row.original.id] ? 'bg-gray-100 rounded-full p-1' : ''}`}
                                onClick={() => deletingItems[row.original.id] ? confirmDelete(row.original.id) : handleDelete(row.original.id)}
                                title={deletingItems[row.original.id] ? 'Confirm Delete' : 'Delete Source'}
                              >
                                <LuTrash2 />
                              </button>
                              <button
                                className="text-gray-500 hover:text-gray-900 bg-transparent rounded-full p-1 disabled:text-gray-200"
                                disabled={true}
                              >
                                <LuRefreshCw className="text-transparent w-4 h-4" />
                              </button>
                            </>
                          )}
                          {row.original.file_type !== 'FOLDER' && row.original.id.startsWith('Project__') && row.original.id.includes('|') && (
                            <>
                              <button
                                className="text-gray-500 hover:text-gray-900 bg-transparent rounded-full p-1 disabled:text-gray-200"
                                disabled={true}
                              >
                                <LuRefreshCw className="text-transparent w-4 h-4" />
                              </button>
                              <button
                                className={`text-gray-500 hover:text-gray-900 ${deletingItems[row.original.id] ? 'bg-gray-100 rounded-full p-1' : ''}`}
                                onClick={() => deletingItems[row.original.id] ? confirmItemDelete(row.original.id.split('|')[1]) : handleDelete(row.original.id)}
                                title={deletingItems[row.original.id] ? 'Confirm Delete' : 'Delete Source'}
                              >
                                <LuTrash2 />
                              </button>
                              <button
                                className="text-gray-500 hover:text-gray-900 bg-transparent rounded-full p-1 disabled:text-gray-200"
                                disabled={true}
                              >
                                <LuRefreshCw className="text-transparent w-4 h-4" />
                              </button>
                            </>
                          )}
                          {row.original.file_type !== 'FOLDER' && !row.original.id.startsWith('Project__') && (
                            <>
                              <button
                                className="text-gray-500 hover:text-gray-900 bg-transparent rounded-full p-1 disabled:text-gray-200"
                                disabled={true}
                              >
                                <LuRefreshCw className="text-transparent w-4 h-4" />
                              </button>
                              <button
                                className="text-gray-500 hover:text-gray-900 bg-transparent rounded-full disabled:text-gray-200"
                                disabled={true}
                              >
                                <LuRefreshCw className="text-transparent w-4 h-4" />
                              </button>
                            </>
                          )}
                          {row.original.url && !row.original.id.startsWith('Project__') && (
                            <a href={row.original.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                              <LuExternalLink />
                            </a>
                          )}
                        </div>
                      )}
                      {!(["file_name", "starred", "priority", "buttons"].includes(cell.column.id)) && (
                        <div className="pl-2 flex items-center gap-2">
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </div>

                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
  );
}
