import React, { useState, useEffect } from 'react';
import { LuCopy, LuRotateCcw, LuChevronDown, LuChevronUp } from "react-icons/lu";
import { HelpCircle } from "lucide-react";
import { useUser } from "@clerk/clerk-react";
import { v4 as uuidv4 } from 'uuid';
import { useData } from '../contexts/DataContext';
import toast from "react-hot-toast";
import ChatSwitch from './ChatSwitch';
import { READ_AI_PLATFORM } from '../lib/constants';
import * as Tooltip from "@radix-ui/react-tooltip";

const API_BASE_URL = `${import.meta.env.VITE_APP_API_URL}`;


const Integration = () => {
    const [webhookUrl, setWebhookUrl] = useState('');
    const { user } = useUser();
    const { UsersAPI } = useData();
    const [account, setAccount] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [showToggle, setShowToggle] = useState(false);
    const [apiKey, setApiKey] = useState(null);
    const [lastUsed, setLastUsed] = useState(null);
    const [showInstructions, setShowInstructions] = useState(false);
    const [showApiInstructions, setShowApiInstructions] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch account for webhook/integration data
                const account = await UsersAPI.getUserAccount(user?.id);
                setAccount(account);
                
                // Set webhook data if exists
                if (account?.integrations?.length > 0) {
                    const readAiIntegration = account?.integrations.find(integration => integration.platform === READ_AI_PLATFORM);
                    if (readAiIntegration) {
                        setWebhookUrl(readAiIntegration.webhook_url || 'Please re-generate a webhook URL');
                        setIsActive(readAiIntegration.is_active);
                        setShowToggle(true);
                    }
                }

                // Fetch active API key separately
                const response = await UsersAPI.getActiveApiKeys();
                if (response?.length > 0) {
                    setApiKey(response[0].api_key);
                    // Convert timestamp to readable date
                    const lastUsedDate = new Date(response[0].last_used * 1000);
                    setLastUsed(lastUsedDate.toLocaleString());
                }
            } catch (error) {
                toast.error('Failed to load account data');
                console.error('Error fetching data:', error);
            }
        }
        
        if (user?.id) {
            fetchData();
        }
    }, [user?.id]);

    const toggleIntegration = (newState) => {
        setIsActive(newState);
    }

    const saveIntegration = async () => {
        const account = {
            account_id: user?.id,
            integrations: [{platform: READ_AI_PLATFORM, webhook_url: webhookUrl, is_active: isActive}],
            email: user?.primaryEmailAddress?.emailAddress,
            name: user?.fullName
        }
        await UsersAPI.updateAccountIntegrations(account)
        setShowToggle(true);
        toast.success('Integration saved successfully!');
    }

    const generateWebhookUrl = async () => {
        const account_id = user?.id;
        const uuid = uuidv4();
        const newUrl = `${API_BASE_URL}/api/v1/readai/webhook/ingest/${account_id}/${uuid}`;
        setWebhookUrl(newUrl);
        setShowSaveButton(true);
        setShowToggle(true);
    };

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(webhookUrl);
        toast.success('Webhook URL copied to clipboard!');
    };

    const generateApiKey = async () => {
        try {
            const response = await UsersAPI.generateApiKey();
            setApiKey(response.api_key);
            // Reset last used since this is a new key
            setLastUsed(new Date().toLocaleString());
            toast.success('API Key generated successfully!');
        } catch (error) {
            toast.error('Failed to generate API Key');
        }
    };

    const copyToClipboardApiKey = async () => {
        await navigator.clipboard.writeText(apiKey);
        toast.success('API Key copied to clipboard!');
    };

    const revokeApiKey = async () => {
        try {
            await UsersAPI.revokeApiKey();
            setApiKey(null);
            // Clear last used when key is revoked
            setLastUsed(null);
            toast.success('API Key revoked successfully!');
        } catch (error) {
            toast.error('Failed to revoke API Key');
        }
    };

    return (
        <Tooltip.Provider>
            <div className="_integration-container">
                <div className="border-b pb-5 mb-6">
                    <h1 className="text-2xl font-semibold">Integrations</h1>
                    <p className="text-sm text-muted-foreground">
                        Manage your external integrations and API access.
                    </p>
                </div>

                <div className="flex items-center">
                    <h2>Read AI Webhook</h2>
                    <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                            <HelpCircle size={15} className="ml-1 text-muted-foreground cursor-pointer" />
                        </Tooltip.Trigger>
                        <Tooltip.Content className="text-sm text-muted-foreground border border-gray-200 p-2 m-2 bg-white text-grey-600 z-50">
                            Once the webhook is set up, your meetings will be automatically <br />
                            ingested and begin building your Profile Outline.
                        </Tooltip.Content>
                    </Tooltip.Root>
                </div>

                <div className="mt-4">
                    {!account?.integrations?.find(integration => integration.platform === 'Read_AI') && (
                        <button 
                            onClick={generateWebhookUrl} 
                            className="_integration-button" 
                        >
                            Generate Webhook URL
                        </button>
                    )}

                    {(webhookUrl || account?.integrations?.find(integration => integration.platform === 'Read_AI')?.webhook_url) && (<div>
                        <div className="_integration-webhook-url">
                            <pre>{webhookUrl || account?.integrations?.find(integration => integration.platform === 'Read_AI')?.webhook_url}</pre>
                            <button 
                                onClick={copyToClipboard} 
                                className="_integration-webhook-url-button"
                            >
                                <LuCopy />
                            </button>
                            <button 
                                onClick={generateWebhookUrl} 
                                className="_integration-webhook-url-button"
                            >
                                <LuRotateCcw />
                            </button>
                        </div>
                    </div>
                    )}

                    {showToggle && (
                        <div className="_integration-toggle-section">
                            <div>
                                <h3>Use personalized context in chat</h3>
                                <ChatSwitch onToggle={toggleIntegration} initialState={isActive} onLabel="ON" offLabel="OFF" />
                            </div>
                            <br />
                            <div className="_integration-instructions">
                                <button 
                                    onClick={() => setShowInstructions(!showInstructions)}
                                    className="_integration-instructions-button text-sm text-blue-600 hover:text-blue-800 underline flex items-center gap-1"
                                >
                                    Setup Instructions
                                    {showInstructions ? <LuChevronUp size={14} /> : <LuChevronDown size={14} />}
                                </button>
                                {showInstructions && (
                                    <div className="_integration-instructions-content">
                                        <ol className="list-decimal list-inside space-y-1">
                                            <li>Generate a webhook URL and copy it.</li>
                                            <li>Go to the Read AI platform and navigate to the Apps & Integrations page.</li>
                                            <li>Select &apos;Webhooks&apos; under the Workflow section.</li>
                                            <li>Click on Add Webhook, paste the URL, provide a name, then &apos;Create Webhook&apos;.</li>
                                            <li>Click on the ellipsis icon and select &apos;Send test request&apos;. You should see a success message.</li>
                                        </ol>
                                        <p className="mt-3">
                                            Note: The status will still show pending until you have a meeting after setting up the webhook. 
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    
                    {(account?.integrations?.length > 0 || showSaveButton) && (
                        <div className="_integration-save-section">
                            <button 
                                className="_integration-button" 
                                onClick={saveIntegration} 
                            >
                                Save
                            </button>
                        </div>
                    )}
                </div>

                <div className="_integration-divider" />

                <div className="flex items-center">
                    <h2>API Key</h2>
                    <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                            <HelpCircle size={15} className="ml-1 text-muted-foreground cursor-pointer" />
                        </Tooltip.Trigger>
                        <Tooltip.Content className="text-sm text-muted-foreground border border-gray-200 p-2 m-2 bg-white text-grey-600 z-50">
                            <p>Use API keys for external integrations with EPHOR. <br /><br />Keep your API keys secure and never share them publicly.</p>
                        </Tooltip.Content>
                    </Tooltip.Root>
                </div>

                <div className="mt-4">
                    {!apiKey && (
                        <button 
                            onClick={generateApiKey} 
                            className="_integration-button"
                        >
                            Generate API Key
                        </button>
                    )}

                    {apiKey && (
                        <>
                            <div className="_integration-api-key">
                                <pre>{apiKey}</pre>
                                <button 
                                    onClick={copyToClipboardApiKey} 
                                    className="_integration-webhook-url-button"
                                >
                                    <LuCopy />
                                </button>
                            </div>
                            {lastUsed && (
                                <p className="text-sm text-muted-foreground mt-2">
                                    Last used: {lastUsed}
                                </p>
                            )}
                            <div className="_integration-button-group">
                                <button 
                                    onClick={revokeApiKey} 
                                    className="_integration-button _integration-button-secondary"
                                >
                                    Revoke API Key
                                </button>
                                <button 
                                    onClick={generateApiKey} 
                                    className="_integration-button"
                                >
                                    Regenerate API Key
                                </button>
                            </div>
                            <br />
                            <div className="_integration-instructions mt-4">
                                <button 
                                    onClick={() => setShowApiInstructions(!showApiInstructions)}
                                    className="_integration-instructions-button text-sm text-blue-600 hover:text-blue-800 underline flex items-center gap-1"
                                >
                                    API Documentation
                                    {showApiInstructions ? <LuChevronUp size={14} /> : <LuChevronDown size={14} />}
                                </button>
                                {showApiInstructions && (
                                    <div className="_integration-instructions-content">
                                        <p className="mb-3">
                                            Access our interactive API documentation to learn how to integrate with our services. More endpoints will be added over time; please share requests for new endpoints via the Feedback button in the bottom corner.
                                        </p>
                                        <ol className="list-decimal list-inside space-y-1">
                                            <li>Visit <a href={`${API_BASE_URL}/docs`} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 underline">{`${API_BASE_URL}/docs`}</a></li>
                                            <li>Use your API key in the Authorization header as: <code className="bg-gray-100 px-2 py-1 rounded">YOUR_API_KEY</code></li>
                                            <li>Test endpoints directly through the interactive documentation</li>
                                        </ol>
                                        <p className="mt-3 text-sm">
                                            Note: Keep your API key secure and never share it publicly. You can regenerate or revoke your key at any time.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Tooltip.Provider>
    );
}

export default Integration;