import React, { useState, useEffect, useRef } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ScatterChart, Scatter } from "recharts";
import { scatter_graph_colors, scatter_graph_stroke_colors, model_name_map } from "../lib/constants";
import ChatSwitch from "./ChatSwitch";
import { useData } from "../contexts/DataContext";

const ProjectMetrics = ({ projectId }) => {
  const { ChannelsAPI } = useData();
  const [channels, setChannels] = useState([]);
  const [channelMessages, setChannelMessages] = useState({});
  const [userMessageCounts, setUserMessageCounts] = useState([]);
  const [lmTypeCounts, setLmTypeCounts] = useState([]);
  const [nonEphorAICounts, setNonEphorAICounts] = useState([]);
  const [artifactCounts, setArtifactCounts] = useState([]);
  const [linesOfCodeCounts, setLinesOfCodeCounts] = useState([]);
  const [slmDataByLmType, setSlmDataByLmType] = useState({});
  const [selectedLmTypes, setSelectedLmTypes] = useState({});
  const [showAverage, setShowAverage] = useState(false);
  const [slmDataByPerf, setSlmDataByPerf] = useState({});
  const isMounted = useRef(true);

  useEffect(() => {
    const fetchChannelsAndMessages = async () => {
      try {
        const channelsData = await ChannelsAPI.getChannels(projectId);
        if (channelsData && isMounted.current) {
          setChannels(channelsData);

          const messagesData = await Promise.all(
            channelsData.map(channel => ChannelsAPI.getChannelMessages(projectId, channel.id))
          );
          const messagesMap = channelsData.reduce((acc, channel, index) => {
            acc[channel.id] = messagesData[index];
            return acc;
          }, {});

          setChannelMessages(messagesMap);

          const userCounts = {};
          const lmCounts = {};
          let nonEphorAICount = 0;
          let artifactCount = 0;
          let linesOfCodeCount = 0;
          const slmLeaderboardData = {};
          const slmPerformanceData = {};

          messagesData.flat().forEach(message => {
            userCounts[message.user_name] = (userCounts[message.user_name] || 0) + 1;
            if (message.user_name === "Ephor AI") {
              lmCounts[message.lm_type] = (lmCounts[message.lm_type] || 0) + 1;
            }
            if (message.user_name !== "Ephor AI") {
              nonEphorAICount++;
            }
            if (message.artifacts && message.artifacts.length > 0) {
              artifactCount++;
              linesOfCodeCount += message.artifacts.reduce((acc, artifact) => acc + (artifact.content.match(/\n/g) || []).length + 1, 0);
            }
            if (message.slm_leaderboard) {
              const leaderboard = JSON.parse(message.slm_leaderboard);
              Object.values(leaderboard).forEach(entry => {
                if (entry.time !== -1 && entry.similarity !== -1) {
                  const displayName = model_name_map[entry.name] || entry.name;
                  if (!slmLeaderboardData[displayName]) {
                    slmLeaderboardData[displayName] = [];
                  }
                  slmLeaderboardData[displayName].push({
                    name: displayName,
                    speed: entry.time,
                    similarity: entry.similarity
                  });

                  if (!slmPerformanceData[displayName]) {
                    slmPerformanceData[displayName] = { speedArr: [], similarityArr: [] };
                  }
                  slmPerformanceData[displayName].speedArr.push(entry.time);
                  slmPerformanceData[displayName].similarityArr.push(entry.similarity);
                }
              });
            }
          });

          const sortedUserCounts = Object.entries(userCounts)
            .filter(([name]) => name !== "Ephor AI")
            .sort((a, b) => b[1] - a[1])
            .slice(0, 5)
            .map(([name, count]) => ({ name, count }));

          const sortedLmCounts = Object.entries(lmCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 5)
            .map(([lmType, count]) => ({ lmType, count }));

          setUserMessageCounts(sortedUserCounts);
          setLmTypeCounts(sortedLmCounts);
          setNonEphorAICounts([{ name: "Non-Ephor AI Messages", count: nonEphorAICount }]);
          setArtifactCounts([{ name: "Artifacts Generated", count: artifactCount }]);
          setLinesOfCodeCounts([{ name: "Lines of Code", count: linesOfCodeCount }]);
          setSlmDataByLmType(slmLeaderboardData);
          setSlmDataByPerf(slmPerformanceData);

          const initialSelectedLmTypes = Object.keys(slmLeaderboardData).reduce((acc, lmType) => {
            acc[lmType] = true;
            return acc;
          }, {});
          setSelectedLmTypes(initialSelectedLmTypes);
        }
      } catch (error) {
        console.error("Failed to fetch channels or messages:", error);
      }
    };

    fetchChannelsAndMessages();

    return () => {
      isMounted.current = false;
    };
  }, [ChannelsAPI, projectId]);

  const handleLmTypeToggle = (lmType) => {
    setSelectedLmTypes((prev) => ({
      ...prev,
      [lmType]: !prev[lmType],
    }));
  };

  const handleShowAverageToggle = () => {
    setShowAverage((prev) => !prev);
  };

  const getAverageData = (data, lmType) => {
    if (!Array.isArray(data) || data.length === 0) return [];
    const validData = data.filter(point => point.speed !== -1 && point.similarity !== -1);
    if (validData.length === 0) return [];
    const total = validData.reduce(
      (acc, point) => {
        acc.speed += point.speed || 0;
        acc.similarity += point.similarity || 0;
        return acc;
      },
      { speed: 0, similarity: 0 }
    );
    return [{
      name: lmType,
      speed: parseFloat((total.speed / validData.length).toFixed(1)),
      similarity: parseFloat((total.similarity / validData.length).toFixed(1))
    }];
  };

  const getTopSlmsByPerformance = () => {
    const topSlms = Object.entries(slmDataByPerf)
      .map(([lmType, data]) => {
        const similarityArr = data.similarityArr || [];
        const similarityCount = similarityArr.filter(similarity => similarity > 80).length;
        const displayName = model_name_map[lmType] || lmType;
        return { lmType: displayName, similarityCount };
      })
      .sort((a, b) => b.similarityCount - a.similarityCount)
      .slice(0, 5);
    return topSlms;
  };

  return (
    <div className="project-metrics-container p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">Project Metrics</h2>
      <div className="metrics-card-grid grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="metric-card bg-gray-50 p-4 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-2">Total Number of Prompts</h3>
          <div className="text-center text-3xl font-bold text-purple-600">
            {nonEphorAICounts.length > 0 ? nonEphorAICounts[0].count : 0}
          </div>
        </div>
        <div className="metric-card bg-gray-50 p-4 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-2">Number of Artifacts Generated</h3>
          <div className="text-center text-3xl font-bold text-purple-600">
            {artifactCounts.length > 0 ? artifactCounts[0].count : 0}
          </div>
        </div>
        <div className="metric-card bg-gray-50 p-4 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-2">Lines of Code Generated</h3>
          <div className="text-center text-3xl font-bold text-purple-600">
            {linesOfCodeCounts.length > 0 ? linesOfCodeCounts[0].count : 0}
          </div>
        </div>
      </div>
      <div className="metrics-row mt-6 grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="metric-card bg-gray-50 p-4 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-2">Top Contributors by Message Count</h3>
          <ResponsiveContainer width="100%" height={220}>
            <BarChart data={userMessageCounts} margin={{ top: 20, right: 30, left: 20, bottom: 25 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={{ fontSize: 12 }} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="rgba(162, 70, 166, 0.5)" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="metric-card bg-gray-50 p-4 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-2">Top LM Used by Message Count</h3>
          <ResponsiveContainer width="100%" height={220}>
            <BarChart data={lmTypeCounts} margin={{ top: 20, right: 30, left: 20, bottom: 25 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="lmType" tick={{ fontSize: 12 }} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="rgba(70, 130, 180, 0.5)" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="metric-card bg-gray-50 p-4 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-2">Top LMs by Performance (80% or more similar to Selected LM)</h3>
          <ResponsiveContainer width="100%" height={220}>
            <BarChart data={getTopSlmsByPerformance()} margin={{ top: 20, right: 30, left: 20, bottom: 25 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="lmType" tick={{ fontSize: 12 }} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="similarityCount" fill="rgba(255, 165, 0, 0.5)" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="metric-card bg-gray-50 p-4 rounded-lg shadow-sm mt-6">
        <h3 className="text-lg font-medium mb-2">LM Performance Scatter Plot</h3>
        <ResponsiveContainer width="100%" height={500}>
          <ScatterChart margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="speed" name="Speed" unit="s" type="number" domain={['auto', 'auto']} />
            <YAxis dataKey="similarity" name="Similarity" unit="%" type="number" domain={['auto', 'auto']} />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              formatter={(value, name, props) => [`${name}: ${value.toFixed(1)}`, props.payload.name]}
            />
            <Legend />
            {Object.entries(slmDataByLmType).map(([lmType, data], index) => {
              if (!selectedLmTypes[lmType]) return null;
              const displayData = showAverage ? getAverageData(data, lmType) : data.filter(point => point.speed !== -1 && point.similarity !== -1);
              const color = scatter_graph_colors[index % scatter_graph_colors.length];
              const strokeColor = scatter_graph_stroke_colors[index % scatter_graph_stroke_colors.length];
              const displayName = model_name_map[lmType] || lmType;
              return (
                <Scatter key={lmType} name={displayName} data={displayData} fill={color} stroke={strokeColor} strokeWidth={1} />
              );
            })}
          </ScatterChart>
        </ResponsiveContainer>
        <div className="flex flex-wrap items-center mt-4">
          {Object.keys(slmDataByLmType).map((lmType) => (
            <div key={lmType} className="flex items-center mr-4">
              <input
                type="checkbox"
                checked={selectedLmTypes[lmType]}
                onChange={() => handleLmTypeToggle(lmType)}
                id={`checkbox-${lmType}`}
                className="mr-2"
              />
              <label htmlFor={`checkbox-${lmType}`} className="text-sm">{model_name_map[lmType] || lmType}</label>
            </div>
          ))}
        </div>
        <div className="flex items-center mt-4">
          <ChatSwitch
            onToggle={handleShowAverageToggle}
            initialState={showAverage}
            onLabel="Show Average"
            offLabel="Show Everything"
            width="170px"
            left="10px"
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectMetrics;
