import React, { useCallback, useEffect, useRef, useState, useContext } from "react";
import { toast } from "react-hot-toast";
import {
  FaCodeBranch,
  FaLock,
  FaShareAlt,
  FaTrophy,
  FaUsers,
} from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { v4 as uuidv4 } from "uuid";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import { useData } from "../contexts/DataContext";
import { useUser } from "@clerk/clerk-react";
import { useParams, useNavigate } from "react-router-dom";

import ChatInput from "./ChatInput";

import MessageOptionsPopUp from "@/components/MessageOptionsPopUp";
import ChatDropdown from "./ChatDropdown";
import { Loader } from "./Loader";
import MessageArtifactButton from "./MessageArtifactButton";
import MessageRelatedQueries from "./MessageRelatedQueries";
import RetractableButton from "./RetractableButton";
import BrainDumpMenu from "./BrainDumpMenu";
import EphorLogo from "./custom-icons/EphorLogo";
import { HiDocumentMagnifyingGlass } from "react-icons/hi2";
import { MdSource } from "react-icons/md";
import { LuFileSearch2 } from "react-icons/lu";
import { FaFileCode } from "react-icons/fa";
import { IoDocument } from "react-icons/io5";
import { extractFilesFromArtifact } from "../lib/artifacts";
import MessageFeedbackButton from "./MessageFeedbackButton";
import loadingImageBase64 from "./LoadingImage";

const lmTypes = {
  "Sonnet 3.5": "anthropic-sonnet35",
  "Haiku 3.5": "anthropic-haiku35",
  "OpenAI 4o": "openai-4o",
  "OpenAI 4om": "openai-4om",
  "Gemini 1.5 Pro": "gemini-1.5-pro",
  "Gemini Flash": "gemini-model-flash",
  "Grok-2 Beta": "grok-beta",
  "Llama 70B": "groq-70",
  "Llama 8B": "groq-8",
  "Mistral 7B": "groq-mistral",
  "Perplexity": "perplexity_online_large",
};

// Define options for the sharing dropdown
const sharingOptions = [
  { value: "private", label: "Private" },
  { value: "shared", label: "Shared" },
  { value: "multi_user", label: "Multi-user" },
];

const iconList = {
  private: <FaLock />,
  shared: <FaShareAlt />,
  multi_user: <FaUsers />,
};

const ProjectChatInput = ({}) => {
  const {
    ChannelsAPI,
    InteractAPI,
    AIAPI,
    WebsocketAPI,

    projects,
    channels,
    activeProjectId,
    activeChannelId,
    isStreaming,
    setActiveChannelId,
    setIsStreaming,
    setCurrentCode,
    LibraryAPI,
    setSelectedPanel,
    setIsPanelVisible,
    setTriggerPreview,
    isConnected,
    setSlmLeaderboardData,
    setContextAnalysisData,
    setChatSidebarOpen,
    selectedArtifact,
    setSelectedArtifact,
    setSources,
    autoGeneratedChannelName,
    editedChannelName,
    FeedbackAPI,
    AttachmentAPI,
  } = useData();
  const { user, isLoaded: isUserLoaded } = useUser();
  const libraryId = projects.find(
    (i) => i.project_id === activeProjectId
  ).library_id;
  const customInstructions = projects.find(
    (i) => i.project_id === activeProjectId
  ).custom_instructions;
  const [channel, setChannel] = useState(null);
  const [channelState, setChannelState] = useState(channel?.state || "private");
  const [messages, setMessages] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [retryCount, setRetryCount] = useState(0);
  const [selectedModel, setSelectedModel] = useState("anthropic-sonnet35");
  const messagesContainerRef = useRef(null);
  const messagesEndRef = useRef(null);

  const [statusToastId, setStatusToastId] = useState(null);
  const [isSharedContext, setSharedContext] = useState(true);
  const [isInternetEnabled, setIsInternetEnabled] = useState(false);
  const [lockModelSelection, setLockModelSelection] = useState(false);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const [loadedAttachments, setLoadedAttachments] = useState({});

  const accumulatedCodeRef = useRef("");
  const isFirstCodeRef = useRef(true);

  const slmLeaderboardData = {};
  const contextAnalysisData = {};

  const isInteractive =
    channelState === "multi_user" || channel?.creator_id === user?.id;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
  };

  const sortMessagesByTimestamp = (msgs) => {
    return msgs.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  };

  useEffect(() => {
    if (selectedArtifact && selectedArtifact.published_url) {
      const message = messages.find(
        (msg) =>
          msg.artifacts &&
          msg.artifacts.some(
            (artifact) =>
              artifact.artifact_id === selectedArtifact.artifact_id &&
              artifact.published_url !== selectedArtifact.published_url
          )
      );
      if (message) {
        console.log("message:", message);
        message.artifacts = message.artifacts.map((artifact) =>
          artifact.artifact_id === selectedArtifact.artifact_id
            ? { ...artifact, published_url: selectedArtifact.published_url }
            : artifact
        );
        // Update the message with the published URL
        setMessages((prevMessages) =>
          prevMessages.map((msg) => {
            if (msg.id === message.id) {
              return message;
            }
            return msg;
          })
        );
        // Update the message with the published URL
        WebsocketAPI.sendMessage(message);
      }
    }
  }, [selectedArtifact]);

  useEffect(() => {
    const imageAttachments = messages.filter((msg) => 
      msg.attachments &&
      msg.attachments.some((attachment) => attachment.is_image && !attachment.base64)
    );
    imageAttachments.forEach((message) => {
      message.attachments.forEach((attachment) => { 
        fetchAttachment(attachment.attachment_id);
      });
    });
  }, [messages]);

  const fetchAttachment = useCallback(async (attachmentId) => {
    if (loadedAttachments[attachmentId]) return; // Skip if already loaded

    try {
      const response = await AttachmentAPI.getAttachment(attachmentId);
      setLoadedAttachments((prev) => ({
        ...prev,
        [attachmentId]: response.b64, // Assuming response.data contains the base64 data
      }));
    } catch (error) {
      console.error(`Failed to load attachment:${attachmentId}`, error);
    }
  }, [loadedAttachments]);

  const handleForkChannel = useCallback(async () => {
    if (!activeChannelId || !activeProjectId) return;

    try {
      const forkedChannel = await ChannelsAPI.forkChannel(
        activeProjectId,
        activeChannelId
      );
      toast.success(
        `Channel forked successfully. New channel: ${forkedChannel.name}`
      );
      ChannelsAPI.getChannels(activeProjectId);
      setActiveChannelId(forkedChannel.id);
    } catch (error) {
      console.error("Failed to fork channel:", error);
      toast.error("Failed to fork channel. Please try again.");
    }
  }, [activeChannelId, activeProjectId, ChannelsAPI]);

  const handleExport = useCallback(async (exportTarget, exportFormat, includeArtifacts, includeSLMData) => {
    if (!activeChannelId || !activeProjectId) return;

    try {
      const channelId = exportTarget === "channel" ? activeChannelId : null;
      const projectName = projects.find(p => p.project_id === activeProjectId)?.name || "unknown_project";
      const channelName = channelId ? (channels.find(c => c.id === channelId)?.name || "unknown_channel") : "";
      const fileExtension = exportTarget === "project" ? "zip" : exportFormat;
      const fileName = `${projectName} - ${exportTarget === "channel" ? channelName : exportFormat.toUpperCase()}`.trim();
      await ChannelsAPI.exportData(activeProjectId, exportTarget, exportFormat, channelId, fileName, fileExtension, includeArtifacts, includeSLMData);
      toast.success(`${exportTarget.charAt(0).toUpperCase() + exportTarget.slice(1)} export initiated successfully.`);
    } catch (error) {
      console.error(`Error exporting ${exportTarget}:`, error);
      toast.error("Internal server error. Please try again later.");
    }
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
      'event': 'download_chat',
      'chat_type': exportTarget === "channel" ? "chat" : "project",
      'output': exportFormat,
      'include_artifact': includeArtifacts,
      'include_slm_data': includeSLMData
      });
  }, [activeChannelId, activeProjectId, ChannelsAPI]);

  const navigate = useNavigate();

  const fetchChannelAndMessages = useCallback(async () => {
    if (!activeChannelId || !activeProjectId) return;

    setIsLoading(true);
    setError(null);

    try {
      const channelResponse = await ChannelsAPI.getChannel(
        activeProjectId,
        activeChannelId
      );
      
      if (channelResponse.error === "ACCESS_DENIED") {
        navigate(`/projects/${activeProjectId}`);
        return;
      }

      const channelMessagesResponse = await ChannelsAPI.getChannelMessages(
        activeProjectId,
        activeChannelId
      );
      setChannel(channelResponse);
      setChannelState(channelResponse.state);
      setMessages(sortMessagesByTimestamp(channelMessagesResponse));
      setRetryCount(0); // Reset retry count on successful fetch
    } catch (error) {
      console.error("Failed to fetch channel or messages:", error);
      setError("Failed to load channel data");
      if (retryCount < 3) {
        setTimeout(
          () => {
            setRetryCount((prevCount) => prevCount + 1);
          },
          1000 * (retryCount + 1)
        ); // Exponential backoff
      }
    }

    setIsLoading(false);
  }, [activeProjectId, activeChannelId, navigate]);

  const handleNewMessage = useCallback((message) => {
    setMessages((prevMessages) => {
      const existingMessage = prevMessages.find((msg) => msg.id === message.id);

      if (existingMessage) {
        // Preserve existing feedback data if not included in the new message
        const preservedFeedback = {
          feedback_id: message.feedback_id ?? existingMessage.feedback_id,
          feedback_type: message.feedback_type ?? existingMessage.feedback_type,
          feedback_category: message.feedback_category ?? existingMessage.feedback_category,
          feedback_details: message.feedback_details ?? existingMessage.feedback_details,
        };

        return sortMessagesByTimestamp(
          prevMessages.map((msg) =>
            msg.id === message.id
              ? { ...msg, ...message, ...preservedFeedback }
              : msg
          )
        );
      } else {
        return sortMessagesByTimestamp([...prevMessages, message]);
      }
    });
  }, []);

  const handleSendMessage = useCallback(
    async (newMessage, attachmentsFull) => {
      if (!isUserLoaded || !user) {
        console.error("User not loaded or not logged in");
        return;
      }

      let messageWithDetails = {
        id: newMessage.id,
        content: newMessage.content,
        timestamp: new Date().toISOString(),
        isAI: newMessage.isAI,
        lm_type: newMessage.lm_type,
        user_id: user.id,
        user_name: user.username || user.fullName || "Unknown User",
        user_picture: user.imageUrl || "",
        isRegenerated: newMessage.isRegenerated || false,
        attachments: newMessage.attachments || [],
      };
      WebsocketAPI.sendMessage(messageWithDetails);
      messageWithDetails = {...messageWithDetails, attachments: attachmentsFull} 
      handleNewMessage(messageWithDetails); // Immediately add the message to the local state
    },
    [isUserLoaded, user, handleNewMessage]
  );

  const canInteract = useCallback(() => {
    if (!channel || !user) return false;
    if (channel.creator_id === user.id) return true;
    if (channelState === "private") return false;
    if (channelState === "shared") return false;
    return channelState === "multi_user";
  }, [channel, user, channelState]);

  const MAX_CONTEXT_LENGTH = 200000;

  const transformMessages = (messages) => {
    let transformedMessages = [];
    let totalLength = 0;
    for (let i = messages.length - 1; i >= 0; i--) {
      const msg = messages[i];
      const role = msg.isAI ? "assistant" : "user";
      const code = msg.artifacts?.length > 0 ? msg.artifacts[0].content : null;
      const transformedMsg = {
        role: role,
        content: code ? JSON.stringify(msg.content + " Code Generated:\n" + code).slice(1, -1) : JSON.stringify(msg.content).slice(1, -1),
      };

      const msgLength = JSON.stringify(transformedMsg).length;

      if (totalLength + msgLength > MAX_CONTEXT_LENGTH) {
        break;
      }

      transformedMessages.unshift(transformedMsg);
      totalLength += msgLength;
    }

    return transformedMessages;
  };

  const handleStreamingMessage = useCallback((streamingMessage) => {
    setMessages((prevMessages) => {
      const messageIndex = prevMessages.findIndex(
        (msg) => msg.id === streamingMessage.id
      );
      if (messageIndex !== -1) {
        // Update existing message
        const updatedMessages = [...prevMessages];
        updatedMessages[messageIndex] = {
          ...updatedMessages[messageIndex],
          ...streamingMessage,
          lm_type:
            streamingMessage.lm_type || updatedMessages[messageIndex].lm_type,
        };
        return sortMessagesByTimestamp(updatedMessages);
      } else {
        // Add new streaming message
        return [...prevMessages, streamingMessage];
      }
    });
  }, []);

  const parseArtifactResponse = (artifactResponse) => {
    // Extract file content
    const fileMatches = [
      ...artifactResponse.matchAll(
        /<file>\s*<file_name>(.*?)<\/file_name>\s*<file_content>([\s\S]*?)(?=<\/file_content>|$)/g
      ),
    ];

    const codeResponse = {
      files: fileMatches.map((match) => ({
        path: match[1],
        content: match[2].trim(),
      })),
    };

    // Remove file content from the artifact response
    let textResponse = artifactResponse;
    fileMatches.forEach(match => {
      textResponse = textResponse.replace(match[0], '');
    });

    // Replace opening tags with titles and remove closing tags
    textResponse = textResponse
      .replace(/<analysis>/g, '**Requirement Analysis**\n\n')
      .replace(/<\/analysis>/g, '')
      .replace(/<implementation_plan>/g, '**Implementation Plan**\n\n')
      .replace(/<\/implementation_plan>/g, '')
      .replace(/<explanation>/g, '**Explanation**\n\n')
      .replace(/<\/explanation>/g, '')
      .replace(/<justification>/g, '**Justification**\n\n')
      .replace(/<\/justification>/g, '')
      .replace(/<code_output>/g, '')
      .replace(/<\/code_output>/g, '')
      .replace(/<file_content>/g, '')
      .replace(/<\/file_content>/g, '')
      .replace(/<file>/g, '')
      .replace(/<\/file>/g, '')
      .trim();

    return { ...codeResponse, textResponse };
  };

  // Modified sendAIMessage function
  const sendAIMessage = useCallback(
    async (messageContent, options = {}) => {
      if (!messageContent.trim()) {
        toast.error("Input is required to use AI features");
        return;
      }

      const {
        selectedModelOverride,
        messageId = uuidv4(),
        userMessageId = uuidv4(),
        sendUserMessage = true,
        isRegenerated = false,
        attachments = [],
        attachmentsFull = [],
      } = options;
      const lm_type = selectedModelOverride || selectedModel;

      setIsStreaming(true);
      setShouldScrollToBottom(true);
      setSlmLeaderboardData({});
      let accumulatedResponse = "";
      let accumulatedArtifactResponse = "";

      if (sendUserMessage) {
        const userMessageData = {
          id: userMessageId,
          content: messageContent,
          timestamp: new Date().toISOString(),
          isAI: false,
          user_id: user.id,
          lm_type: lm_type,
          attachments: attachments,
          parent_id: null
        };
        handleSendMessage(userMessageData, attachmentsFull);
        WebsocketAPI.sendMessage(userMessageData);
      }

      const transformedMessages = transformMessages(messages);

      try {
        const reader = await AIAPI.streamResponse(
          messageId,
          messageContent,
          transformedMessages,
          lm_type,
          libraryId,
          12,
          {},
          activeProjectId,
          isSharedContext,
          customInstructions,
          attachments,
        );
        const decoder = new TextDecoder();

        let isStreamEnded = false;
        let hasReceivedSLMData = false;
        let sources = [];

        while (!isStreamEnded) {
          const { value, done } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          const lines = chunk.split("\n");

          for (const line of lines) {
            if (line.startsWith("data: ")) {
              try {
                const eventData = JSON.parse(line.slice(6));
                if (eventData.type_id === "context_analysis") {
                  contextAnalysisData[eventData.chunk.lm_type] = eventData.chunk;
                  setContextAnalysisData(prev => ({
                    ...prev,
                    [eventData.chunk.lm_type]: eventData.chunk
                  }));
                } else if (eventData.type_id === "text") {
                  accumulatedResponse += eventData.chunk;
                  const streamingMessage = {
                    id: messageId,
                    content: accumulatedResponse,
                    timestamp: new Date().toISOString(),
                    isAI: true,
                    isStreaming: true,
                    user_id: "Ephor AI",
                    lm_type,
                  };
                  handleStreamingMessage(streamingMessage);
                } else if (eventData.type_id === "code") {
                  if (isFirstCodeRef.current) {
                    setSelectedPanel("artifacts");
                    setIsPanelVisible(true);
                    //InteractAPI.spawnSandbox();
                    accumulatedCodeRef.current = "";
                    isFirstCodeRef.current = false;
                  }
                  accumulatedCodeRef.current += eventData.chunk;
                  setCurrentCode(accumulatedCodeRef.current);
                } else if (eventData.type_id === "status") {
                  if (statusToastId) {
                    toast.dismiss(statusToastId);
                  }
                  const newToastId = toast(eventData.chunk, {
                    duration: 5000,
                    position: "top-center",
                  });
                  setStatusToastId(newToastId);
                  if (
                    eventData.chunk.includes("React code") &&
                    eventData.chunk.includes("Completed")
                  ) {
                    const artifact = {
                      artifact_id: uuidv4(),
                      template: "",
                      content: accumulatedCodeRef.current,
                      published_url: "",
                    };
                    setIsPanelVisible(true);
                    setSelectedPanel('artifacts');
                    setCurrentCode(accumulatedCodeRef.current || '');
                    setTriggerPreview(true);
                    setSelectedArtifact(artifact);
                  }
                } else if (eventData.type_id === "slm_text") {
                  if (!hasReceivedSLMData && window.innerWidth >= 768) {
                    setSelectedPanel("slm_leaderboard");
                    setIsPanelVisible(true);
                  }

                  hasReceivedSLMData = true;

                  const {
                    slm_type,
                    slm_response_end = false,
                    slm_time_to_complete = -1,
                    slm_similarity_score = -1,
                  } = eventData.metadata;

                  if (!slmLeaderboardData[slm_type]) {
                    slmLeaderboardData[slm_type] = {
                      name: slm_type,
                      content: "",
                      time: -1,
                      similarity: -1,
                      is_completed: false,
                    };
                  }

                  if (slm_response_end) {
                    slmLeaderboardData[slm_type].is_completed = true;
                  }
                  if (slm_time_to_complete !== -1) {
                    slmLeaderboardData[slm_type].time = slm_time_to_complete;
                  }
                  if (slm_similarity_score !== -1) {
                    slmLeaderboardData[slm_type].similarity =
                      slm_similarity_score;
                  }
                  if (eventData.chunk.length > 0) {
                    slmLeaderboardData[slm_type].content += eventData.chunk;
                  }

                  setSlmLeaderboardData({ ...slmLeaderboardData });
                } else if (eventData.type_id === "sources") {
                  const sourceList = eventData.chunk;
                  console.log("Sources:", sourceList);
                  sources = sourceList;
                  setSources(sources);
                } else if (eventData.type_id === "artifact") {
                  // setSelectedPanel("artifacts");
                  accumulatedArtifactResponse += eventData.chunk;
                  const artifactResponse = parseArtifactResponse(
                    accumulatedArtifactResponse
                  );

                  if (artifactResponse.files) {
                    if (isFirstCodeRef.current) {
                      setSelectedPanel("artifacts");
                      setIsPanelVisible(true);
                      // InteractAPI.spawnSandbox();
                      isFirstCodeRef.current = false;
                    }
                    let code = "";
                    artifactResponse.files.forEach((file) => {
                      code += `\n\n// File: ${file.path}\n\n${file.content}`;
                    });
                    accumulatedCodeRef.current = code;
                    setCurrentCode(code);
                  }

                  const streamingMessage = {
                    id: messageId,
                    content:
                      accumulatedResponse + artifactResponse.textResponse,
                    timestamp: new Date().toISOString(),
                    isAI: true,
                    isStreaming: true,
                    user_id: "Ephor AI",
                    lm_type,
                  };
                  handleStreamingMessage(streamingMessage);
                } else if (eventData.type_id === "end" && eventData.is_final) {
                  const artifactResponse = parseArtifactResponse(
                    accumulatedArtifactResponse
                  );
                  console.log("Artifact Response:", artifactResponse);

                  const finishedCode = accumulatedCodeRef.current;

                  // Get all previous artifacts
                  const previousArtifacts = messages
                    .filter((msg) => msg.artifacts && msg.artifacts.length > 0)
                    .flatMap((msg) => msg.artifacts);

                  // Extract files from previous artifacts
                  let allFiles = {};
                  previousArtifacts.forEach((artifact) => {
                    const files = extractFilesFromArtifact(artifact.content);
                    allFiles = { ...allFiles, ...files };
                  });

                  // Extract files from the current finished code
                  const currentFiles = extractFilesFromArtifact(finishedCode);

                  // Merge current files with previous files, overriding as necessary
                  allFiles = { ...allFiles, ...currentFiles };

                  // Convert merged files back to a single string
                  const mergedCode = Object.entries(allFiles)
                    .map(
                      ([filename, content]) =>
                        `// File: ${filename}\n\n${content}`
                    )
                    .join("\n\n");

                  setCurrentCode(mergedCode);

                  const artifact = {
                    artifact_id: uuidv4(),
                    template: "",
                    content: mergedCode,
                    published_url: "",
                  };

                  let messageContent = accumulatedResponse;
                  if (artifactResponse.textResponse) {
                    messageContent += "\n" + artifactResponse.textResponse;
                  }
                  
                  if(mergedCode) {
                    setSelectedArtifact(artifact);
                  }
                  
                  console.log("Context analysis data:", contextAnalysisData);
                  const finalMessage = {
                    id: messageId,
                    content: messageContent,
                    timestamp: new Date().toISOString(),
                    isAI: true,
                    isStreaming: false,
                    user_id: "Ephor AI",
                    user_name: "Ephor AI",
                    artifacts:
                      !isFirstCodeRef.current && mergedCode ? [artifact] : [],
                    lm_type,
                    isRegenerated,
                    sources,
                    slm_leaderboard: JSON.stringify(slmLeaderboardData) || "{}",
                    context_analysis: JSON.stringify(contextAnalysisData) || "{}",
                    parent_id: userMessageId,
                  };

                  console.log("Final message:", finalMessage);
                  WebsocketAPI.sendMessage(finalMessage);
                  handleStreamingMessage(finalMessage);
                  isStreamEnded = true;
                  isFirstCodeRef.current = true;
                  if (statusToastId) {
                    toast.dismiss(statusToastId);
                    setStatusToastId(null);
                  }

                  break;
                }
              } catch (error) {
                console.error("Error parsing event data:", error);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to get AI response");
      } finally {
        setIsStreaming(false);
        isFirstCodeRef.current = true;
        if (statusToastId) {
          toast.dismiss(statusToastId);
          setStatusToastId(null);
        }

        // Push event to Google Tag Manager
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'chat_sent',
          'lm_type': lm_type,
        });
      }
    },
    [
      messages,
      selectedModel,
      libraryId,
      user,
      statusToastId,
      isSharedContext,
      activeProjectId,
    ]
  );

  const shouldDisplayQueries =
    (messages.length === 0 ||
      messages[messages.length - 1].isAI ||
      messages[messages.length - 1].user_name === "Ephor AI") &&
    !isStreaming &&
    isInteractive;

  // Adjust resendAIMessage to use sendAIMessage
  const resendAIMessage = useCallback(
    (id, lm_type) => {
      console.log(
        `Resending AI message with id: ${id} and lm_type: ${lm_type}`
      );

      const messageIndex = messages.findIndex((msg) => msg.id === id);
      if (messageIndex === -1) {
        console.log(`Message with id: ${id} not found`);
        return; // Message not found
      }

      const precedingUserMessageIndex = messages
        .slice(0, messageIndex)
        .reverse()
        .findIndex((msg) => !msg.isAI && msg.user_name !== "Ephor AI");

      if (precedingUserMessageIndex === -1) {
        console.log(
          `No preceding user message found for message with id: ${id}`
        );
        return; // No preceding user message found
      }

      const precedingUserMessage =
        messages[messageIndex - precedingUserMessageIndex - 1];

      console.log(
        `Found preceding user message with id: ${precedingUserMessage.id}`
      );

      // Now, we can call sendAIMessage with the content of the precedingUserMessage
      sendAIMessage(precedingUserMessage.content, {
        selectedModelOverride: lm_type,
        messageId: id, // Use the same messageId to overwrite the AI message
        attachments: precedingUserMessage.attachments,
        sendUserMessage: false, // Do not send the user message again
        isRegenerated: true,
      });
    },
    [messages, sendAIMessage]
  );

  const handleChannelStateChange = async (newState) => {
    if (!isUserLoaded || !user) {
      console.error("User not loaded or not logged in");
      return;
    }

    const updatedChannel = await ChannelsAPI.updateChannel(
      activeProjectId,
      activeChannelId,
      { state: newState }
    );

    setChannelState(updatedChannel.state);
    toast.success(`Chat is now ${newState.replace("_", " ")}`, {
      duration: 3000,
      position: "top-center",
    });
    // Push event to Google Tag Manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'chat_type_change',
      'changed_to': newState
    });
    await LibraryAPI.ingestSharedChats(activeProjectId, libraryId);
  };

  useEffect(() => {
    const eventListener = (event) => handleNewMessage(event.detail);
    window.addEventListener("newWebSocketMessage", eventListener);
    return () => {
      window.removeEventListener("newWebSocketMessage", eventListener);
    };
  }, [handleNewMessage]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;
    const handleScroll = () => {
      const {scrollTop, scrollHeight, clientHeight} = container;
      const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
      if (distanceFromBottom > 5) {
        setShouldScrollToBottom(false);
      } else {
        setShouldScrollToBottom(true);
      }
    }
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    }
  }, [messages]);

  useEffect(() => {
    if (shouldScrollToBottom) {
      scrollToBottom();
    }
  }, [messages, shouldScrollToBottom]);

  useEffect(() => {
    // Reset component state when channelId changes
    (async () => {
      setChannel(null);
      setMessages([]);
      setIsLoading(true);
      setError(null);
      setRetryCount(0);

      if (isUserLoaded && activeChannelId) {
        fetchChannelAndMessages();
        await WebsocketAPI.connect();
      }
    })();
  }, [activeChannelId]);


  useEffect(() => {
    setChannel((prev) => ({
      ...prev,
      name: autoGeneratedChannelName
    }));
  }, [autoGeneratedChannelName]);


  useEffect(() => {
    setChannel((prev) => ({
      ...prev,
      name: editedChannelName
    }));
  }, [editedChannelName]);

  const downloadAttachment = async (attachment) => {
    console.log('Downloading attachment:', attachment.attachment_id);
    try {
      const response = await AttachmentAPI.getAttachment(attachment.attachment_id);
      const dataBase64 = response.b64; // Ensure you're accessing the correct property
      const fileName = attachment.name;
      const extension = attachment.extension;
      const base64 = dataBase64.split(',')[1]; // Adjust if necessary

      // Convert base64 to a Blob
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/octet-stream' });

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Failed to download attachment:', error);
    }
  }

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const today = new Date();
    // if before today, show date and time AM / PM
    if (date.toDateString() !== today.toDateString()) {
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    } else {
      // if today, show time AM / PM
      return date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    }
  };

  const handleFeedbackSubmit = useCallback(async (messageId, feedbackData) => {
    // Find message in current state
    const aiMessage = messages.find(msg => msg.id === messageId);
    if (!aiMessage || !aiMessage.parent_id) {
      console.log('Message not found or invalid:', messageId);
      return;
    }

    const userMessage = messages.find(msg => msg.id === aiMessage.parent_id);
    if (!userMessage) {
      console.log('Parent message not found');
      return;
    }

    console.log('Current message state:', {
      messageId,
      hasFeedback: !!aiMessage.feedback_id,
      feedbackId: aiMessage.feedback_id,
      feedbackType: aiMessage.feedback_type
    });

    try {
      // If clicking an active feedback button, delete the feedback
      if (aiMessage.feedback_id) {
        console.log('Deleting feedback:', aiMessage.feedback_id);
        await FeedbackAPI.deleteFeedback(aiMessage.feedback_id);
        
        // Update local state to remove feedback
        const updatedMessage = {
          ...aiMessage,
          feedback_id: null,
          feedback_type: null,
          feedback_category: null,
          feedback_details: null
        };
        
        // Update messages state to ensure future checks see the updated state
        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.id === messageId ? updatedMessage : msg
          )
        );
        
        console.log('Feedback deleted, message updated');
        return;
      }

      // Create new feedback
      const feedbackPayload = {
        feedback_id: uuidv4(),
        message_id: messageId,
        channel_id: activeChannelId,
        account_id: userMessage.user_id,
        parent_id: aiMessage.parent_id,
        user_query: userMessage.content,
        slm_leaderboard: aiMessage.slm_leaderboard || '{}',
        sources: aiMessage.sources || [],
        artifacts: aiMessage.artifacts || [],
        feedback_type: feedbackData.type,
        feedback_category: feedbackData.category || null,
        feedback_details: feedbackData.details || null
      };

      console.log('Creating new feedback:', feedbackPayload);
      await FeedbackAPI.createFeedback(feedbackPayload);

      // Update local state with new feedback
      const updatedMessage = {
        ...aiMessage,
        feedback_id: feedbackPayload.feedback_id,
        feedback_type: feedbackData.type,
        feedback_category: feedbackData.category,
        feedback_details: feedbackData.details
      };

      // Update messages state to ensure future checks see the updated state
      setMessages(prevMessages => 
        prevMessages.map(msg => 
          msg.id === messageId ? updatedMessage : msg
        )
      );

      console.log('Feedback created, message updated');

    } catch (error) {
      console.error('Failed to handle feedback:', error);
      toast.error('Failed to submit feedback');
    }
  }, [messages, setMessages, FeedbackAPI, activeChannelId]);

  if (!activeChannelId) return <div>Please select a channel</div>;
  if (isLoading) return <Loader />;
  if (error) {
    return (
      <div className="error-container">
        <p>Error: {error}</p>
        {retryCount < 3 && <p>Retrying... (Attempt {retryCount + 1}/3)</p>}
        <button
          onClick={() => {
            setRetryCount(0);
            fetchChannelAndMessages();
          }}
          className="retry-button"
        >
          Retry Now
        </button>
      </div>
    );
  }
  if (!channel) return <div>Channel not found</div>;
  if (!isConnected) return <div>Connecting to chat...</div>;

  return (
    <div className="project-chat-input">
      <div className="channel-header">
        <div className="_chat-sidebar-header-toggle">
          <button onClick={() => setChatSidebarOpen(true)}>
            <IoMdMenu />
          </button>
        </div>
        <h3>#{channel.name}</h3>
        <button
          onClick={handleForkChannel}
          className="fork-channel-button"
          title="Fork this channel"
        >
          <FaCodeBranch />
        </button>
        <BrainDumpMenu onExport={handleExport} />
        <ChatDropdown
          options={sharingOptions}
          value={channelState}
          onChange={handleChannelStateChange}
          iconList={iconList}
          disabled={!(user && channel.creator_id === user.id)}
        />
      </div>
      <div className="messages-container">
        <div className="messages" ref={messagesContainerRef}>
          {messages.map((msg, index) => (
            <React.Fragment key={msg.id || index}>
              <div
                className={`message ${msg.isAI || msg.user_name === "Ephor AI" ? "ai-message" : ""}`}
              >
                <div className="avatar">
                  {msg.isAI || msg.user_name === "Ephor AI" ? (
                    <EphorLogo />
                  ) : msg.user_picture ? (
                    <img
                      src={msg.user_picture}
                      alt={`${msg.user_name}'s avatar`}
                      className="avatar-image rounded-full"
                    />
                  ) : (
                    <div className="default-avatar rounded-full">
                      {msg.user_name?.charAt(0).toUpperCase()}
                    </div>
                  )}
                </div>
                <div className="content">
                  <p className="user">
                    {msg.isAI || msg.user_name === "Ephor AI" ? (
                      <>
                        Ephor AI
                        {msg.lm_type && (
                          <span className="text-xs text-gray-500 ml-2">
                            (
                            {Object.entries(lmTypes).find(
                              ([_, value]) => value === msg.lm_type
                            )?.[0] || msg.lm_type}
                            )
                          </span>
                        )}
                      </>
                    ) : (
                      msg.user_name
                    )}
                    <span className="timestamp">
                      {formatTimestamp(msg.timestamp)}
                    </span>
                    {msg.isStreaming && (
                      <span className="text-xs text-blue-500 ml-2">
                        (typing...)
                      </span>
                    )}
                  </p>
                  <div className="text">
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      components={{
                        h1: ({node, ...props}) => <h1 style={{color: 'blue'}} {...props} />,
                        li: ({node, ...props}) => <li style={{marginBottom: '0.5em'}} {...props} />,
                        code({node, inline, className, children, ...props}) {
                          const match = /language-(\w+)/.exec(className || '')
                          return !inline && match ? (
                            <SyntaxHighlighter
                              language={match[1]}
                              PreTag="div"
                              {...props}
                            >
                              {String(children).replace(/\n$/, '')}
                            </SyntaxHighlighter>
                          ) : (
                            <code className={className} {...props}>
                              {children}
                            </code>
                          )
                        }
                      }}
                    >
                      {msg.content}
                    </ReactMarkdown>
                  </div>
                  <>
                  <div className="_attachments_container no-left-padding">
                  {msg.attachments 
                    ? msg.attachments.map((attachment) => {
                      if (['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'].includes(attachment.extension )) {
                        return (
                          <div key={attachment.attachment_id} className="_image_attachment_container">
                            <img
                              className="_image_attachment download"
                              src={attachment.base64 || loadedAttachments[attachment.attachment_id] || loadingImageBase64()}
                              alt={attachment.name}
                              onClick={() => downloadAttachment(attachment)}
                            />
                            <span className="_image_attachment_name">
                              {attachment.name}
                            </span>
                          </div>
                        );
                      } else {
                        return (
                          <div
                        key={attachment.attachment_id}
                        className="_text_attachment_container download"
                      >
                        <div className="_text_attachment small" onClick={() => downloadAttachment(attachment)}>
                          <span className="text-xs">{attachment.extension?.toUpperCase() || 'FILE'}</span>
                        </div>
                          <span className="_text_attachment_name">
                            {attachment.name}
                          </span>
                        </div>
                        );
                      }
                    })
                    : ''
                    }
                    </div>
                  </>
                </div>
              </div>
              <div className="message-options">
                {(msg.isAI || msg.user_name === "Ephor AI") && msg.parent_id && (
                  <MessageFeedbackButton 
                    messageId={msg.id}
                    initialFeedback={{
                      type: msg.feedback_type,
                      category: msg.feedback_category,
                      details: msg.feedback_details,
                      feedbackId: msg.feedback_id
                    }}
                    onFeedbackSubmit={(feedbackData) => 
                      handleFeedbackSubmit(msg.id, feedbackData)
                    }
                    queryUserId={messages.find(m => m.id === msg.parent_id)?.user_id}
                    currentUserId={user?.id}
                  />
                )}
                {msg.user_name === "Ephor AI" &&
                  index === messages.length - 1 &&
                  isInteractive &&
                  !isStreaming && (
                    <MessageOptionsPopUp
                      messageId={msg.id}
                      lmTypes={lmTypes}
                      selectedType={
                        Object.entries(lmTypes).find(
                          ([_, value]) => value === msg.lm_type
                        )?.[0] ||
                        msg.lm_type ||
                        ""
                      }
                      onSelect={resendAIMessage}
                    />
                  )}
                {msg.user_name === "Ephor AI" &&
                  msg.sources &&
                  msg.sources.length > 0 &&
                  (msg.sources.length != 1 && msg.sources[0].id !== "folder_structure") && (
                    <RetractableButton
                      messageId={msg.id}
                      selectedType="Sources"
                      onSelect={() => {
                        try {
                          const sources = msg.sources;
                          setSelectedPanel("sources");
                          setSources(sources);
                        } catch (e) {
                          console.error("Failed to parse SLM leaderboard:", e);
                        }
                      }}
                      Icon={FaFileCode}
                    />
                  )}
                {msg.user_name === "Ephor AI" &&
                  msg.slm_leaderboard &&
                  Object.keys(JSON.parse(msg.slm_leaderboard)).length > 0 && (
                    <RetractableButton
                      messageId={msg.id}
                      selectedType="Leaderboard"
                      onSelect={() => {
                        try {
                          const leaderboardData = JSON.parse(
                            msg.slm_leaderboard
                          );
                          setSelectedPanel("slm_leaderboard");
                          setSlmLeaderboardData(leaderboardData);
                          setContextAnalysisData(JSON.parse(msg.context_analysis) || {});
                          console.log("Context analysis data:", contextAnalysisData);
                        } catch (e) {
                          console.error("Failed to parse SLM leaderboard:", e);
                        }
                      }}
                      Icon={FaTrophy}
                      disabled={isStreaming}
                    />
                  )}
                {msg.artifacts && msg.artifacts.length > 0 && (
                  <MessageArtifactButton artifact={msg.artifacts[0]} />
                )}
              </div>
            </React.Fragment>
          ))}
          {shouldDisplayQueries && (
            <MessageRelatedQueries
              messages={messages}
              scrollToBottom={scrollToBottom}
              sendAIMessage={sendAIMessage}
            />
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>
      {canInteract() ? (
        <ChatInput
          messages={messages}
          channelId={activeChannelId}
          onSendMessage={handleSendMessage}
          projectId={activeProjectId}
          libraryId={libraryId}
          onStreamingMessage={handleStreamingMessage}
          sendAIMessage={sendAIMessage}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          channelState={channelState}
          isSharedContext={isSharedContext}
          setSharedContext={setSharedContext}
          isInternetEnabled={isInternetEnabled}
          setIsInternetEnabled={setIsInternetEnabled}
          lockModelSelection={lockModelSelection}
          setLockModelSelection={setLockModelSelection}
        />
      ) : (
        // add border radius 8 here
        <div className="_read-only-message p-4 bg-gray-100 text-center">
          This channel is {channelState === "shared" ? "read-only" : "private"}.
        </div>
      )}
    </div>
  );
};

export default ProjectChatInput;
