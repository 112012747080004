import React, { useState, useEffect, useRef } from 'react';
import { LuRefreshCcw, LuChevronDown } from "react-icons/lu";

const MessageOptionsPopUp = ({ messageId, lmTypes, selectedType, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    setIsOpen(false);
    onSelect(messageId, option);
  }

  return (
    <>
      <button
        ref={buttonRef}
        className="_regenerate-ai-message-button"
        onClick={toggleMenu}
      >
        <LuRefreshCcw />
        <span>{selectedType}</span>
        <LuChevronDown />
        {isOpen && (
          <div
            ref={menuRef}
            className='_regenerate-ai-message-button-options'
            style={{
            }}
          >
            {Object.entries(lmTypes).map(([name, value]) => (
              <button
                key={value}
                onClick={() => handleSelect(value)}
                className="_regenerate-ai-message-button-options"
              >
                {name}
              </button>
            ))}
          </div>
        )}
      </button>
    </>
  );
};

export default MessageOptionsPopUp;