import {
	SandpackCodeEditor,
	SandpackLayout,
	SandpackPreview,
	SandpackProvider,
	useSandpack,
	useSandpackConsole,
} from '@codesandbox/sandpack-react'
import { dracula as draculaTheme } from '@codesandbox/sandpack-themes'
import { saveAs } from 'file-saver'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useState, useCallback } from 'react'
import PreviewOptionsMenu from './PreviewOptionsMenu'
import LZString from 'lz-string'
import AutoFixButton from './AutoFixButton'
import { FaExpand, FaCompress } from 'react-icons/fa'
import { sanitizeCode, getIndexCode, toCamelCaseFolderName, getBaseName } from './Utils'
import JSZip from 'jszip'
import { useSandbox } from './SandboxProvider'
import Alert from './Alert'
import { extractFilesFromArtifact } from '@/lib/artifacts'
import dedent from 'ts-dedent'

function SandpackContent({
	children,
	onAutoFix,
	onSandpackError,
	onReady,
	height,
	width,
	files,
	onMessage,
	onPublish,
	hidePreviewOptionsMenu,
	hideFullScreenOption,
	publishedUrl,
	onDownload
}) {
	const [isPreviewOnly, setIsPreviewOnly] = useState(true)
	const [isReady, setIsReady] = useState(false)
	const [showAutoFixButton, setShowAutoFixButton] = useState(true)
	const [isFullScreen, setIsFullScreen] = useState(false)

	const { sandpack, listen, dispatch } = useSandpack()
	const { error, status } = sandpack
	const [state, setState] = useState('idle')
	const [isAutoFixInProgress, setIsAutoFixInProgress] = useState(false)
	const { logs, reset } = useSandpackConsole({ resetOnPreviewRestart: true })
	const [consoleError, setConsoleError] = useState(null)

	const handleDownload = () => {
		onDownload?.()
	}

	const handleOpenInCodesandbox = () => {
		const parameters = {
			files: Object.entries(files).reduce(
				(acc, [path, { code }]) => {
					acc[path.replace(/^\//, '')] = { content: code }
					return acc
				},
				{}
			),
		}

		const compressed = LZString.compressToBase64(JSON.stringify(parameters))
			.replace(/\+/g, '-') // Convert '+' to '-'
			.replace(/\//g, '_') // Convert '/' to '_'
			.replace(/=+$/, '') // Remove ending '='

		const form = document.createElement('form')
		form.method = 'POST'
		form.target = '_blank'
		form.action = 'https://codesandbox.io/api/v1/sandboxes/define'
		form.style.display = 'none'

		const input = document.createElement('input')
		input.name = 'parameters'
		input.value = compressed
		form.appendChild(input)

		document.body.appendChild(form)
		form.submit()
		document.body.removeChild(form)
	}

	const toggleCodeView = () => {
		setIsPreviewOnly(!isPreviewOnly)
	}

	const handleRestart = () => {
		// dispatch({ type: 'shell/restart' })
	}

	const handleRefresh = () => {
		dispatch({ type: 'refresh' })
	}

	const handleDiscardAutoFix = () => {
		reset()
		setConsoleError(null)
		setShowAutoFixButton(false)
	}

	const handleError = (error) => {
		console.log('error: ', error)
		const errorMessage = dedent`
		**${error.title || "Something went wrong"}**

		${error.message}
		`
		onSandpackError?.(error)
		setConsoleError(errorMessage);
		setShowAutoFixButton(true);
	}

	const resetError = () => {
		setConsoleError(null)
		setShowAutoFixButton(false)
	}

	useEffect(() => {
		console.log('status: ', status)

		if (error) {
			handleError(error)
		}

		const stopListening = listen((msg) => {
		
			if (msg.type === 'dependencies') {
				// do nothing
				resetError()
			} else if (msg.type === 'status') {
				if (msg.status === 'transpiling') {
					resetError()
				} else if (msg.status === 'evaluating') {
					resetError()
				}
			} else if (msg.type === 'done') {
				setIsReady(true)
				onReady?.()
			}
		})
		return () => {
			stopListening()
		}
	}, [listen, state, error, status])

	const toggleFullScreen = useCallback(() => {
		setIsFullScreen((prev) => !prev)
	}, [])

	return (
		<div
			className="relative"
			style={{
				height: isFullScreen ? '100vh' : height,
				width: isFullScreen ? '100vw' : width,
				position: isFullScreen ? 'fixed' : 'relative',
				top: isFullScreen ? 0 : 'auto',
				left: isFullScreen ? 0 : 'auto',
				zIndex: isFullScreen ? 9999 : 'auto',
			}}
		>
			{!hideFullScreenOption && (
				<button className="absolute right-2 top-2 z-50 rounded bg-gray-800 p-2 text-white" onClick={toggleFullScreen}>
					{isFullScreen ? <FaCompress /> : <FaExpand />}
				</button>
			)}
			<SandpackLayout>
				{!isPreviewOnly && (
					<SandpackCodeEditor
						style={{
							height: isFullScreen ? '100vh' : height,
							width: '50%',
						}}
						showRunButton={false}
						showInlineErrors={true}
						wrapContent={true}
						showLineNumbers={true}
						showTabs={true}
						showReadOnly={true}
					/>
				)}
				<div
					className="relative flex"
					style={{
						height: isFullScreen ? '100vh' : height,
						width: isPreviewOnly ? '100%' : '50%',
					}}
				>
					<SandpackPreview
						style={{
							height: isFullScreen ? '100vh' : height,
							width: '100%',
						}}
						showRefreshButton={false}
						showNavigator={false}
						showRestartButton={false}
						showOpenInCodeSandbox={false}
						showOpenNewtab={false}
						showSandpackErrorOverlay={true}
					/>
				</div>
				{consoleError && !isAutoFixInProgress && showAutoFixButton && (
					<div className="absolute bottom-2 left-1/2 z-50 flex -translate-x-1/2 -translate-y-1/2 transform items-center gap-2">
						{onAutoFix && (
							<AutoFixButton
								error={consoleError}
								onClick={() => {
									setIsAutoFixInProgress(true)
									onAutoFix({ message: consoleError }, () => {
										setIsAutoFixInProgress(false)
									})
								}}
								onDiscard={handleDiscardAutoFix}
							/>
						)}
					</div>
				)}
			</SandpackLayout>
			{state !== 'shell/progress' && isReady && (
				<div className="absolute bottom-2 right-2 z-50">
					{!hidePreviewOptionsMenu && (
						<PreviewOptionsMenu
							onShowCode={toggleCodeView}
							onDownload={handleDownload}
							onRestart={handleRestart}
							onRefresh={handleRefresh}
							onOpenInCodesandbox={handleOpenInCodesandbox}
							onPublish={onPublish}
							files={files}
							isPreviewOnly={isPreviewOnly}
							publishedUrl={publishedUrl}
						/>
					)}
				</div>
			)}
		</div>
	)
}

export default function CodeEditor({
	selectedArtifact,
	theme,
	onAutoFix,
	onSandpackError,
	onReady,
	onMessage,
  onPublish,
	children,
	height,
	width,
	hidePreviewOptionsMenu,
	hideFullScreenOption,
}) {
  const { isLoading, template, publishArtifact, getTailwindCSS } = useSandbox();
	const [sandpackFiles, setSandpackFiles] = useState({})
  const [alert, setAlert] = useState(null);
  const [isReady, setIsReady] = useState(false);

	const getSandpackFiles = () => {
		let files = {};

		const getSandpackPath = (path) => {
			return `/${path.replace(/^\//, '')}`
		}

		Object.entries(template?.files || {}).forEach(([path, code]) => {
			let sandpackPath = getSandpackPath(path)
			files[sandpackPath] = {
				code,
				active: false,
				hidden: true,
				readOnly: true,
			}
		})

		files['/src/index.tsx'] = {
			code: getIndexCode(),
			active: false,
			hidden: true,
			readOnly: true,
		}

		if (selectedArtifact && isReady) {
			console.log('selectedArtifact:', selectedArtifact)
			const codeFiles = extractFilesFromArtifact(selectedArtifact.content)
			console.log('codeFiles:', codeFiles)
			const artifactFolderName = toCamelCaseFolderName(getBaseName(selectedArtifact.artifact_id.split('-')[0] ?? 'Artifact'))
			const basePath = `/src/${artifactFolderName}`
			let active = true
			Object.entries(codeFiles || {}).forEach(([path, code]) => {
				const sandpackPath = `${basePath}/${getBaseName(path)}`
				files[sandpackPath] = {
					code: sanitizeCode(code),
					active,
					hidden: false,
					readOnly: false,
				}
				active = false
			})
			if(codeFiles["App.tsx"]) {
				files[`${basePath}/index.tsx`] = {
					code: codeFiles["App.tsx"],
					active: false,
					hidden: false,
					readOnly: false,
				}
			}
			if (codeFiles) {
				files['/src/index.tsx'] = {
					code: getIndexCode(selectedArtifact.artifact_id.split('-')[0] ?? 'Artifact'),
					active: false,
					hidden: true,
					readOnly: true,
				}
			}
		}

		if (theme) {
			files['/src/index.css'] = {
				code: theme,
				active: false,
				hidden: true,
				readOnly: true,
			}
		}

		//console.log('files:', files)

		return files
	}

	useEffect(() => {
		const updateSandpackFiles = async () => {
      if (isLoading) {
        return;
      }
			console.log('updateSandpackFiles')

			let files = getSandpackFiles()

			try {
				const tailwindCssContent = await getTailwindCSS({
					templateName: 'react-ts',
					additionalFiles: Object.entries(files).map(([path, { code }]) => ({ path, content: code })),
				})

				files['/src/tailwind.css'] = {
					code: tailwindCssContent,
					active: false,
					hidden: true,
					readOnly: true,
				}
			} catch (error) {
				console.error('Failed to fetch Tailwind CSS:', error)
				// Handle error as needed
			}

			setSandpackFiles(files)
		}

		updateSandpackFiles()
	}, [selectedArtifact, template, theme, isLoading, isReady])

	const handleAutoFix = async (error, callback) => {
		onAutoFix?.(error, callback)
	}

	const handleOnReady = () => {
    setIsReady(true);
		onReady?.()
	}

	const handlePublish = () => {
    if (selectedArtifact) {
      publishArtifact(selectedArtifact, (publishedUrl) => {
        console.log("publishedUrl:", publishedUrl);
        onPublish?.(selectedArtifact, publishedUrl);
        navigator.clipboard.writeText(publishedUrl)
        setAlert({
          type: "success",
          message: "Artifact published successfully! Published URL copied to clipboard!",
        });
      });
    }
  };

	const handleDownload = () => {
		const zip = new JSZip()

		// Add all files to the zip
		Object.entries(sandpackFiles).forEach(([path, { code }]) => {
			// Remove the leading slash from the path
			const filePath = path.replace(/^\//, '')
			zip.file(filePath, code)
		})

		// Generate the zip file
		zip.generateAsync({ type: 'blob' }).then((content) => {
			const artifactFolderName = toCamelCaseFolderName(getBaseName(selectedArtifact?.displayName ?? `artifact-${selectedArtifact?.artifact_id.split('-')[0]}`))
			// Use file-saver to trigger the download
			saveAs(content, `${artifactFolderName}.zip`)
		})
	}

	return Object.keys(sandpackFiles).length > 0 ? (
		<div className="relative" style={{ height, width }}>
			<AnimatePresence>
				<SandpackProvider
          key="sandpack-provider"
					template={'react-ts'}
					theme={draculaTheme}
					files={sandpackFiles}
				>
					<SandpackContent
            key="sandpack-content"
						onAutoFix={handleAutoFix}
						onSandpackError={onSandpackError}
						onReady={handleOnReady}
						height={height}
						width={width}
						files={sandpackFiles}
						onMessage={onMessage}
						onPublish={handlePublish}
						publishedUrl={selectedArtifact?.publishedUrl}
						hidePreviewOptionsMenu={hidePreviewOptionsMenu}
						hideFullScreenOption={hideFullScreenOption}
						onDownload={handleDownload}
					>
						{children}
					</SandpackContent>
				</SandpackProvider>
        {alert && <Alert type={alert.type} message={alert.message} />}
			</AnimatePresence>
		</div>
	) : (
		<div>Loading...</div>
	)
}
