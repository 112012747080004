const RightFromBracketIcon = () =>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_111_56)">
      <path opacity="0.4" d="M-0.0117188 5V15C-0.0117188 17.0703 1.66797 18.75 3.73828 18.75H6.23828C6.92969 18.75 7.48828 18.1914 7.48828 17.5C7.48828 16.8086 6.92969 16.25 6.23828 16.25H3.73828C3.04688 16.25 2.48828 15.6914 2.48828 15V5C2.48828 4.30859 3.04688 3.75 3.73828 3.75H6.23828C6.92969 3.75 7.48828 3.19141 7.48828 2.5C7.48828 1.80859 6.92969 1.25 6.23828 1.25H3.73828C1.66797 1.25 -0.0117188 2.92969 -0.0117188 5Z" fill="white" />
      <path d="M19.5469 8.93359L14.7539 4.13672C14.5039 3.88672 14.168 3.75 13.8164 3.75C13.0859 3.75 12.4922 4.34375 12.4922 5.07422V7.5H7.49219C6.80078 7.5 6.24219 8.05859 6.24219 8.75V11.25C6.24219 11.9414 6.80078 12.5 7.49219 12.5H12.4922V14.9258C12.4922 15.6562 13.0859 16.25 13.8164 16.25C14.168 16.25 14.5039 16.1094 14.7539 15.8633L19.5469 11.0664C19.8281 10.7852 19.9883 10.3984 19.9883 10C19.9883 9.60156 19.8281 9.21484 19.5469 8.93359Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_111_56">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>

export default RightFromBracketIcon;