export const scatter_graph_colors = [
  '#a246a6',
  '#d6b9fa',
  '#a6d6a2',
  '#f2e2a6',
  '#f2a6d6',
  '#a6f2f2',
  '#f2c6a6',
  '#c6a6f2',
  '#a6f2c6',
  '#f2a6c6',
  '#d6f2a6',
  '#a6c6f2',
  '#f2a6a6',
  '#a6f2a6',
  '#a6a6f2',
  '#f2d6a6',
  '#a6f2d6'
];

export const scatter_graph_stroke_colors = [
  '#822f82',
  '#b08bd1',
  '#82b082',
  '#82a2c2',
  '#c282a2',
  '#82c2c2',
  '#c29b82',
  '#9b82c2',
  '#82c29b',
  '#c2829b',
  '#b2c282',
  '#82a2c2',
  '#c28282',
  '#82c282',
  '#8282c2',
  '#c2a282',
  '#82c2a2'
];

export const model_name_map = {
    'openai-4o': 'OpenAI 4o',
    'openai-4om': 'OpenAI 4om',
    'gemini-model-flash': 'Gemini Flash',
    'groq-8': 'Llama 8B',
    'anthropic-haiku3': 'Haiku 3',
    'groq-70': 'Llama 70B',
    'groq-mistral': 'Mistral 7B',
    'groq-gemma': 'Gemma 9B',
    'anthropic-sonnet35': 'Sonnet 3.5', 
    'anthropic-haiku35': 'Haiku 3.5',
    'gemini-1.5-pro': 'Gemini 1.5 Pro',
    'grok-beta': 'Grok Beta',
};

export const model_priority = {
    'openai-4om': 3,
    'gemini-model-flash': 2,
    'groq-8': 2,
    'anthropic-haiku3': 3,
    'groq-70': 2,
    'groq-mistral': 1,
    'groq-gemma': 3
}

export const DEFAULT_CHANNEL_NAME = "Untitled";
export const READ_AI_PLATFORM = "Read_AI";