import React from 'react';
import Library from '@/components/Library';

const ProjectConfigLibrary = () => (
  <div className="_project-explorer-library">
    <h2>Library</h2>
    <Library />
  </div>
);

export default ProjectConfigLibrary;