import React from 'react';
import { LuRefreshCcw } from "react-icons/lu";

const RetractableButton = ({ messageId, selectedType, onSelect, Icon = LuRefreshCcw, disabled }) => {
  return (
    <button
      className="_slm-leaderboard-button"
      onClick={() => onSelect(messageId)}
      disabled={disabled}
      style={{ color: disabled ? 'gray' : 'inherit' }}
    >
      <Icon />
      <span>{selectedType}</span>
    </button>
  );
};

export default RetractableButton;