import { useUser } from "@clerk/clerk-react";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiSparkles } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { useData } from "../contexts/DataContext";
import toast from "react-hot-toast";
import SecondBrain from "./SecondBrain";

import { Loader } from "./Loader";

const ProjectConfigGeneral = ({ projectId }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { projects, ProjectsAPI, refreshAppData, setActiveProjectId } = useData();
  const project = projects.find((p) => p.project_id === projectId);

  const [name, setName] = useState(project?.name || "");
  const [description, setDescription] = useState(project?.description || "");
  const [predefinedQueries, setPredefinedQueries] = useState(
    project?.predefined_queries || []
  );
  const [customInstructions, setCustomInstructions] = useState(project?.custom_instructions || "");
  const [generatedQueries, setGeneratedQueries] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State to control modal visibility
  const inputRefs = useRef([]);

  useEffect(() => {
    const fetchProjectData = async () => {
      const updatedProject = await ProjectsAPI.readProject(projectId);
      if (updatedProject) {
        setName(updatedProject.name);
        setDescription(updatedProject.description);
        setPredefinedQueries([...(updatedProject.predefined_queries || [])]);
        setCustomInstructions(updatedProject.custom_instructions || "");
      }
    };

    fetchProjectData();
  }, [projectId]);

  const handleSave = async () => {
    setIsSaving(true);
    const payload = {
      name,
      description,
      predefined_queries: predefinedQueries,
      custom_instructions: customInstructions,
    };
    try {
      await ProjectsAPI.editProject({ project_id: projectId, ...payload });

      // Re-fetch the project data to ensure the component re-renders with updated data
      await ProjectsAPI.readProject(projectId);
      
      toast.success("Project updated successfully");
    } catch (error) {
      console.error("Failed to save project details:", error);
      if (error.response) {
        switch (error.response.status) {
          case 400:
            toast.error("Invalid input. Please check your data and try again.");
            break;
          case 403:
            toast.error("You don't have permission to edit this project.");
            break;
          case 404:
            toast.error("Project not found. It may have been deleted.");
            break;
          case 409:
            toast.error("Update conflict. The project was modified by another user. Please refresh and try again.");
            break;
          default:
            toast.error("An error occurred while saving the project. Please try again later.");
        }
      } else {
        toast.error("Network error. Please check your connection and try again.");
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleAddQuery = () => {
    setPredefinedQueries([...predefinedQueries, ""]);
    setTimeout(() => {
      const lastIndex = predefinedQueries.length;
      if (inputRefs.current[lastIndex]) {
        inputRefs.current[lastIndex].focus();
      }
    }, 0);
  };

  const handleRemoveQuery = (index) => {
    setPredefinedQueries(predefinedQueries.filter((_, i) => i !== index));
  };

  const handleQueryChange = (index, value) => {
    const newQueries = [...predefinedQueries];
    newQueries[index] = value;
    setPredefinedQueries(newQueries);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddQuery();
    }
  };

  const handleGenerateGuidedQueries = async (index) => {
    if (generatedQueries.length === 0) {
      toast.loading("Generating queries...", { duration: 2000 });
      const newGeneratedQueries = await ProjectsAPI.generateGuidedQueries(
        projectId,
        []
      );
      if (newGeneratedQueries && newGeneratedQueries.length > 0) {
        setGeneratedQueries(newGeneratedQueries.slice(1));
        const newQueries = [...predefinedQueries];
        newQueries[index] = newGeneratedQueries[0];
        setPredefinedQueries(newQueries);
        if (inputRefs.current[index]) {
          const input = inputRefs.current[index];
          input.focus();
          input.setSelectionRange(
            newGeneratedQueries[0].length,
            newGeneratedQueries[0].length
          );
        }
        toast.success("Queries generated successfully!");
      } else {
        toast.error("Failed to generate queries. Please try again.");
      }
    } else {
      const newQueries = [...predefinedQueries];
      newQueries[index] = generatedQueries[0];
      setPredefinedQueries(newQueries);
      setGeneratedQueries(generatedQueries.slice(1));
      if (inputRefs.current[index]) {
        const input = inputRefs.current[index];
        input.focus();
        input.setSelectionRange(
          generatedQueries[0].length,
          generatedQueries[0].length
        );
      }
    }
  };

  const handleDeleteProject = async () => {
    try {
      setIsDeleting(true);
      await ProjectsAPI.trashProject(projectId);
      refreshAppData();
      setActiveProjectId(null);
      setTimeout(() => {
        setIsDeleting(false);
        navigate("/projects");
      }, 2000);
    } catch (error) {
      console.error("Failed to delete project:", error);
    }
  };

  const canDelete =
    project && project.users && project.users[user.id] === "admin";

  return (
    <div className="_project-explorer-general">
      <h2>Project Settings</h2>
      <div className="form-group">
        <label htmlFor="project-name">Project Name</label>
        <input
          id="project-name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="project-description">Description</label>
        <textarea
          id="project-description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Pre-defined Queries</label>
        {predefinedQueries.length === 0 ? (
          <p>No queries defined yet. Type a query below to get started.</p>
        ) : null}
        {[...predefinedQueries, ""].map((query, index) => (
          <div key={index} className="query-item">
            <input
              type="text"
              value={query}
              placeholder="Type a query and press Enter to add another"
              onChange={(e) => handleQueryChange(index, e.target.value)}
              onKeyPress={handleKeyPress}
              ref={(el) => (inputRefs.current[index] = el)}
            />
            {query === "" && (
              <button
                type="button"
                onClick={() => handleGenerateGuidedQueries(index)}
                className="generate-query-btn"
              >
                <HiSparkles />
              </button>
            )}
            {index < predefinedQueries.length && (
              <button
                type="button"
                onClick={() => handleRemoveQuery(index)}
                className="remove-query-btn"
              >
                Remove
              </button>
            )}
          </div>
        ))}
      </div>
      
      <div className="form-group">
        <label htmlFor="custom-instructions">Custom Instructions (upto 10,000 words)</label>
        <textarea
          id="custom-instructions"
          value={customInstructions}
          onChange={(e) => setCustomInstructions(e.target.value)}
          placeholder="Enter custom instructions for this project. You may also specify the the desired output format and style guide for Ephor to follow."
          rows={10}
          maxLength="50000"
        />
      </div>

      <div className="_projects-button-row">
        <button type="submit" onClick={handleSave} disabled={isSaving}>
          {isSaving ? (
            <AiOutlineLoading3Quarters className="loading-spinner" />
          ) : (
            "Save"
          )}
        </button>
        {canDelete && (
          <button onClick={() => setShowDeleteModal(true)}>
            Move To Trash
          </button>
        )}
      </div>

      <SecondBrain />

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="_channel-modal">
          <div className="_channel-modal-content">
            <h2>Confirm Moving To Trash</h2>
            <p>Are you sure you want to move this project to trash?</p>
            <p className="text-sm text-gray-600">
              Once moved to trash, all members of the project will permanently lose
              access to all chats, including private ones.
            </p>
            <p className="text-sm text-gray-600">
              Members can still download a braindump of the project until it is permanently deleted.
            </p>
            <p className="text-sm text-gray-600">
              The project can be restored by the owner.
            </p>
            <div className="_channel-modal-buttons">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-200 hover:bg-gray-300 text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteProject}
                className="bg-red-500 hover:bg-red-600 text-white"
              >
                {isDeleting ? <Loader size="16px" /> : "Move to Trash"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectConfigGeneral;
