import { Menu } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaRegEdit, FaRegSave } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import {
  LuChevronDown,
  LuFiles,
  LuPlus,
  LuSettings2,
  LuTrash2,
  LuUser,
  LuBarChart
} from "react-icons/lu";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useData } from "../contexts/DataContext";
import { Loader } from "./Loader";
import ProjectChatInput from "./ProjectChatInput";
import EphorLogo from "./custom-icons/EphorLogo";
import { DEFAULT_CHANNEL_NAME } from "../lib/constants";

const ProjectChat = () => {
  const {
    ProjectsAPI,
    ChannelsAPI,
    LibraryAPI,
    projects,
    activeProjectId,
    activeChannelId,
    setActiveProjectId,
    setActiveChannelId,
    channels,
    editingChannelId,
    setEditingChannelId,
    editedChannelName,
    setEditedChannelName,
    setChatSidebarOpen,
    autoGeneratedChannelName,
    setAutoGeneratedChannelName,
  } = useData();
  const { projectId, channelId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [channelState, setChannelState] = useState("private");
  const [projectName, setProjectName] = useState("");
  const [libraryId, setLibraryId] = useState("");
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    channelId: null,
    channelName: "",
  });

  const fetchChannels = async () => {
    const result = await ChannelsAPI.getChannels(activeProjectId);
    if (result && result.error === "ACCESS_DENIED") {
      navigate(`/projects`);
      toast.error("You no longer have access to this project or channel.");
    }
    // Push event to Google Tag Manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'project_viewed',
      'chat_number': channels.length
    });
  };

  const fetchProjectDetails = async () => {
    if (!activeProjectId) {
      return;
    }

    const projectData = await ProjectsAPI.readProject(activeProjectId);
    setProjectName(projectData.name);
    setLibraryId(projectData.library.library_id);
  };

  const handleAddChannel = async () => {
    await handleCreateChannel();
    setChannelState("private");
  };

  const handleCreateChannel = async () => {
    const channelName = DEFAULT_CHANNEL_NAME;
    const newChannel = await ChannelsAPI.createChannel(projectId, {
      name: channelName,
      state: channelState,
    });
    setAutoGeneratedChannelName(channelName);
    navigate("/projects/" + projectId + "/chat/" + newChannel.id);
    toast.success("Chat created successfully");

    // Push event to Google Tag Manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'new_chat'
    });
  };

  const handleDeleteChannel = (channelId) => {
    const channel = channels.find((item) => item.id === channelId);
    if (!channel) return;
    setConfirmModal({ isOpen: true, channelId, channelName: channel.name });
  };


  const handleAutoGeneratedChannelNameUpdate = async (channelId) => {
    if (autoGeneratedChannelName.trim() === "" || autoGeneratedChannelName === DEFAULT_CHANNEL_NAME) {
      return;
    }
  
    const resp = await ChannelsAPI.updateChannel(projectId, channelId, {
      name: autoGeneratedChannelName,
    });

    if (!resp || resp.error) return;
  };

  useEffect(() => {
    if (autoGeneratedChannelName) {
      handleAutoGeneratedChannelNameUpdate(activeChannelId);
    }
  }, [autoGeneratedChannelName]);


  const confirmDeleteChannel = async () => {
    const { channelId } = confirmModal;
    await ChannelsAPI.deleteChannel(projectId, channelId);
    setConfirmModal({ isOpen: false, channelId: null, channelName: "" });
    toast.success("Chat deleted successfully");
    await LibraryAPI.ingestSharedChats(projectId, libraryId)
  };

  const handleChannelNameUpdate = async (channelId) => {
    if (editedChannelName.trim() === "") {
      toast.error("Channel name cannot be empty");
      return;
    }

    const resp = await ChannelsAPI.updateChannel(projectId, channelId, {
      name: editedChannelName,
    });

    if (!resp || resp.error) return;

    toast.success("Channel name updated successfully");
  };

  // Function to render project links at the bottom
  const renderProjectLinks = () => (
    <div className="_project-links">
      <Link to={`/projects/${projectId}/settings`} className="_workspace-link">
        <LuSettings2 />
        Project Settings
      </Link>
      <Link to={`/projects/${projectId}/metrics`} className="_workspace-link">
        <LuBarChart />
        Project Metrics
      </Link>
      <Link to={`/projects/${projectId}/participants`} className="_workspace-link">
        <LuUser />
        Participants
      </Link>
      <Link to={`/projects/${projectId}/library`} className="_workspace-link">
        <LuFiles />
        Library
      </Link>
    </div>
  );

  // Menu to list all project names and update the active project
  const renderProjectMenu = () => (
    <Menu as="div" className="_workspace-menu">
      <Menu.Button className="_workspace-menu-button">
        <span>{projectName || "Loading..."}</span>
        <LuChevronDown />
      </Menu.Button>
      <Menu.Items className="_workspace-menu-items">
        {projects.map((project, i) => (
          <Menu.Item key={`${i}-${project.id}`}>
            {({ active }) => (
              <div
                className={`_workspace-menu-item ${active ? "active" : ""}`}
                onClick={() => {
                  if (project.project_id !== projectId) {
                    navigate(`/projects/${project.project_id}`)
                  }
                }}
              >
                {project.name}
              </div>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );

  useEffect(() => {
    if (projectId !== activeProjectId) {
      setActiveProjectId(projectId);
    }

    if (channelId && channelId !== activeChannelId) {
      setActiveChannelId(channelId);
    }
  }, [projectId, channelId]);

  useEffect(() => {
    (async () => {
      if (activeProjectId) {
        setIsLoading(true);

        try {
          await fetchChannels();
          await fetchProjectDetails();
          setActiveChannelId(channelId || null);
        } catch (error) {
          console.error("Error loading project chat:", error);
          toast.error("An error occurred while loading the project chat.");
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [activeProjectId, channelId]);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className="_project-chat">
          <div className="_chat-sidebar">
            <div className="_chat-sidebar-toggle">
              <button onClick={() => setChatSidebarOpen(false)}>
                <IoMdCloseCircle />
              </button>
            </div>
            <div className="_chat-sidebar-header">
              {renderProjectMenu()}
              <div className="_add-channel" onClick={handleAddChannel}>
                <LuPlus />
                <span>New Chat</span>
              </div>
            </div>
            <div className="_channels-list flex-grow">
              <div className="_channels-list-section">
                <div className="_chats-container">
                  <h3>Private Chats</h3>
                  {(channels || [])
                    .filter((channel) => channel.state === "private")
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    .map((channel) => (
                      <div
                        key={channel.id}
                        className={`_channel-item ${activeChannelId === channel.id ? "active" : ""}`}
                        onClick={() => navigate("/projects/" + projectId + "/chat/" + channel.id)}
                      >
                        {editingChannelId === channel.id ? (
                          <input
                            type="text"
                            className="_edit-channel-input"
                            value={editedChannelName}
                            onChange={(e) => setEditedChannelName(e.target.value)}
                            onBlur={() => handleChannelNameUpdate(channel.id)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleChannelNameUpdate(channel.id);
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          <span>{channel.name}</span>
                        )}
                        {activeChannelId === channel.id && (
                          <div className="_active-channel-buttons">
                            {editingChannelId === channel.id ? (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleChannelNameUpdate(channel.id);
                                }}
                                className="_save-channel-name-btn"
                              >
                                <FaRegSave />
                              </button>
                            ) : (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditingChannelId(channel.id);
                                  setEditedChannelName(channel.name);
                                }}
                                className="_edit-channel-name-btn"
                              >
                                <FaRegEdit />
                              </button>
                            )}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteChannel(channel.id);
                              }}
                              className="_delete-channel-btn"
                            >
                              <LuTrash2 />
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>

              <div className="_channels-list-section">
                <div className="_chats-container">
                  <h3>Shared Chats</h3>
                  {(channels || [])
                    .filter((channel) => channel.state === "shared")
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    .map((channel) => (
                      <div
                        key={channel.id}
                        className={`_channel-item ${activeChannelId === channel.id ? "active" : ""}`}
                        onClick={() => navigate("/projects/" + projectId + "/chat/" + channel.id)}
                      >
                        <span>{channel.name}</span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="_channels-list-section last-section">
                <div className="_chats-container">
                  <h3>Multi-User Chats</h3>
                  {(channels || [])
                    .filter((channel) => channel.state === "multi_user")
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    .map((channel) => (
                      <div
                        key={channel.id}
                        className={`_channel-item ${activeChannelId === channel.id ? "active" : ""}`}
                        onClick={() => navigate("/projects/" + projectId + "/chat/" + channel.id)}
                      >
                        <span>{channel.name}</span>
                      </div>
                    ))}
                </div>
              </div>

            </div>

            {/* Render the project links at the bottom with padding and border */}
            <div className="_project-links-container border-t">
              {renderProjectLinks()}
            </div>

          </div>

          <div className="_chat-container flex-grow">
            {activeChannelId ? (
              <ProjectChatInput />
            ) : (
              <button className="_no-channel-selected" onClick={() => setChatSidebarOpen(true)}>
                <EphorLogo />
                <p>Select a chat to start collaborating</p>
              </button>
            )}
          </div>

          {confirmModal.isOpen && (
            <div className="_channel-modal">
              <div className="_channel-modal-content">
                <h2>Confirm Deletion</h2>
                <p>Are you sure you want to delete the chat "{confirmModal.channelName}"?</p>
                <div className="_channel-modal-buttons">
                  <button
                    onClick={() =>
                      setConfirmModal({
                        isOpen: false,
                        channelId: null,
                        channelName: "",
                      })
                    }
                  >
                    Cancel
                  </button>
                  <button onClick={confirmDeleteChannel}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProjectChat;
