export const extractFilesFromArtifact = (artifactCode) => {
  const fileRegex = /\/\/ File: (.+)\n\n([\s\S]+?)(?=\n\/\/ File:|$)/g;
  const files = {};
  let match;

  while ((match = fileRegex.exec(artifactCode)) !== null) {
    files[match[1]] = match[2].trim();
  }

  if(!Object.keys(files).length && artifactCode) {
    files["pages/index.jsx"] = artifactCode;
  }

  return files;
};
