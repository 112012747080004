import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable"

export function ArtifactBoard() {
  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel>One</ResizablePanel>
      <ResizableHandle className="_artifact-board-handle" />
      <ResizablePanel>Two</ResizablePanel>
    </ResizablePanelGroup>
  );
}