import { useNavigate } from "react-router-dom";
import { convertMimeType } from "@/lib/actions";
import { useAuth, useUser } from "@clerk/clerk-react";
import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import toast from "react-hot-toast";

const API_URL = `${import.meta.env.VITE_APP_API_URL}/api/v1`;

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { getToken } = useAuth();
  const { user } = useUser();
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeProjectId, setActiveProjectId] = useState("");
  const [channels, setChannels] = useState([]);
  const [activeChannelId, setActiveChannelId] = useState("");
  const [currentCode, setCurrentCode] = useState("");
  const [selectedPanel, setSelectedPanel] = useState(null);
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(false);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [chatSidebarOpen, setChatSidebarOpen] = useState(false);
  const [triggerPreview, setTriggerPreview] = useState(false);
  const [slmLeaderboardData, setSlmLeaderboardData] = useState({});
  const [contextAnalysisData, setContextAnalysisData] = useState({});
  const [sources, setSources] = useState([]);

  const [libraryId, setLibraryId] = useState(null);
  const [firstFolderData, setFirstFolderData] = useState({});
  const [libraryData, setLibraryData] = useState([]);
  const [blockPriorityUpdates, setBlockPriorityUpdates] = useState(false);
  const [streamLock, setStreamLock] = useState(false);
  const [googleStatus, setGoogleStatus] = useState(null);

  const [editingChannelId, setEditingChannelId] = useState(null);
  const [editedChannelName, setEditedChannelName] = useState("");

  const [isConnected, setIsConnected] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const socketRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const reconnectAttemptsRef = useRef(0);

  const [artifactError, setArtifactError] = useState(null);
  const [selectedArtifact, setSelectedArtifact] = useState(null);
  const [autoGeneratedChannelName, setAutoGeneratedChannelName] = useState("");

  const navigate = useNavigate();

  const toggleLeftSidebar = () => {
    setIsLeftSidebarOpen((prevState) => !prevState);
    document.body.classList.toggle("_left-sidebar-open");
  };

  const toggleRightSidebar = (force = false) => {
    if (!force) {
      setIsRightSidebarOpen((prevState) => !prevState);
      document.body.classList.toggle("_right-sidebar-open");
    } else {
      setIsRightSidebarOpen(false);
      document.body.classList.remove("_right-sidebar-open");
    }
  };

  const apiCall = async (
    method,
    urlSegment,
    headers = {},
    data = null,
    responseType = "json"
  ) => {
    try {
      const response = await axios({
        method,
        url: `${API_URL}/${urlSegment}`,
        headers: {
          ...headers,
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await getToken()}`,
        },
        data,
        responseType,
      });
      return response.data;
    } catch (error) {
      // console.error("API call error:", error);
      if (error.response) {
        switch (error.response.status) {
          case 404:
            navigate("/projects");  
          toast.error("Resource not found. You may not have access to this content.");
            break;
          case 403:
            navigate("/projects");
            toast.error("Access denied. You don't have permission to view this content.");
            break;
          case 401:
            toast.error("Authentication required. Please log in again.");
            break;
          case 500:
            toast.error("An internal server error occurred. Please try again later.");
            break;
          default:
            toast.error(`An error occurred: ${error.response.statusText}`);
        }
      } else if (error.request) {
        toast.error("No response received from the server. Please check your network connection.");
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }

      throw error;
    }
  };

  const ProjectsAPI = {
    browseProjects: async () => {
      return await apiCall("GET", "projects/");
    },

    readProject: async (projectId) => {
      return await apiCall("GET", `projects/${projectId}`);
    },

    editProject: async (payload) => {
      const response = await apiCall(
        "PUT",
        `projects/${payload.project_id}`,
        {},
        payload
      );

      if (!response) {
        return null;
      }

      const rsp = await apiCall("GET", "projects/");
      setProjects(rsp);

      return response;
    },

    addProject: async (payload) => {
      const newProject = {
        name: payload.name,
        description: payload.description || null,
        collection: payload.collection || [],
        users: payload.users || {},
        predefined_queries: payload.predefined_queries || [],
        custom_instructions: payload.custom_instructions || "",
        messages: payload.messages || [],
        model: payload.model || null,
        comments: payload.comments || [],
      };

      const response = await apiCall("POST", "projects/", {}, newProject);

      if (!response.project_id) {
        return null;
      }

      setActiveProjectId(response.project_id);
      setActiveChannelId(null);

      const rsp = await apiCall("GET", "projects/");
      setProjects(rsp);

      return response;
    },

    trashProject: async (projectId) => {
        await apiCall("POST", `projects/${projectId}/trash`);
    },

    restoreProject: async (projectId) => {
      await apiCall("POST", `projects/${projectId}/restore`);
    },

    deleteProject: async (projectId) => {
      return await apiCall("DELETE", `projects/${projectId}`);
    },

    addUserToProject: async (projectId, email) => {
      return await apiCall("GET", `projects/${projectId}/add-user/${email}`);
    },

    removeUserFromProject: async (projectId, userId) => {
      return await apiCall(
        "DELETE",
        `projects/${projectId}/remove-user/${userId}`
      );
    },

    completeInvitation: async (inviteId) => {
      return await apiCall("GET", `projects/invite-completed/${inviteId}`);
    },

    onboard: async () => {
      return await apiCall("POST", "projects/onboard-user");
    },

    acceptInvite: async (inviteId) => {
      return await apiCall("POST", `projects/invite/email/${inviteId}`);
    },

    joinViaLink: async (invite_link_id) => {
      return await apiCall("POST", `projects/invite/join/${invite_link_id}`);
    },

    generateGuidedQueries: async (projectId, messages) => {
      return await apiCall(
        "POST",
        `projects/${projectId}/generate_queries`,
        {},
        messages
      );
    },

    generateSpoV: async (projectId, spov) => {
      return await apiCall("POST", `projects/${projectId}/spovs/generate`, {}, spov);
    },

    getSpoVs: async (projectId) => {
      return await apiCall("GET", `projects/${projectId}/spovs`);
    },

    saveSpoVs: async (projectId, spovs) => {
      return await apiCall("POST", `projects/${projectId}/spovs`, {}, spovs);
    },

    deleteSpoV: async (projectId) => {
      return await apiCall("DELETE", `projects/${projectId}/spovs`);
    }
  };

  const UsersAPI = {
    getUserProfile: async (userId) => {
      return await apiCall("GET", `account/public-profile/${userId}`);
    },

    searchUserProfiles: async (term) => {
      return await apiCall("GET", `account/search?search_term=${term}&limit=5`);
    },

    getActiveApiKeys: async () => {
      return await apiCall("GET", "account/api-key");
    },

    getGoogleDriveToken: async () => {
      const rsp = await apiCall("GET", "account/clerk/google");
      if (rsp && rsp.status === "success") {
        return rsp.oauth_token;
      } else {
        throw new Error(rsp.message);
      }
    },

    authorizeGoogle: async (from) => {
      const rsp = await apiCall(
        "GET",
        `account/oauth/google/authorize?from=${from}`
      );
      if (rsp && rsp.status === "success") {
        return rsp.url;
      } else {
        throw new Error(rsp.message);
      }
    },

    getGoogleOAuthToken: async () => {
      const rsp = await apiCall("GET", "account/oauth/google");
      if (rsp && rsp.status === "success") {
        return rsp.oauth_token;
      } else {
        throw new Error(rsp.message);
      }
    },
    verifyGoogleIntegrationStatus: async () => {
      const rsp = await apiCall("GET", "account/oauth/google/status");
      if (rsp && rsp.status === "success") {
        return rsp.valid;
      } else {
        throw new Error(rsp.message);
      }
    },

    updateAccountIntegrations: async (account) => {
      return await apiCall("PUT", "account/integrations", {}, account)
      
    },

    getUserAccount: async (accountId) => {
      return await apiCall("GET", `account/profile/${accountId}`);
    },

    generateApiKey: async () => {
        return await apiCall("POST", "account/api-key");
    },

    revokeApiKey: async () => {
        return await apiCall("DELETE", "account/api-key");
    },

    regenerateApiKey: async () => {
        return await apiCall("POST", "account/api-key");
    },

    getProfileSummary: async () => {
      return await apiCall("GET", "account/profile/summary");
    },
  };

  const InteractAPI = {
    spawnSandbox: async () => {
      console.log("spawning sandbox");
      return await apiCall(
        "POST",
        "interact/tasks/artifacts/sandbox",
        {},
        {
          user_id: user.id,
          template: "mas-nextjs-authjs-prisma",
        }
      );
    },


    generateChannelName: async (query) => {
      return await apiCall("POST", "interact/query",{}, {
        "query": query,
        "top_k": 1,
        "lm_type": "openai-4om",
        "custom_instructions": "Generate a unique chat name with a maximum of 5 words, completely based on user query. The name should reflect the essence of the user's prompt"
      });
    },

    enhanceQuery: async (project_name, previous_messages, query) => {
      const lastFourMessages = previous_messages.slice(-4).map(msg => `${msg.user_name === "Ephor AI" ? "assistant" : "user"}: ${msg.content}`).join("\n");
      
      let custom_instructions = `
        Analyze the user's query to fully grasp its intent and context using best LLM prompting practices.
        Optimize the query to formulate a precise and effective prompt that leverages the full potential of the LLM.
        Focus on depth of the query rather than widening the breadth.
        Tailor the output to align perfectly with the user's objectives.
        Modify the query to ensure get most detailed answer covering all information related to the subject.
        Deliver only the refined prompt.
        Consider the project name: ${project_name} and the context from previous messages: ${lastFourMessages}
      `;

      let user_query = `Here is the original query user provided: ${query}`;

      return await apiCall("POST", "interact/query", {}, {
        "query": user_query,
        "top_k": 1,
        "lm_type": "openai-4om",
        "custom_instructions": custom_instructions
      });
    }
  };

  const ChannelsAPI = {
    getChannels: async (projectId) => {
      try {
        const rsp = await apiCall("GET", `projects/${projectId}/channels`);

        if (!rsp) return;

        setChannels(rsp);

        return rsp;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          return { error: "ACCESS_DENIED", status: 404 };
        }
        throw error;
      }
    },

    getChannel: async (projectId, channelId) => {
      try {
        return await apiCall(
          "GET",
          `projects/${projectId}/channels/${channelId}`
        );
      } catch (error) {
        if (error.response && error.response.status === 404) {
          return { error: "ACCESS_DENIED", status: 404 };
        }
        throw error;
      }
    },

    createChannel: async (projectId, channelCreate) => {
      const rsp = await apiCall(
        "POST",
        `projects/${projectId}/channels`,
        {},
        channelCreate
      );

      if (!rsp) return;

      const channel = {
        id: rsp.channel_id,
        name: rsp.channel_name,
        state: "private",
      };

      setChannels((prev) => [...prev, channel]);

      return channel;
    },

    getChannelMessages: async (projectId, channelId) => {
      return await apiCall(
        "GET",
        `projects/${projectId}/channels/${channelId}/messages`
      );
    },

    updateChannel: async (projectId, channelId, updates) => {
      const current = await ChannelsAPI.getChannel(projectId, channelId);

      const rsp = await apiCall(
        "PUT",
        `projects/${projectId}/channels/${channelId}`,
        {},
        { ...current, ...updates }
      );

      if (!rsp) return;

      setChannels((prevChannels) =>
        prevChannels.map((i) => (i.id === channelId ? rsp : i))
      );
      setEditingChannelId(null);
      setActiveChannelId(rsp.id);
      return rsp;
    },

    deleteChannel: async (projectId, channelId) => {
      const rsp = await apiCall(
        "DELETE",
        `projects/${projectId}/channels/${channelId}`
      );

      if (!rsp) return;

      setChannels((prev) => prev.filter((channel) => channel.id !== channelId));
      if (activeChannelId === channelId) {
        setActiveChannelId(null);
      }
    },

    forkChannel: async (projectId, channelId) => {
      return await apiCall(
        "POST",
        `projects/${projectId}/channels/${channelId}/fork`
      );
    },

    exportData: async (
      projectId,
      exportOption,
      exportType,
      channelId = null,
      fileName = "dump",
      fileExtension = "txt",
      includeArtifacts = false,
      includeSLMData = false
    ) => {
      const body = {
        export_option: exportOption,
        export_type: exportType,
        channel_id: channelId,
        include_artifacts: includeArtifacts,
        include_slm_data: includeSLMData,
      };

      const response = await apiCall(
        "POST",
        `projects/${projectId}/export`,
        {},
        body,
        "blob"
      );

      if (response) {
        downloadBlob(response, `${fileName}.${fileExtension}`);
      }

      return response;
    },
  };

  const downloadBlob = (blobData, fileName) => {
    const url = window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const AIAPI = {
    streamResponse: async (
      messageId,
      query,
      pastMessages,
      lmType = "openai-4o",
      libraryId = "dfd08b55-fbd2-4013-a3f2-974b94927046",
      topK = 3,
      metadata = null,
      projectId = undefined,
      sharedContext = True,
      customInstructions = null,
      attachments = [] 
    ) => {
      const token = await getToken();

      const payload = {
        message_id: messageId,
        query: query,
        library_id: libraryId,
        top_k: topK,
        past_messages: pastMessages,
        attachments: attachments,
        lm_type: lmType,
        metadata: metadata,
        project_id: projectId,
        shared_context: sharedContext,
        custom_instructions: customInstructions,
      };
      const response = await fetch(`${API_URL}/interact/stream`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      return response.body.getReader();
    },
  };

  const LibraryAPI = {
    getLibrary: async (libraryId) => {
      return await apiCall("GET", `library/?library_id=${libraryId}`);
    },

    addSource: async (url, library_id) => {
      return await apiCall(
        "POST",
        `library/ingest/`,
        {},
        { drive_url: url, library_id: library_id }
      );
    },

    updateItem: async (indexReference, itemId, updates) => {
      return await apiCall(
        "PATCH",
        `library/${indexReference}/${itemId}`,
        {},
        updates
      );
    },

    deleteDriveFolder: async (library_id, index_reference) => {
      return await apiCall(
        "DELETE",
        `library/${library_id}/${index_reference}`
      );
    },

    ingestWorkflowy: async (link, library_id) => {
      return await apiCall(
        "POST",
        `library/ingest/workflowy`,
        {},
        {
          url: link,
          library_id: library_id,
        }
      );
    },

    refreshWorkflowy: async (namespace) => {
      return await apiCall(
        "POST",
        `library/ingest/workflowy/refresh`,
        {},
        {
          namespace: namespace,
        }
      );
    },

    getStatistics: async (namespace) => {
      return await apiCall("GET", `library/stats/${namespace}`);
    },

    refreshLibraryItem: async (folderId) => {
      return await apiCall("POST", `library/${folderId}/refresh`);
    },
    ingestSharedChats: async (projectId, libraryId) => {
      return await apiCall(
        "POST",
        `library/sharedchats/${projectId}/${libraryId}`
      );
    },
    ingestVideo: async (videoUrl, libraryId) => {
      const token = await getToken();
      return await apiCall(
        "POST",
        "library/ingest/video",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        { video_url: videoUrl, library_id: libraryId }
      );
    },

    deleteSingleItem: async (indexReference, itemId) => {
      return await apiCall("DELETE", `library/${indexReference}/item/${itemId}`);
    },
    crawlWebsite: async (websiteUrl, libraryId, maxDepth) => {
      const token = await getToken();
      return await apiCall(
        "POST",
        "crawl/new",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          url: websiteUrl,
          library_id: libraryId,
          max_depth: maxDepth,
        }
      );
    },
  };

  const WebsocketAPI = {
    currentChannelId: null,

    connect: async () => {
      if (
        activeChannelId === WebsocketAPI.currentChannelId ||
        !activeChannelId
      ) {
        return;
      }

      if (socketRef.current?.readyState === WebSocket.OPEN) {
        socketRef.current.close();
      }

      // Update the current channel ID
      WebsocketAPI.currentChannelId = activeChannelId;

      try {
        const token = await getToken();
        if (!token) {
          throw new Error("Failed to get authentication token");
        }

        const wsUrl = `${import.meta.env.VITE_WS_URL}/ws/${activeChannelId}?token=${token}`;
        socketRef.current = new WebSocket(wsUrl);

        socketRef.current.onopen = () => {
          console.log("WebSocket Connected");
          setIsConnected(true);
          reconnectAttemptsRef.current = 0;
        };

        socketRef.current.onclose = (event) => {
          console.log("WebSocket Disconnected", event);
          setIsConnected(false);
          WebsocketAPI.reconnect();
        };

        socketRef.current.onerror = (error) => {
          console.error("WebSocket Error:", error);
        };

        socketRef.current.onmessage = (event) => {
          try {
            const message = JSON.parse(event.data);
            setLastMessage(message);
            window.dispatchEvent(
              new CustomEvent("newWebSocketMessage", { detail: message })
            );
          } catch (error) {
            console.error("Error parsing WebSocket message:", error);
          }
        };
      } catch (error) {
        console.error("Error setting up WebSocket:", error);
        WebsocketAPI.reconnect();
      }
    },

    reconnect: () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      const delay = Math.min(
        30000,
        Math.pow(2, reconnectAttemptsRef.current) * 1000
      );
      reconnectTimeoutRef.current = setTimeout(() => {
        console.log(
          `Attempting to reconnect... (Attempt ${reconnectAttemptsRef.current + 1})`
        );
        reconnectAttemptsRef.current++;
        WebsocketAPI.connect();
      }, delay);
    },

    sendMessage: (message) => {
      if (socketRef.current?.readyState === WebSocket.OPEN) {
        console.log("Sending WebSocket message:", message);
        socketRef.current.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected. Message not sent:", message);
      }
    },
  };

  const AttachmentAPI = {
    uploadAttachment: async (attachmentId, fileContent, usedTokens) => {
      console.log('uploading attachment', attachmentId);
      return await apiCall("POST", `conversations/attachments`, {}, {attachment_id: attachmentId, file_content: fileContent, used_tokens: usedTokens});
    },
    deleteAttachment: async (attachmentId) => {
      return await apiCall("DELETE", `conversations/attachments/${attachmentId}`);
    },
    getAttachment: async (attachmentId) => {
      return await apiCall("GET", `conversations/attachments/${attachmentId}`);
    },
  }

  const UserProfileOutlineAPI = {
    getProfileSummary: async () => {
      return await apiCall("GET", "profile/summary");
    },
  };


  const refreshAppData = async () => {
    const rsp = await apiCall("GET", "projects/");
    setProjects(rsp);
  };

  const handleAuthorizeGoogle = async () => {
    const from = new URL(window.location.href).pathname;
    const rsp = await UsersAPI.authorizeGoogle(from);
    console.log(rsp);
    window.location.href = rsp;
  };

  const folderStatusFinder = (folder) => {
    const oneHourAgo = new Date(Date.now() - 3600000);

    const hasFailed = folder.manifest.some((item) => item.ingestion_status === "Failed");
    if (hasFailed) return "Failed";

    const hasStuckProcessing = folder.manifest.some(
      (item) =>
        (item.ingestion_status === "Processing" || item.ingestion_status === "ProcessingInitiated") &&
        new Date(item.updated_at) < oneHourAgo
    );

    const hasIngested = folder.manifest.some((item) => item.ingestion_status === "Ingested");

    if (hasStuckProcessing && hasIngested) return "Ingested";

    const hasProcessing = folder.manifest.some(
      (item) =>
        item.ingestion_status === "Processing" || item.ingestion_status === "ProcessingInitiated"
    );
    if (hasProcessing) return "Processing";

    const hasUnknown = folder.manifest.some(
      (item) => item.ingestion_status === "Unknown" || !item.ingestion_status
    );
    if (hasUnknown) return "Unknown";

    return "Ingested";
  };

  const dateFinder = (folder) => {
    const latestItem = folder.manifest.reduce((latest, item) => {
      return !latest || new Date(item.updated_at) > new Date(latest.updated_at)
        ? item
        : latest;
    }, null);
    return latestItem ? latestItem.updated_at : null;
  };

  const formatDate = (date) => {
    const MM = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const hh = String(date.getHours()).padStart(2, "0");
    const mm = String(date.getMinutes()).padStart(2, "0");

    return `${MM}/${dd} ${hh}:${mm}`;
  };

  const urlMaker = (index_reference, name) => {
    if (index_reference.startsWith('drive')){
      return `https://drive.google.com/drive/folders/${index_reference.replace("drive__", "")}` 
    } else if (name.startsWith('http')){
      return `${name}`;
    } else {
      return `https://${name}`; 
    }
  }

  const updateLibraryData = useCallback(
    (newLibraryItems, force = false) => {
      const transformedData = newLibraryItems.reduce(
        (accumulator, newLibraryItem) => {
          if (!newLibraryItem.index_reference.startsWith("sharedchats__")) {
            accumulator.push({
              id: newLibraryItem.index_reference,
              file_name: newLibraryItem.name,
              file_type:  newLibraryItem.index_reference.startsWith('site') ? "WEBSITE" : "FOLDER",
              url: urlMaker(newLibraryItem.index_reference, newLibraryItem.name),
              updated_at: formatDate(new Date(dateFinder(newLibraryItem))),
              ingestion_status: folderStatusFinder(newLibraryItem),
              children: newLibraryItem.manifest.map((item) => ({
                id: item.id,
                file_name: item.name,
                file_type: convertMimeType(item.mimeType),
                url: item.webViewLink,
                starred: item.starred,
                priority: item.priority,
                updated_at: formatDate(new Date(item.updated_at)),
                ingested: item.ingestion_status,
                ingestion_status:
                  item.ingestion_status ||
                  (item.ingested ? "Ingested" : "Unknown"),
              })),
            });
          }
          return accumulator;
        },
        []
      );

      setLibraryData((prevData) => {
        if (force) {
          return preserveErrorStatus(transformedData, prevData);
        } else {
          return [
            ...prevData,
            ...preserveErrorStatus(transformedData, prevData),
          ];
        }
      });

      if (transformedData.length > 0 && force) {
        setFirstFolderData(transformedData[0]);
      }
    },
    [activeProjectId, libraryId]
  );

  const preserveErrorStatus = (transformedData, prevData) => {
    const preservedData = transformedData.map((newItem) => {
      const prevItem = prevData.find((prevItem) => prevItem.id === newItem.id);
      if (
        prevItem &&
        prevItem.ingestion_status === "Status Sync timeout, check back later"
      ) {
        return {
          ...newItem,
          ingestion_status: prevItem.ingestion_status,
        };
      } else {
        return newItem;
      }
    });
    return preservedData;
  };

  const fetchLibraryData = useCallback(
    async (libraryId) => {
      const response = await LibraryAPI.getLibrary(libraryId);
      console.log("libraryId: ", libraryId);
      if (response?.items?.length > 0) {
        updateLibraryData(response.items, true);
      } else {
        updateLibraryData([], true);
      }
    },
    [activeProjectId, libraryId, updateLibraryData]
  );

  const handleAddSource = async (url, library_id = libraryId) => {
    const rsp = await LibraryAPI.addSource(url, library_id);

    if (rsp.status !== "success") {
      throw new Error(rsp.message);
    }

    const newLibraryItem = {
      index_reference: rsp.index_reference,
      name: rsp.name,
      manifest: rsp.manifest.map((item) => ({
        id: item.id,
        mimeType: item.mimeType,
        name: item.name,
        webViewLink: item.url,
        priority: item.priority,
        updated_at: new Date(),
      })),
    };

    handleBlockPriorityUpdates();
    updateLibraryData([newLibraryItem]);
    return newLibraryItem;
  };

  const handleIngestWorkflowy = async (link, library_id) => {
    const rsp = await LibraryAPI.ingestWorkflowy(link, library_id);

    if (rsp.status !== "success") {
      const toastExec = rsp.message.includes(
        "Failed to ingest Workflowy content."
      )
        ? toast.success
        : toast.error;
      toastExec(rsp.message);
      return;
    }
    if (libraryData.find((item) => item.id === rsp.index_reference)) {
      updateChildIngestionStatus(
        rsp.index_reference,
        rsp.name,
        "Added",
        new Date(),
        rsp.name,
        "text/markdown"
      );
    } else {
      updateLibraryData([
        {
          index_reference: rsp.index_reference,
          name: "Project Files",
          manifest: rsp.manifest.map((item) => ({
            id: item.id,
            mimeType: item.mimeType,
            name: item.name,
            webViewLink: "",
            priority: item.priority,
            starred: item.starred,
            updated_at: new Date(),
            ingestion_status: "Added",
          })),
        },
      ]);
    }
    handleBlockPriorityUpdates();
    return rsp;
  };

  const handleCrawlWebsite = async (websiteUrl, libraryId, maxDepth = 30) => {
    const response = await LibraryAPI.crawlWebsite(websiteUrl, libraryId, maxDepth);

    if (response.status === "success") {
      toast.success("Website crawling started");
    } else {
      toast.error(response.message || "Failed to start website crawling");
    }

    if (libraryData.find(item => item.id === response.index_reference)) {   
    } else {
      updateLibraryData([{
        index_reference: response.index_reference,
        name: response.name,
        manifest: response.manifest.map((item) => ({
          id: item.id,
          mimeType: item.mimeType,
          name: item.name,
          webViewLink: '',
          priority: item.priority,
          updated_at: new Date(),
          ingested: "Processing",
          ingestion_status: "Processing",
        })),
      }]);
    }

    handleBlockPriorityUpdates();
    return response;
  }

  const updateItemOnServer = useCallback(
    async (indexReference, itemId, updates) => {
      const rsp = await LibraryAPI.updateItem(indexReference, itemId, updates);

      if (rsp.error) {
        console.error(
          "Error updating item on server:",
          JSON.stringify(rsp, null, 2)
        );
        return {
          status: rsp.error.response?.status || 500,
          statusText: rsp.error.response?.statusText || "Internal Server Error",
          error: rsp.error.response?.data || "Unknown error",
        };
      }

      return rsp;
    },
    []
  );

  const handleItemUpdate = useCallback((itemId, updates) => {
    setLibraryData((prevLibrary) => {
      let indexReference;

      const newLibrary = prevLibrary.map((item) => {
        if (!item.children) return item;

        const updatedChildren = item.children.map((doc) => {
          if (doc.id !== itemId) return doc;
          indexReference = item.id;
          return { ...doc, ...updates };
        });

        return { ...item, children: updatedChildren };
      });

      if (indexReference) {
        updateItemOnServer(indexReference, itemId, updates);
      } else {
        console.error(`Could not find index_reference for itemId: ${itemId}`);
      }

      return newLibrary;
    });
  }, []);

  const deleteDriveFolder = useCallback(
    async (index_reference) => {
      const rsp = await LibraryAPI.deleteDriveFolder(
        libraryId,
        index_reference
      );
      setLibraryData((prevData) =>
        prevData.filter((item) => item.id !== index_reference)
      );
      return true;
    },
    [libraryId]
  );

  const deleteSingleItem = useCallback(
    async (itemId) => {
      const folderId = libraryData.find((item) => item.children.some((child) => child.id === itemId))?.id;

      const rsp = await LibraryAPI.deleteSingleItem(folderId, itemId);
      setLibraryData((prevData) =>
        prevData.map((item) => {
          if (item.id === folderId) {
            return {
              ...item,
              children: item.children.filter((child) => child.id !== itemId)
            };
          }
          return item;
        })
      );
      return true;
    },
    [libraryData]
  );

  const getStats = useCallback(async (namespace) => {
    const rsp = await LibraryAPI.getStats(namespace);
    return rsp || null;
  }, []);

  const handleBlockPriorityUpdates = useCallback(() => {
    setBlockPriorityUpdates(true);
    setTimeout(() => {
      setBlockPriorityUpdates(false);
    }, 10000);
  }, []);

  const updateChildIngestionStatus = useCallback(
    (folderId, childId, newStatus, date, newName, newMimeType) => {
      setLibraryData((prevData) => {
        const newData = prevData.map((folder) => {
          if (folder.id === folderId) {
            let updatedChildren = folder.children || [];

            if (newStatus === "Deleted") {
              updatedChildren = updatedChildren.filter(
                (child) => child.id !== childId
              );
            } else if (newStatus === "Added") {
              if (!updatedChildren.some(child => child.file_name === newName)) {
              updatedChildren = [
                ...updatedChildren,
                {
                  id: childId,
                  file_name: newName,
                  file_type: convertMimeType(newMimeType),
                  starred: false,
                  ingested: "Added",
                  ingestion_status: "Added",
                  updated_at: formatDate(new Date(date)),
                },
              ];
            }
            } else {
              updatedChildren = updatedChildren.map((child) => {
                if (child.id === childId && newStatus !== "Processing") {
                  return {
                    ...child,
                    ingested: newStatus,
                    ingestion_status: newStatus,
                    updated_at: formatDate(new Date(date)),
                  };
                } else if (child.id === childId && newStatus === "Processing") {
                  return { ...child, ingestion_status: newStatus };
                }
                return child;
              });
            }
            let newFolderStatus = "Ingested";
            if (
              updatedChildren.some(
                (child) =>
                  child.ingestion_status === "Processing" ||
                  child.ingestion_status === "ProcessingInitiated"
              )
            ) {
              newFolderStatus = "Processing";
            } else if (
              updatedChildren.some(
                (child) => child.ingestion_status === "Failed"
              )
            ) {
              newFolderStatus = "Failed";
            }
            else if (updatedChildren.some(child => child.ingestion_status === "Unknown" || !child.ingestion_status)) {
              newFolderStatus = "Unknown";
            }

            if (childId === "Error") {
              console.log(
                "Error in child ingestion, setting folder status to Refresh"
              );
              newFolderStatus = "Status Sync timeout, check back later";
            }

            return {
              ...folder,
              children: updatedChildren,
              ingestion_status: newFolderStatus,
            };
          }
          return folder;
        });

        return newData;
      });
    },
    []
  );

  const refreshLibraryItem = useCallback(async (folderId) => {
    const rsp = await LibraryAPI.refreshLibraryItem(folderId);
    if (rsp.status !== "success") {
      throw new Error(rsp.message);
    }
  }, []);

  const streamFolderSyncStatus = async (folderId, reconnection = false, itemPrefix = null) => {
    let url;
    if (reconnection) {
      url = `${API_URL}/library/itemstatus/${folderId}/reconnection`;
    } else {
      url = `${API_URL}/library/itemstatus/${folderId}`;
    }

    if (itemPrefix) {
      // Add the item prefix to the url to route it to the correct stream
      url = url + `/${itemPrefix}`;
    }
    

    const eventSource = new EventSource(url);

    eventSource.onmessage = (event) => {
      const parts = event.data.split("|");
      const date = parts[1];

      var dataItem = parts[0];

      if (dataItem.includes("http:") || dataItem.includes("https:")) {
        dataItem = dataItem.replace(/^https?:/, '');
      }

      const itemId = dataItem.split(":")[0];
      const status = dataItem.split(":")[1];
      const newName = parts[2] || "New File";
      const newMimeType = parts[3] || "New";

      if (
        [
          "Ingested",
          "Incompatible",
          "Failed",
          "Processing",
          "Unchanged",
          "ProcessingInitiated",
          "Deleted",
          "Added",
          "Unknown",
          "Ridiculous",
        ].includes(status)
      ) {
        updateChildIngestionStatus(
          folderId,
          itemId,
          status,
          date,
          newName,
          newMimeType
        );
      }
      if (itemId === "Error") {
        updateChildIngestionStatus(
          folderId,
          itemId,
          status,
          date,
          newName,
          newMimeType
        );
        fetchLibraryData(libraryId);
        eventSource.close();
        return;
      }

      if (status === "Stop") {
        fetchLibraryData(libraryId);
        eventSource.close();
      }
    };

    eventSource.onerror = (error) => {
      console.error("EventSource failed:", error);
      eventSource.close();
    };

    eventSource.onopen = () => {
      console.log("EventSource connection to server opened.");
    };

    eventSource.onclose = () => {
      console.log("EventSource connection to server closed.");
    };

    return eventSource;
  };

  useEffect(() => {
    const libraryId = projects.find(
      (project) => project.project_id === activeProjectId
    )?.library_id;
    if (libraryId) {
      setLibraryId(libraryId);
      fetchLibraryData(libraryId);
    }
  }, [activeProjectId, projects]);

  useEffect(() => {
    const verifyGoogleIntegrationStatus = async () => {
      const status = await UsersAPI.verifyGoogleIntegrationStatus();
      setGoogleStatus(status);
    };
    verifyGoogleIntegrationStatus();
  }, []);

  useEffect(() => {
    setIsPanelVisible(false);
    setSelectedPanel(null);
    setCurrentCode("");
    setSlmLeaderboardData({});
    setContextAnalysisData({});
    setSources([]);
    setChatSidebarOpen(false);
  }, [activeProjectId, activeChannelId]);

  useEffect(() => {
    console.log("import.meta.env\n", import.meta.env.VITE_WS_URL);
    const loadAppData = async () => {
      const rsp = await apiCall("GET", "projects/");

      const triggerLibraryRefresh = async () => {
        const refreshTimestamp = localStorage.getItem("refresh_timestamp");
        if (
          refreshTimestamp &&
          Date.now() - Number(refreshTimestamp) < 1200000
        ) {
          console.log("Not refreshing library: too soon");
          return;
        }
        localStorage.setItem("refresh_timestamp", Date.now().toString());
        await InteractAPI.spawnSandbox();
        console.log("Triggering refresh on all libraries, >20m");
        for (const project of rsp) {
          const libraryId = project.library_id;
          let library = await LibraryAPI.getLibrary(libraryId);
          library.items = library.items.filter((item) =>
            item.index_reference.startsWith("drive__")
          );
          library.items.forEach((item, index) => {
            setTimeout(async () => {
              console.log(`Refreshing ${item.index_reference}`);
              await LibraryAPI.refreshLibraryItem(item.index_reference);
            }, 4000 * index);
          });
        }
      };
      //await triggerLibraryRefresh();
      //setInterval(triggerLibraryRefresh, 1200000);
      setProjects(rsp);
      setIsLoading(false);
    };

    loadAppData();

    WebsocketAPI.connect();

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, []);


  // Unified ingestVideo function
  const ingestVideo = async (videoUrl, libraryId) => {
    try {
      const response = await LibraryAPI.ingestVideo(videoUrl, libraryId);
      console.log("response: ", response);

      if (response.status === "success") {
        toast.success("Video ingestion started");

        const newItem = {
          index_reference: response.index_reference,
          name: response.name || "Project Files",
          manifest: response.manifest.map((item) => ({
            id: item.id,
            mimeType: item.mimeType,
            name: item.name,
            webViewLink: videoUrl,
            priority: item.priority,
            updated_at: new Date(),
            ingestion_status: "Added",
          })),
        };

        if (libraryData.find((item) => item.id === response.index_reference)) {
          updateChildIngestionStatus(
            response.index_reference,
            response.name,
            "Added",
            new Date(),
            response.name,
            "text/plain"
          );
        } else {
          updateLibraryData([newItem]);
        }

        handleBlockPriorityUpdates();
        return response;
      } else {
        // Throw an error with the response message
        throw new Error(response.message || "Failed to ingest video");
      }
    } catch (error) {
      console.error("Error ingesting video:", error);
      // Do not show toast here; rethrow the error
      throw error;
    }
  };

  const FeedbackAPI = {
    createFeedback: async (feedbackData) => {
      return await apiCall(
        "POST",
        "feedback",
        {},
        feedbackData
      );
    },

    deleteFeedback: async (feedbackId) => {
      return await apiCall(
        "DELETE",
        `feedback/${feedbackId}`
      );
    }
  };

  return (
    <DataContext.Provider
      value={{
        apiCall,

        isLeftSidebarOpen,
        toggleLeftSidebar,
        isRightSidebarOpen,
        toggleRightSidebar,

        ProjectsAPI,
        UsersAPI,
        InteractAPI,
        ChannelsAPI,
        AIAPI,
        LibraryAPI,
        AttachmentAPI,
        WebsocketAPI,

        googleStatus,
        handleAuthorizeGoogle,
        refreshAppData,

        activeProjectId,
        setActiveProjectId,
        projects,
        setProjects,
        isLoading, 

        currentCode,
        setCurrentCode,
        sources,
        setSources,
        slmLeaderboardData,
        setSlmLeaderboardData,
        contextAnalysisData,
        setContextAnalysisData,
        isStreaming,
        setIsStreaming,
        triggerPreview,
        setTriggerPreview,
        isPanelVisible,
        setIsPanelVisible,
        selectedPanel,
        setSelectedPanel,
        chatSidebarOpen,
        setChatSidebarOpen,

        activeChannelId,
        setActiveChannelId,
        editingChannelId,
        setEditingChannelId,
        editedChannelName,
        setEditedChannelName,
        channels,
        setChannels,

        libraryData,
        setLibraryData,
        libraryId,
        setLibraryId,
        firstFolderData,
        handleAddSource,
        handleIngestWorkflowy,
        handleCrawlWebsite,
        handleItemUpdate,
        deleteSingleItem,
        deleteDriveFolder,
        getStats,
        blockPriorityUpdates,
        handleBlockPriorityUpdates,
        updateChildIngestionStatus,
        refreshLibraryItem,
        streamFolderSyncStatus,
        streamLock,
        setStreamLock,

        ingestVideo,

        isConnected,
        setIsConnected,
        lastMessage,
        setLastMessage,

        socketRef,
        reconnectTimeoutRef,
        reconnectAttemptsRef,

        artifactError,
        setArtifactError,

        selectedArtifact,
        setSelectedArtifact,

        autoGeneratedChannelName,
        setAutoGeneratedChannelName,

        FeedbackAPI,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
