import React, { useState, useEffect } from 'react';

function ChatSwitch({ icon, onToggle, onLabel = "On", offLabel = "Off", initialState = false }) {
  const [isOn, setIsOn] = useState(initialState);

  useEffect(() => {
    setIsOn(initialState);
  }, [initialState]);

  const handleToggle = () => {
    const newState = !isOn;
    setIsOn(newState);
    if (onToggle) {
      onToggle(newState);
    }
  };

  return (
    <div className={`switch ${isOn ? 'on' : 'off'}`} onClick={handleToggle} >
      { icon ? icon : null }
      <div className="switch-text switch-text-on">{onLabel}</div>
      <div className="switch-text switch-text-off">{offLabel}</div>
      <div className="switch-ball"></div>
    </div >
  );
}

export default ChatSwitch;
