import { useUser } from "@clerk/clerk-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useData } from "../contexts/DataContext";
import { LuTrash2 } from "react-icons/lu";
import { IoChatbubblesOutline } from "react-icons/io5";
import { LuSignal } from "react-icons/lu";
import { FaChevronUp, FaMagic, FaUndo } from "react-icons/fa";
import { Loader2 } from "lucide-react";
import SharpLightAttachment from "./custom-icons/SharpLightAttachment";
import ChatDropdown from "./ChatDropdown";
import ChatSwitch from "./ChatSwitch";
import { toast } from 'react-hot-toast';
import * as Tooltip from '@radix-ui/react-tooltip';
import { DEFAULT_CHANNEL_NAME } from "../lib/constants";
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import '@webscopeio/react-textarea-autocomplete/style.css';

// Language model types
const lmTypes = {
  "Sonnet 3.5": "anthropic-sonnet35",
  "Haiku 3.5": "anthropic-haiku35",
  "OpenAI 4o": "openai-4o",
  "OpenAI 4om": "openai-4om",
  "Gemini 1.5 Pro": "gemini-1.5-pro",
  "Gemini Flash": "gemini-model-flash",
  "Grok-2 Beta": "grok-beta",
  "Llama 70B": "groq-70",
  "Llama 8B": "groq-8",
  "Mistral 7B": "groq-mistral",
  "Perplexity": "perplexity_online_large",
};

const modelOptions = Object.entries(lmTypes).map(([name, value]) => ({
  label: name,
  value: value,
}));

const ChatInput = ({
  messages,
  onSendMessage,
  sendAIMessage,
  selectedModel,
  setSelectedModel,
  isSharedContext,
  setSharedContext,
  isInternetEnabled,
  setIsInternetEnabled,
  lockModelSelection,
  setLockModelSelection,
  channelState,
}) => {
  const { isStreaming, setIsStreaming, artifactError, setArtifactError, setInitialUserMessage, channels, activeChannelId, InteractAPI, setAutoGeneratedChannelName, AttachmentAPI, libraryData } = useData();
  const { user } = useUser();

  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);
  const [toUploadAttachment, setToUploadAttachment] = useState([]);
  const [filesChanged, setFilesChanged] = useState(false);
  const [isAIEnabled, setIsAIEnabled] = useState(true);
  const [isChannelRenamed, setIsChannelRenamed] = useState(false);
  const [enhanceLoading, setEnhanceLoading] = useState(false);
  const textareaRef = useRef(null);
  const [messageHistory, setMessageHistory] = useState([]);
  const [showRevertButton, setShowRevertButton] = useState(false);
  const fileInputRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [usedTokens, setUsedTokens] = useState(0);

  useEffect(() => {
    if (filesChanged) {
      setTimeout(() => {
        setFilesChanged(false);
      }, 600);
    }
  }, [files]);

  useEffect(() => {
    if (toUploadAttachment.length > 0) {
      setIsStreaming(true);
      toUploadAttachment.forEach(async (attachment) => {
        const b64Data = await fileToBase64(attachment.data);
        const response = await AttachmentAPI.uploadAttachment(attachment.attachmentId, b64Data, usedTokens);
        if (response.error) {
          toast.error("Token limit exceeded. Please try again with a smaller file.");
        } else {
          setUsedTokens(prev => prev + response.used_tokens);
        }
        setToUploadAttachment(prev => prev.filter(a => a.attachmentId !== attachment.attachmentId));
        setFiles(prev => prev.map(f => f.attachmentId === attachment.attachmentId ? {...f, status: 'uploaded', base64: b64Data} : f));
      });
    } else {
      setIsStreaming(false);
    }
  }, [toUploadAttachment]);

  useEffect(() => {
    if (artifactError) {
      setMessage(`Getting this error: \n\n${artifactError.message} \n\nCan you please fix it?`);
      setArtifactError(null);
    }
  }, [artifactError]);


  useEffect(() => {
    if (!isStreaming && textareaRef.current) {
      textareaRef.current.style.height = "5px";
      textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
      textareaRef.current.focus();
    }
  }, [isStreaming, message]);

  useEffect(() => {
    const flattenedData = libraryData.flatMap(folder => {
      return folder.children.map(child => child.file_name);
    });
    setSuggestions(flattenedData);
  }, [libraryData]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!isStreaming) {
        handleSubmit();
      }
    }
  };
  
  // The toggle function, updating only the internal state
  const toggleAIEnabled = useCallback((e) => {
    const newValue = e.target.checked;
    setIsAIEnabled(newValue);
  }, []);

  useEffect(() => {
    setIsInternetEnabled(selectedModel === "perplexity_online_large");
  }, [selectedModel]);

  const toggleInternetEnabled = () => {
    const newVal = !isInternetEnabled;
    setIsInternetEnabled(newVal);
    setLockModelSelection(newVal);
  }
  const toggleSharedContext = () => {
    setSharedContext((prev) => !prev);
  }

  const handleAutoGenerateChannelName = async () => {
    if(isChannelRenamed) {
      return;
    }
    const channel = channels.find((c) => c.id === activeChannelId);
    if(channel.name === DEFAULT_CHANNEL_NAME) {
      const generateChannelName = await InteractAPI.generateChannelName(message);
      const newChannelName = generateChannelName.replace(/"/g, '').split(" ").slice(0, 5).join(" ") || DEFAULT_CHANNEL_NAME;
      setAutoGeneratedChannelName(newChannelName);
      setIsChannelRenamed(true);
    }
  }

  const handleEnhanceQuery = async () => {
    if (!message.trim()) {
      toast.error("Input is empty. Please enter a message.");
      return;
    }
    setEnhanceLoading(true);
    try {
      const channel = channels.find((c) => c.id === activeChannelId);
      const enhancedQuery = await InteractAPI.enhanceQuery(channel.name, messages, message);
      setMessageHistory((prevHistory) => [...prevHistory, message]);
      setMessage(enhancedQuery);
      setShowRevertButton(true);
    } catch (error) {
      console.error("Error enhancing query:", error);
      toast.error("Failed to enhance query. Please try again.");
    } finally {
      setEnhanceLoading(false);
    }
  };

  const handleRevertQuery = () => {
    if (messageHistory.length > 0) {
      const previousMessage = messageHistory[messageHistory.length - 1];
      setMessage(previousMessage);
      setMessageHistory((prevHistory) => prevHistory.slice(0, -1));
      if (messageHistory.length === 1) {
        setShowRevertButton(false);
      }
    }
  };

  const handleSubmit = useCallback(
     (e) => {
      if (e) e.preventDefault();
      if (!message.trim()) return;

      // Deliberately not awaiting because it's okay if the channel name is not generated immediately
      handleAutoGenerateChannelName();
      const attachmentsFull = files.map((file, index) => ({
        index: index,
        name: file.data.name,
        extension: file.data.name.split('.').pop()?.toLowerCase(),
        is_image: file.data.type.startsWith('image/'),
        attachment_id: file.attachmentId || '', 
        base64: file.base64,
      }));
      const attachments = attachmentsFull.map(({base64, ...attachment}) => attachment);

      if (isAIEnabled) {
        sendAIMessage(message, {
          selectedModelOverride: selectedModel,
          attachments: attachments,
          attachmentsFull: attachmentsFull,
        });

        // Clear input fields
        setMessage("");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setFiles([]);
      } else {
        // Send regular message

        const messageData = {
          id: uuidv4(),
          content: message,
          timestamp: new Date().toISOString(),
          isAI: false,
          user_id: user.id,
          attachments: attachments,
        };

        onSendMessage(messageData, attachmentsFull);
        setMessage("");
        setFiles([]);
      }
      setMessageHistory([]);
      setShowRevertButton(false);
    },
    [
      isAIEnabled,
      message,
      selectedModel,
      sendAIMessage,
      user.id,
      onSendMessage,
      files,
    ]
  );

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };
  

  const handleFileChange = (newFiles) => {
    if (newFiles && newFiles.length > 0) {
      setFilesChanged(true);
      setFiles(prevFiles => {
        const updatedFiles = prevFiles ? prevFiles : [];
        const existingFileNames = new Set(updatedFiles.map(file => file.data.name));
        const newFilesConverted = Array.from(newFiles)
        newFilesConverted.forEach(file => {
          if (!existingFileNames.has(file.name)) {
            const isImage = file.type.startsWith('image/');
            const isTextFile = file.type.startsWith('text/');
            const isPDF = file.type.startsWith('application/pdf');
            let coercedFile;
            let uniqueAttachmentId = uuidv4();
            if (isPDF) {
              coercedFile = new File([file], file.name, { type: 'application/pdf' });
            } else {
              coercedFile = isImage || isTextFile ? file : new File([file.data], file.name, { type: 'text/plain' });
            }
            const fileWrapper = {
              data: coercedFile,
              attachmentId: activeChannelId + '__' + uniqueAttachmentId,
              base64: null,
              status: 'uploading',
            };
            setToUploadAttachment(prev => [...prev, fileWrapper]);
            updatedFiles.push(fileWrapper);
            existingFileNames.add(file.name);
          }
        });
        return updatedFiles;
      });
    }
  };


  const handleDeleteFile = async (attachmentId) => {
    setFiles(prevFiles => prevFiles.filter(file => file.attachmentId !== attachmentId));  
    await AttachmentAPI.deleteAttachment(attachmentId);
    console.log('Deleted attachment', attachmentId);
  }

  const handleRemoveAllFiles = () => {
    setFiles([]);
    files.forEach(async (file) => {
      await AttachmentAPI.deleteAttachment(file.attachmentId);
      console.log('Deleted attachment', file.attachmentId);
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePaste = useCallback((event) => {
    //Might need to access underlying textarea in the new implementation
    //innerRef={(textarea) => {
     // if (textarea) {
      //  textarea.addEventListener("paste", this.handlePaste);
      //}
    //}}

    const clipboardItems = event.clipboardData.items;
    const pastedFiles = [];

    const getUniqueFileName = (baseName, extension) => {
      let counter = 1;
      let fileName = `${baseName}.${extension}`;
      while (files.some(file => file.data.name === fileName)) {
        fileName = `${baseName}_${counter}.${extension}`;
        counter++;
      }
      return fileName;
    };

    for (let i = 0; i < clipboardItems.length; i++) {
      const item = clipboardItems[i];
      if (item.type.indexOf('image') !== -1) {
        const blob = item.getAsFile();
        if (blob) {
          const extension = blob.type.split('/')[1] || 'png';
          const fileName = getUniqueFileName('image', extension);
          const file = new File([blob], fileName, { 
            type: blob.type,
            lastModified: new Date().getTime()
          });
          pastedFiles.push(file);
        }
      } 
    }

    if (pastedFiles.length > 0) {
      event.preventDefault();
      setFiles(prevFiles => {
        const updatedFiles = prevFiles ? prevFiles : [];
        pastedFiles.forEach(file => {
          if (!updatedFiles.some(existingFile => existingFile.data.name === file.name)) {
            const uniqueAttachmentId = uuidv4();
            const fileWrapper = {
              data: file,
              attachmentId: activeChannelId + '__' + uniqueAttachmentId,
              status: 'uploading',
            };
            updatedFiles.push(fileWrapper);
            setToUploadAttachment(prev => [...prev, fileWrapper]);
          }
        });
        return updatedFiles;
      });
    }
  }, [files, setFiles]);


  useEffect(() => {
    if (isInternetEnabled) {
      setSelectedModel(lmTypes["Perplexity"]);
    } else {
      setSelectedModel(lmTypes["OpenAI 4o"]);
    }
  }, [isInternetEnabled])

  // Add window-level drag event listeners
  useEffect(() => {
    const handleWindowDragEnter = (e) => {
      e.preventDefault();
      if (e.dataTransfer.types.includes('Files')) {
        setIsDragging(true);
      }
    };

    const handleWindowDragLeave = (e) => {
      e.preventDefault();
      // Only handle drag leave if we're leaving the window
      if (e.clientY <= 0 || e.clientX <= 0 || 
          e.clientX >= window.innerWidth || e.clientY >= window.innerHeight) {
        setIsDragging(false);
      }
    };

    window.addEventListener('dragenter', handleWindowDragEnter);
    window.addEventListener('dragleave', handleWindowDragLeave);

    return () => {
      window.removeEventListener('dragenter', handleWindowDragEnter);
      window.removeEventListener('dragleave', handleWindowDragLeave);
    };
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFileChange(e.dataTransfer.files);
    }
  }, [handleFileChange]);

  return (
    <Tooltip.Provider>
    <div 
      className={`chat-input-area sticky bottom-0 p-4 rounded-md bg-white relative ${isDragging ? 'dragging' : ''}`}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {isDragging && (
        <div className="drop-overlay">
          <div className="drop-card">
            <SharpLightAttachment className="w-12 h-12 mb-2" />
            <span>Drop files here</span>
          </div>
        </div>
      )}
      {/* Display attached files */}
      <div className="_attachments_container">
        {files 
          ? (files).map((file, index) => {
          const { type } = file.data;
          const extension = file.data.name.split('.').pop()?.toLowerCase();

          if (type.includes('image') || ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'].includes(extension || '')) {
            return (
              <div key={index} className={`_image_attachment_container ${file.status === 'uploading' ? 'uploading' : ''}`}>
                <button
                  onClick={() => handleDeleteFile(file.attachmentId)}
                  className="_overlay_trash_icon"
                  disabled={file.status === 'uploading'}
                >
                  <LuTrash2 />
                </button>
                <img
                  className="_image_attachment"
                  src={URL.createObjectURL(file.data)}
                  alt={file.data.name}
                />
                <span className="_image_attachment_name">
                  {file.data.name}
                </span>
              </div>
            );
          } else if (type.includes('text') || type.includes('application/pdf')){
            return (
              <div
                key={index}
                className={`_text_attachment_container ${file.status === 'uploading' ? 'uploading' : ''}`}
              >
                <button
                  onClick={() => handleDeleteFile(file.attachmentId)}
                  className="_overlay_trash_icon"
                  disabled={file.status === 'uploading'}
                >
                  <LuTrash2 />
                </button>
                <div className="_text_attachment">
                  <span className="text-xs">{extension?.toUpperCase() || 'FILE'}</span>
                </div>
                <span className="_text_attachment_name">
                  {file.data.name}
                </span>
              </div>
            );
          }
        })
        : ''
        }
        {files && files.length > 0 && (
        <div className="relative right-0">
          <button
            variant="ghost"
            size="icon"
            className="h-7 w-7"
            onClick={handleRemoveAllFiles} // Remove all files
          >
            <LuTrash2 className="h-4 w-4" />
          </button>
        </div>
        )} 
        </div>

      <form onSubmit={handleSubmit} className="chat-input-form">
        <label className="flex items-center cursor-pointer">
          <SharpLightAttachment />
          <input
            type="file"
            accept="image/*, text/*, application/json, .c, .cpp, .cc, .cxx, .hpp, .h, .rs, .go, .swift, .kt, .kts, .java, .py, .js, .jsx, .mjs, .cjs, .ts, .tsx, .rb, .php, .pl, .sh, .bash, .zsh, .fish, .bat, .lua, .html, .css, .scss, .less, .md, .yaml, .yml, .json, .toml, .xml, .ini, .sql, .csv, .tsv, .hs, .lhs, .r, .lisp, .clj, .cljs, .erl, .ex, .exs, .dart, .f, .f90, .vhd, .vhdl, .v"
            onChange={(e) => handleFileChange(e.target.files)}
            disabled={isStreaming}
            multiple={true}
            className="hidden"
            ref={fileInputRef}
          />
        </label>
        <ReactTextareaAutocomplete
          innerRef={(ref) => {
            textareaRef.current = ref;
          }}
          className="_chat-input-area"
          containerClassName="react-textarea-container"
          dropdownClassName="react-textarea-dropdown"
          itemClassName="react-textarea-item"
          loadingComponent={() => <span>Loading</span>}
          placeholder="Enter your message..."
          value={message}
          onChange={handleInputChange}
          onPaste={handlePaste}
          onKeyDown={(e) => {
            if (e.key === "Enter" && (e.altKey || e.metaKey)) {
              if (!isStreaming) {
                handleEnhanceQuery();
              }
            } else {
              handleKeyDown(e);
            }
          }}
          minChar={0}
          trigger={{
            '@': {
              dataProvider: (token) => {
                const entries = [...suggestions.map(item => `@${item.replace(/\s/g, '\u00A0')}\u200B`)];
                return entries.filter(item => 
                  item.toLowerCase().includes(`${token.replace('@', '').toLowerCase()}`)
                ).slice(0, 20);
              },
              component: ({ entity }) => <div>{entity}</div>,
              output: (item) => item,
            },
            '#': {
              dataProvider: (token) => {
                const entries = [...suggestions.map(item => `#${item.replace(/\s/g, '\u00A0')}\u200B`)];
                return entries.filter(item => 
                  item.toLowerCase().includes(`${token.replace('#', '').toLowerCase()}`)
                ).slice(0, 20);
              },
              component: ({ entity }) => <div>{entity}</div>,
              output: (item) => item,
            },
            '/': {
              dataProvider: (token) => {
                const entries = ['/foreach\u200B'];
                return entries.filter(item => 
                  item.toLowerCase().includes(`${token.replace('/', '').toLowerCase()}`)
                );
              },
              component: ({ entity }) => <div>{entity}</div>,
              output: (item) => item,
            },
          }}
          disabled={isStreaming}
        />
        <div className="magic-wand-group">
          {showRevertButton && (
            <button
              type="button"
              className={`chat-send-button revert-button ${showRevertButton ? 'visible' : ''}`}
              onClick={handleRevertQuery}
              disabled={isStreaming}
            >
              <FaUndo />
            </button>
          )}
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <button
                type="button"
                className="chat-send-button"
                onClick={handleEnhanceQuery}
                disabled={isStreaming || !message.trim()}
              >
                {enhanceLoading ? (
                  <Loader2 className="text-[#5A165D] animate-spin" />
                ) : (
                  <FaMagic />
                )}
              </button>
            </Tooltip.Trigger>
            <Tooltip.Content className="text-xs text-gray-500 border border-gray-200 p-1 bg-white">
              Alt/⌥ + Enter
            </Tooltip.Content>
          </Tooltip.Root>
        </div>
        <button
          className="chat-send-button"
          onClick={handleSubmit}
          disabled={isStreaming}
        >
          <FaChevronUp />
        </button>
      </form>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <ChatDropdown
            options={modelOptions}
            value={selectedModel}
            onChange={(value) => setSelectedModel(value)}
            disabled={isStreaming || !isAIEnabled || lockModelSelection}
            goesUp={true}
          />
        </div>
        <div className="_chat-switches">
          <ChatSwitch
            onToggle={toggleSharedContext}
            initialState={isSharedContext}
            onLabel="Use All Chats"
            offLabel="Limit To Current"
            icon={<IoChatbubblesOutline />}
          />
          <ChatSwitch
            onToggle={toggleInternetEnabled}
            initialState={isInternetEnabled}
            onLabel="Internet"
            offLabel="Internet"
            icon={<LuSignal />}
          />
        </div>
      </div>
    </div>
    </Tooltip.Provider>
  );
};

export default ChatInput;
