import React, { useEffect, useState } from 'react';
import { useData } from "../contexts/DataContext";
import { LuChevronRight, LuChevronLeft } from "react-icons/lu";
import { toast } from "react-hot-toast";

const MessageRelatedQueries = ({
  messages,
  scrollToBottom,
  sendAIMessage,
  selectedModel,
}) => {
  const { ProjectsAPI, activeProjectId, isStreaming } = useData();
  const [mergedQueries, setMergedQueries] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const queriesPerPage = 3; // Show only three queries per page
  const projectId = activeProjectId;

  useEffect(() => {
    const fetchAndMergeQueries = async () => {
      if (projectId) {
        try {
          const projectData = await ProjectsAPI.readProject(projectId);
          const projectQueries = projectData.predefined_queries?.filter(query => query.trim() !== '') || [];
          const unusedProjectQueries = projectQueries.filter(query => !messages.some(msg => msg.content === query));

          const aiQueries = await ProjectsAPI.generateGuidedQueries(projectId, messages);
          const aiGeneratedQueries = aiQueries.filter(query => query.trim() !== '') || [];

          // Merging the project and AI queries
          const merged = [
            ...unusedProjectQueries.map(q => ({ type: 'project', query: q })),
            ...aiGeneratedQueries.map(q => ({ type: 'AI', query: q })),
          ];

          setMergedQueries(merged);
          setTimeout(() => scrollToBottom(), 200);
        } catch (error) {
          console.error("Error fetching queries:", error);
        }
      }
    };

    fetchAndMergeQueries();
  }, []);

  const handleQueryClick = (queryObj) => {
    if (sendAIMessage) {
      sendAIMessage(queryObj.query, {
        selectedModelOverride: selectedModel,
      });
      scrollToBottom();
    } else {
      toast.error('AI messaging is not available.');
    }
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(mergedQueries.length / queriesPerPage) - 1;
    setCurrentPage(prevPage => Math.min(prevPage + 1, maxPage));
  };

  const renderQueryList = () => {
    const startIndex = currentPage * queriesPerPage;
    const displayedQueries = mergedQueries.slice(startIndex, startIndex + queriesPerPage);

    return (
      <div className="_query-list">
        <ul>
          {displayedQueries.map((queryObj, index) => (
            <li key={index} onClick={() => handleQueryClick(queryObj)} className="_query-item">
              <span className="query-type">
                {queryObj.type.toUpperCase()}
              </span>
              <span className="query-text">{queryObj.query}</span>
              <span className="chevron"><LuChevronRight /></span>
            </li>
          ))}
        </ul>
        <div className="_pagination-controls">
          <button onClick={handlePrevPage} disabled={currentPage === 0}>
            <LuChevronLeft />
          </button>
          <button onClick={handleNextPage} disabled={currentPage >= Math.ceil(mergedQueries.length / queriesPerPage) - 1}>
            <LuChevronRight />
          </button>
        </div>
      </div>
    );
  };

  // Only show the component if there is more than one query
  if (mergedQueries.length === 0) {
    return null;
  }

  return (
    <div className="_message-related-queries">
      {renderQueryList()}
    </div>
  );
};

export default MessageRelatedQueries;
