import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { useAuth } from '@clerk/clerk-react';
import { useUser } from '@clerk/clerk-react';
import { useData } from '@/contexts/DataContext';

import { LuLogOut, LuCodesandbox, LuUser2 } from "react-icons/lu";
import { TbLayoutSidebarLeftExpand, TbLayoutSidebarLeftCollapse, TbLayoutSidebarRightCollapse, TbLayoutSidebarRightExpand } from "react-icons/tb";

import LogoMark from '../assets/ephor.svg';
import EphorHeaderLogo from './custom-icons/EphorHeaderLogo';
import RightFromBracketIcon from './custom-icons/RightFromBracketIcon';
import LeftFromBracketIcon from './custom-icons/LeftFromBracketIcon';

const Topbar = () => {
  const { isLeftSidebarOpen, isRightSidebarOpen, toggleRightSidebar, toggleLeftSidebar } = useData();
  const [userMenuExpanded, setUserMenuExpanded] = useState(false);
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const { user } = useUser();
  const userMenuRef = useRef();

  const navigationOptions = [
    { label: 'Projects', path: '/projects', icon: <LuCodesandbox />, },
  ];


  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
    document.body.classList.toggle('_sidebar-open');
  };

  const toggleUserMenu = () => {
    setUserMenuExpanded(!userMenuExpanded);
  };

  const handleNavigate = useCallback((path) => {
    setUserMenuExpanded(false);
    navigate(path);
  }, [navigate]);

  const handleSignOut = useCallback(() => {
    setUserMenuExpanded(false);
    signOut();
  }, [signOut]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setUserMenuExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='_topbar'>
      <div className='_topbar-left'>
        <div className='_topbar-navigation'>
          <button className="_topbar-navigation-toggle" onClick={toggleLeftSidebar}>
            {isLeftSidebarOpen ? <LeftFromBracketIcon /> : <RightFromBracketIcon />}
          </button>
        </div>
      </div>

      <div className='_topbar-center'>
        <Link to="/" style={{
          width: "140px",
          height: "20px",
        }}>
          <EphorHeaderLogo />
        </Link>
      </div>

      <div className='_topbar-right'>
        <div className='_topbar-user-menu'>
          <button className="_topbar-user-menu-toggle" onClick={toggleUserMenu}>
            <img src={user.imageUrl} />
            <span>{user.firstName}</span>
          </button>
          <div
            className={`_topbar-user-menu-container ${userMenuExpanded ? 'open' : ''}`}
            ref={userMenuRef}
          >
            <button className="_topbar-user-menu-option" onClick={() => handleNavigate('/profile')}>
              <span>Profile</span>
              <LuUser2 />
            </button>
            <button className="_topbar-user-menu-option" onClick={handleSignOut}>
              <span>Sign out</span>
              <LuLogOut />
            </button>
          </div>
        </div>
        <div className='_topbar-right-sidebar-toggle'>
          <button className="_topbar-navigation-toggle" onClick={() => toggleRightSidebar(false)}>
            {isRightSidebarOpen ? <RightFromBracketIcon /> : <LeftFromBracketIcon />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
