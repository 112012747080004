const EphorHeaderLogo = () =>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183.18 32">
    <defs>
      <style>
        {`.cls-1 {fill: #fff;}`}
      </style>
    </defs>
    <path fill="white" d="M20.85,27.05v2.69H0V.26h20.22v2.69H3.11v10.48h15.26v2.64H3.11v10.95h17.74v.02Z" />
    <path fill="white" d="M59.27,10.37c0,6.27-4.56,10.06-12.05,10.06h-7.93v9.31h-3.11V.26h11.05c7.49,0,12.05,3.79,12.05,10.11ZM56.16,10.37c0-4.73-3.11-7.42-9.01-7.42h-7.84v14.74h7.84c5.9,0,9.01-2.69,9.01-7.32Z" />
    <path fill="white" d="M98.66.26v29.49h-3.07v-13.62h-18.46v13.62h-3.11V.26h3.11v13.15h18.46V.26h3.07Z" />
    <path fill="white" d="M113.63,15c0-8.59,6.58-15,15.51-15s15.47,6.37,15.47,15-6.62,15-15.47,15-15.51-6.41-15.51-15ZM141.48,15c0-7.04-5.27-12.22-12.36-12.22s-12.43,5.17-12.43,12.22,5.31,12.22,12.43,12.22,12.36-5.17,12.36-12.22Z" />
    <path fill="white" d="M179.74,29.74l-6.74-9.48c-.75.09-1.52.12-2.36.12h-7.91v9.36h-3.11V.26h11.05c7.51,0,12.05,3.79,12.05,10.11,0,4.63-2.43,7.89-6.74,9.27l7.21,10.11h-3.44ZM179.58,10.37c0-4.73-3.11-7.42-9.01-7.42h-7.84v14.79h7.84c5.9,0,9.01-2.74,9.01-7.37Z" />
  </svg>

export default EphorHeaderLogo;