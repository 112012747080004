import { UserProfile } from "@clerk/clerk-react";
import { useState, useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import { LuArrowLeft, LuUser, LuLink } from "react-icons/lu";
import ProfileSummary from "./ProfileSummary";
import Integration from "./Integration";
export default function CustomUserProfile() {
    const navigate = useNavigate();
    const redirect = new URLSearchParams(window.location.search).get('redirect');
    const from = new URLSearchParams(window.location.search).get('from');
    const [showReconnect, setShowReconnect] = useState(redirect);

    useEffect(() => {
        if (redirect) {
            const url = new URL(window.location);
            url.searchParams.delete('redirect');
            window.history.replaceState({}, '', url);
        }
    }, [redirect]);

    return (
              <div className={`${!showReconnect ? '_userProfileContainerDefault' : '_userProfileContainerReconnect'}`}>
                <div className="_userProfileContainerBackButton">
                    <button onClick={() => {
                        navigate(from ? from : '/projects');
                    }}>
                    <LuArrowLeft className="_userProfileContainerBackButton-icon" />
                    <span>Back to {from ? from.split('/').pop() : 'Projects'}</span>
                    </button>
                </div>
                <UserProfile>
                    <UserProfile.Page 
                        label="Profile Outline" 
                        url="/profile-outline" 
                        labelIcon={<LuUser />}
                    >
                        <ProfileSummary />
                    </UserProfile.Page>
                    <UserProfile.Page 
                        label="Integrations" 
                        url="/integrations" 
                        labelIcon={<LuLink />}
                    >
                        <Integration />
                    </UserProfile.Page>
                </UserProfile>
              </div>
    )
}