import React from 'react';
import * as Tooltip from "@radix-ui/react-tooltip";
import '../styles/ContextUsageBar.scss';

const formatNumber = (num) => {
    if (num >= 1000000) {
        return `${(num / 1000000).toFixed(1)}M`;
    }
    if (num >= 1000) {
        return `${(num / 1000).toFixed(1)}K`;
    }
    return num.toString();
};

const ContextUsageBar = ({ contextData }) => {
    if (!contextData) return null;

    const categories = {
        system_prompt: { color: '#a246a6', label: 'System Prompt' },
        custom_instructions: { color: '#d6b9fa', label: 'Custom Instructions' },
        past_messages: { color: '#cad9fc', label: 'Past Messages' },
        retrieved_context: { color: '#9f9fff', label: 'Retrieved Context' }
    };

    const totalTokens = contextData.total_tokens;
    const maxTokens = contextData.max_tokens || 4096; // fallback to 4096 if not provided
    
    return (
        <div className="context-usage-container">
            <div className="header">
                <span className="title">Used Context</span>
                <span className="percentage">
                    {contextData.context_usage_pct.toFixed(2)}% of {formatNumber(maxTokens)}
                </span>
            </div>
            <div className="usage-bar">
                {Object.entries(categories).map(([key, { color, label }], index) => {
                    const width = (contextData.category_tokens[key] / totalTokens) * 100;
                    if (width === 0) return null;
                    
                    return (
                        <Tooltip.Provider key={key}>
                            <Tooltip.Root>
                                <Tooltip.Trigger asChild>
                                    <div
                                        className="usage-segment"
                                        style={{
                                            width: `${width}%`,
                                            backgroundColor: color
                                        }}
                                    />
                                </Tooltip.Trigger>
                                <Tooltip.Portal>
                                    <Tooltip.Content 
                                        className="tooltip-content" 
                                        sideOffset={5}
                                        collisionPadding={10}
                                        align="center"
                                        alignOffset={0}
                                        avoidCollisions={true}
                                    >
                                        {`${label}: ${contextData.category_usage_pct[key].toFixed(2)}% (${contextData.category_tokens[key]} tokens)`}
                                        <Tooltip.Arrow className="tooltip-arrow" />
                                    </Tooltip.Content>
                                </Tooltip.Portal>
                            </Tooltip.Root>
                        </Tooltip.Provider>
                    );
                })}
            </div>
            <div className="legend">
                {Object.entries(categories).map(([key, { color, label }]) => (
                    contextData.category_tokens[key] > 0 && (
                        <div key={key} className="legend-item">
                            <div 
                                className="color-dot"
                                style={{ backgroundColor: color }}
                            />
                            <span className="label">{label}</span>
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default ContextUsageBar; 