import React from 'react';

const CinemaModal = ({ url, onClose }) => {
  return (
    <div className="_cinema-modal">
      <div className="_cinema-modal-content">
        <button className="_cinema-close-button" onClick={onClose}>
          ×
        </button>
        <iframe
          src={url}
          className="_cinema-iframe"
          title="Cinema Mode Preview"
          sandbox="allow-scripts allow-same-origin"
        />
      </div>
    </div>
  );
};

export default CinemaModal;