import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FiMenu, FiCode, FiDownload, FiRefreshCw, FiPlay, FiExternalLink, FiEye, FiUpload, FiCopy } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';
import Alert from './Alert';

const PreviewOptionsMenu = ({
  onShowCode,
  onDownload,
  onRestart,
  onRefresh,
  onOpenInCodesandbox,
  onPublish,
  isPreviewOnly,
  publishedUrl,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const menuRef = useRef(null);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleOptionClick = (callback) => {
    callback();
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onCopyPublishedUrl = () => {
    if(publishedUrl) {
      navigator.clipboard.writeText(publishedUrl)
      setShowAlert(true)
      setAlertMessage('Published URL copied to clipboard')
    }
  }

  const options = useMemo(() => {
    const baseOptions = [
    { icon: FiExternalLink, text: 'Open in Codesandbox', onClick: onOpenInCodesandbox },
    { icon: isPreviewOnly ? FiCode : FiEye, text: isPreviewOnly ? 'Show Code' : 'Hide Code', onClick: onShowCode },
    { icon: FiDownload, text: 'Download', onClick: onDownload },
    // { icon: FiPlay, text: 'Restart', onClick: onRestart },
    { icon: FiRefreshCw, text: 'Refresh', onClick: onRefresh },
  ]

  if(onPublish) {
    baseOptions.push({ icon: publishedUrl ? FiCopy : FiUpload, text: publishedUrl ? 'Copy Link' : 'Publish', onClick: publishedUrl ? onCopyPublishedUrl : onPublish })
  }

  return baseOptions
}, [isPreviewOnly, onOpenInCodesandbox, onShowCode, onDownload, onRestart, onRefresh, onPublish, publishedUrl]);

  return (
    <div className="relative z-50" ref={menuRef}>
      <motion.button
        onClick={toggleMenu}
        className="p-2 bg-blue-600 text-white rounded-full shadow-md hover:bg-blue-700 transition duration-300 ease-in-out flex items-center justify-center"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <FiMenu className="w-5 h-5" />
      </motion.button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 bottom-full mb-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden"
          >
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              {options.map((item, index) => (
                <motion.button
                  key={index}
                  onClick={() => handleOptionClick(item.onClick)}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  className={`flex items-center px-4 py-2 text-sm text-gray-700 w-full text-left ${
                    hoveredIndex === index ? 'bg-gray-100 text-gray-900' : ''
                  }`}
                >
                  <item.icon className="mr-3 h-5 w-5 text-gray-400" />
                  {item.text}
                </motion.button>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {showAlert && <Alert type="success" message={alertMessage} onClose={() => setShowAlert(false)} />}
    </div>
  );
};

export default PreviewOptionsMenu;
