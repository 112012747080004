import React, { useEffect, useState } from "react";
import { SignUp } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { Loader } from "./Loader";
import { useParams } from "react-router-dom";

const InvitationRedirect = () => {
  const [loading, setLoading] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const inviteId = urlParams.get("inviteId");
  let joinId;

  if (!inviteId) {
    joinId = useParams().inviteLink;
  }

  const navigate = useNavigate();

  useEffect(() => {
    const params = inviteId ? { inviteId } : { joinId };

    const newURLParams = new URLSearchParams(params)
    const newUrl = `/?${newURLParams.toString()}`;
    console.log("New URL", newUrl);
    navigate(newUrl, { replace: true });

    // Simulate a brief loading state for user feedback
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="_sign-in-container">
      <Loader />
    </div>
  );
};

export default InvitationRedirect;
