import { GoogleDrivePicker } from "@/components/GoogleDrivePicker";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { useData } from "@/contexts/DataContext";
import { cn } from "@/lib/utils";
import * as Tooltip from "@radix-ui/react-tooltip";
import { HelpCircle, Loader2, ArrowRight } from "lucide-react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { FaInstagram, FaTiktok, FaTwitter, FaYoutube } from "react-icons/fa";
import { PiPaperPlaneTiltBold } from "react-icons/pi";
import { FaLink } from "react-icons/fa";
import ChatSwitch from "./ChatSwitch"; // Assuming ChatSwitch is the switch component used
import { FaPaperPlane } from "react-icons/fa"; // Import an icon for the submit button

export function LibraryUploadCards({ className, isDrawer = false }) {
  const [loading, setLoading] = useState(false);
  const [wfloading, setWfLoading] = useState(false);
  const [wcloading, setWcLoading] = useState(false);

  const {
    handleAddSource,
    streamFolderSyncStatus,
    handleIngestWorkflowy,
    handleCrawlWebsite,
    setStreamLock,
    libraryId,
    handleAuthorizeGoogle,
    ingestVideo,
  } = useData();

  const [driveUrl, setDriveUrl] = useState("");
  const [nodeUrl, setNodeUrl] = useState("");
  const [webCrawlUrl, setWebCrawlUrl] = useState("");
  const [maxDepth, setMaxDepth] = useState(1); // New state for max depth

  const isAnyInputFilled = nodeUrl || webCrawlUrl;

  const handleDriveUrlSubmit = async (e) => {
    e.preventDefault();
    if (driveUrl) {
      setLoading(true);
      try {
        const newItem = await handleAddSource(driveUrl);

        if (newItem) {
          setDriveUrl("");
          streamFolderSyncStatus(newItem.index_reference);
          setStreamLock(true);
        }
        setLoading(false);
        
        // Push event to Google Tag Manager
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'added_content_library',
          'type': 'drive'
        });
      } catch (error) {
        setLoading(false);
        console.log("DriveUrlSubmit Error", error.message);
        if (
          error.message.includes("api.clerk") ||
          error.message.includes("oauth2.googleapis.com") ||
          error.message.includes("refresh_token")
        ) {
          console.log("Authorizing Google anew");
          await handleAuthorizeGoogle();
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const handleWorkflowyUrlSubmit = async (e) => {
    e.preventDefault();
    setWfLoading(true);
    try {
      await handleIngestWorkflowy(nodeUrl, libraryId);
      setNodeUrl("");
      await streamFolderSyncStatus("Project__" + libraryId, false, "workflowy");
      console.log("Scraping event triggered successfully");

      // Push event to Google Tag Manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'added_content_library',
        'type': 'workflowy'
      });
    } catch (error) {
      console.log("Error triggering the backend event:", error);
    } finally {
      setWfLoading(false);
    }
  };

  const onFolderSelect = async (folderId) => {
    if (folderId) {
      const url = `https://drive.google.com/drive/folders/${folderId}?usp=sharing`;
      setLoading(true);
      try {
        const newItem = await handleAddSource(url);
        streamFolderSyncStatus(newItem.index_reference);
        setStreamLock(true);
        if (newItem) {
          setDriveUrl("");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleWebCrawlSubmit = async (e) => {
    e.preventDefault();
    try {
      setWcLoading(true);
      const response = await handleCrawlWebsite(webCrawlUrl, libraryId, maxDepth);
      if (response && response.status === "success") {
        streamFolderSyncStatus(response.index_reference);
      }
      setWebCrawlUrl("");
      setWcLoading(false);
    } catch (error) {
      console.log("Error while crawling website ", error);
      setWcLoading(false);
    }
  }

  const handleMaxDepth = (newState) => {
    const newMaxDepth = newState ? 5 : 1;
    setMaxDepth(newMaxDepth);
    return newMaxDepth === 1;
  };

  // Unified loading state for video ingestion
  const [videoLoading, setVideoLoading] = useState(false);

  // Unified state for video URL input
  const [videoUrl, setVideoUrl] = useState("");

  // Unified handler function for ingesting videos
  const handleIngestVideo = async (e) => {
    e.preventDefault();
    if (videoUrl) {
      setVideoLoading(true);
      try {
        const response = await ingestVideo(videoUrl, libraryId);
        if (response.status === "success") {
          setVideoUrl("");
          // Stream the new item: index_reference is the id of the new item which is already being defined in the backend response
          await streamFolderSyncStatus(response.index_reference, false, "social_media_video");

          // the below was the previous way to stream the project to update the UI, i'm just testing this new way
          // await streamFolderSyncStatus("Project__" + libraryId); 
          
          // Push event to Google Tag Manager
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "added_content_library",
            type: "video",
          });
        } else {
          // Show error toast if the video ingestion failed
          toast.error(response.message || "Failed to ingest video");
        }
      } catch (error) {
        console.error("Error ingesting video:", error.message);
        // Show error toast if the video ingestion failed
        toast.error("Error ingesting video: " + error.message);
      } finally {
        setVideoLoading(false);
      }
    }
  };

  return (
    <Tooltip.Provider>
      <div className={cn("grid items-start gap-4", className)}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 ">
          <Card>
            <CardHeader className={cn(isDrawer ? "pt-1" : "", "pb-4")}>
              {!isDrawer && (
                <CardTitle>
                  <div className="flex items-center">
                    Google Drive
                    {loading && (
                      <Loader2 className="ml-2 text-gray-400 animate-spin" />
                    )}
                  </div>
                </CardTitle>
              )}
            </CardHeader>
            <CardContent>
            <div className="mb-4">
            </div>
              <div className="flex items-center mb-2">
                <GoogleDrivePicker onFolderSelect={onFolderSelect} />
              </div>
              <form
                className="flex items-center"
                onSubmit={handleDriveUrlSubmit}
              >
                <Input
                  type="text"
                  placeholder="Enter Google Drive Folder link"
                  className="w-full mr-2 h-9"
                  value={driveUrl}
                  onChange={(e) => setDriveUrl(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleDriveUrlSubmit(e);
                    }
                  }}
                />
                <Button
                  type="submit"
                  variant="outline"
                  size="icon"
                  className="h-9 w-9"
                >
                  <FaLink size={15} />
                </Button>
              </form>
            </CardContent>
          </Card>
          <Card className="relative">
            <CardHeader className={cn(isDrawer ? "pt-1" : "", "pb-4")}>
              {!isDrawer && (
                <CardTitle>
                  <div className="flex items-center">
                    WorkFlowy{" "}
                    <span className="text-xs text-muted-foreground px-1">
                      (Beta)
                    </span>
                    {wfloading && (
                      <Loader2 className="ml-2 text-gray-400 animate-spin" />
                    )}
                    <Tooltip.Root>
                      <Tooltip.Trigger>
                        <HelpCircle size={15} className="ml-1" />
                      </Tooltip.Trigger>
                      <Tooltip.Content className="text-xs text-muted-foreground border border-gray-200 p-2 m-2 bg-white text-grey-600 z-50">
                        <p>
                          Go to Node Settings &gt; Share &gt; Invite by Secret
                          Link.
                        </p>
                        <br />
                        <p>
                          If you are not the owner ask the owner to invite you <br/>
                          to the node or parent node with &apos;Full Access&apos; permissions.
                        </p>
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </div>
                </CardTitle>
              )}
            </CardHeader>
            <CardContent>
              <div className="mb-4">
              </div>
              <form id="scrape-form" onSubmit={handleWorkflowyUrlSubmit} className="flex items-center">
                <Input
                  type="text"
                  id="node-url"
                  name="node-url"
                  value={nodeUrl}
                  onChange={(e) => setNodeUrl(e.target.value)}
                  required
                  className="w-full mr-2 h-9"
                  placeholder="Enter Workflowy Secret link"
                />
                <Button
                  type="submit"
                  variant="outline"
                  size="icon"
                  className="h-9 w-9"
                  disabled={wfloading}
                >
                  <FaLink size={15} />
                </Button>
              </form>
            </CardContent>
          </Card>
          <Card className="relative">
            <CardHeader className={isDrawer ? "pt-1" : ""}>
              {!isDrawer && (
                <CardTitle>
                  <div className="flex items-center">
                    Social Media Video
                    <span className="text-xs text-muted-foreground px-1">
                      (Beta)
                    </span>
                    {videoLoading && (
                      <Loader2 className="ml-2 text-gray-400 animate-spin" />
                    )}
                    <Tooltip.Root>
                      <Tooltip.Trigger>
                        <HelpCircle size={15} className="ml-1" />
                      </Tooltip.Trigger>
                      <Tooltip.Content className="text-xs text-muted-foreground border border-gray-200 p-2 m-2 bg-white text-grey-600 z-50">
                        <p>
                          Videos from these sources only will be transcribed <br/>
                          for use as context: YouTube, Twitter, TikTok, and Instagram.
                        </p>
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </div>
                </CardTitle>
              )}
            </CardHeader>
            <CardContent>
              <form className="flex items-center" onSubmit={handleIngestVideo}>
                <Input
                  type="text"
                  placeholder="Enter YouTube, Twitter, TikTok, or Instagram Video link"
                  className="w-full mr-2"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleIngestVideo(e);
                    }
                  }}
                />
                <Button
                  type="submit"
                  variant="outline"
                  size="icon"
                  rounded="full"
                  disabled={!videoUrl || videoLoading}
                >
                  <FaPaperPlane size={15} />
                </Button>
              </form>
            </CardContent>
          </Card>
  
        <Card className="relative">
          <CardHeader className={isDrawer ? "pt-1 pb-2 p-6" : "pb-2 p-6"}>
            {!isDrawer && (
              <CardTitle>
                <div className="flex items-center">
                  Web Crawling
                  {wcloading && (
                    <Loader2 className="ml-2 text-gray-400 animate-spin" />
                  )}
                </div>
              </CardTitle>
            )}
          </CardHeader>
          <CardContent>
            <form id="web-crawl-form" onSubmit={handleWebCrawlSubmit} className="space-y-4">
              <div className="flex items-center">
                <Input
                  type="url"
                  id="web-crawl-url"
                  name="web-crawl-url"
                  value={webCrawlUrl}
                  onChange={(e) => setWebCrawlUrl(e.target.value)}
                  required
                  className="flex-grow p-2 border border-gray-300 rounded-md"
                  placeholder="Enter Website link"
                />
                <Button
                  type="submit"
                  variant="outline"
                  size="icon"
                  className="ml-2 h-9 w-9"
                  disabled={loading}
                >
                  <FaLink size={15} />
                </Button>
              </div>
              <div className="flex items-center justify-between">
                <label htmlFor="max-depth" className="text-sm font-medium text-gray-700">
                <ChatSwitch
                  onToggle={handleMaxDepth}
                  initialState={maxDepth === 5}
                  onLabel="Entire Website"
                  offLabel="Single Page"
                  className="ml-2"
                />
                </label>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
      </div>
    </Tooltip.Provider>
  );
}
