import axios from 'axios';
import Cookies from 'js-cookie';
const API_URL = import.meta.env.VITE_APP_API_URL;


async function updateItemOnServer(indexReference, itemId, updates) {
  console.log(`Updating item on server for indexReference: ${indexReference}, itemId: ${itemId}`, updates);
  try {
    const url = `${API_URL}/api/v1/library/${indexReference}/${itemId}`;

    const response = await axios.patch(
      url,
      updates,
      { headers: await authHeaders() }
    );
    
    if (response.status === 204) {
      console.log('Item updated successfully');
    } else {
      throw new Error('Unexpected response status');
    }
  } catch (error) {
    console.error('Error updating item on server:', error);
  }
}



async function fetchLibrary() {
  console.log("fetching library for user", user);
  if (!user) return;
  try {
    const response = await axios.get(`${API_URL}/api/v1/library/`, {
      headers: await authHeaders()
    });
    setLibrary(response.data.items);
    return response.data.items;
  } catch (error) {
    console.error('Error fetching library:', error);
    return null;
  }
}

async function createLibrary(libraryData) {
  try {
    const response = await axios.post(`${API_URL}/api/v1/library/`, libraryData, {
      headers: await authHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error creating library:', error);
    return null;
  }
}

async function editLibrary(libraryId, libraryData) {
  try {
    const response = await axios.put(`${API_URL}/api/v1/library/${libraryId}`, libraryData, {
      headers: await authHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error editing library:', error);
    return null;
  }
}

async function deleteLibrary(libraryId) {
  try {
    await axios.delete(`${API_URL}/api/v1/library/${libraryId}`, {
      headers: await authHeaders()
    });
    fetchLibrary();
    return true;
  } catch (error) {
    console.error('Error deleting library:', error);
    return false;
  }
}

export const convertMimeType = (mimeType) => {
  const mimeTypeToShortName = {
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "Word Doc",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "Excel Sheet",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": "PowerPoint",
    "application/msword": "Word",
    "application/vnd.ms-excel": "Excel",
    "application/vnd.ms-powerpoint": "PowerPoint",
    "application/pdf": "PDF",
    "text/plain": "Plain Text",
    "application/zip": "ZIP",
    "application/x-rar-compressed": "RAR",
    "application/vnd.google-apps.document": "Google Doc",
    "application/vnd.google-apps.spreadsheet": "Google Sheet",
    "application/vnd.google-apps.presentation": "Google Slide",
    "image/jpeg": "JPEG",
    "image/png": "PNG",
    "image/gif": "GIF",
    "image/svg+xml": "SVG",
    "application/json": "JSON",
    "application/javascript": "JavaScript",
    "application/vnd.google-apps.form": "Google Form",
    "audio/mpeg": "MP3",
    "audio/ogg": "OGG",
    "video/mp4": "MP4",
    "video/x-msvideo": "AVI",
    "video/webm": "WebM",
    "application/rtf": "RTF",
    "application/vnd.oasis.opendocument.text": "OpenDoc Text",
    "application/vnd.oasis.opendocument.spreadsheet": "OpenDoc Sheet",
    "application/vnd.oasis.opendocument.presentation": "OpenDoc Slide",
    "application/x-tar": "TAR",
    "application/x-7z-compressed": "7-Zip",
    "application/x-bzip2": "Bzip2",
    "application/vnd.apple.pages": "Pages",
    "application/vnd.apple.numbers": "Numbers",
    "application/vnd.apple.keynote": "Keynote",
    "text/csv": "CSV",
    "text/html": "HTML",
    "application/xml": "XML",
    "application/vnd.google-apps.drawing": "Google Drawing",
    "application/vnd.google-apps.script": "Google Script",
    "image/tiff": "TIFF",
    "audio/wav": "WAV",
    "application/x-sh": "Shell Script",
    "application/x-httpd-php": "PHP",
    "application/octet-stream": "Binary File",
    "video/vnd.dlna.mpeg-tts": "TypeScript",
    "text/texmacs": "TypeScript"
  };

  return mimeTypeToShortName[mimeType] || (mimeType.includes("/") ? mimeType.split("/")[1].split(".").pop().toUpperCase() : "Unknown");
};