import { useEffect, useState } from "react";
import { useData } from "../contexts/DataContext";
import { useUser } from "@clerk/clerk-react";


const ProfileSummary = () => {
    const { UsersAPI } = useData();
    const [profileSummary, setProfileSummary] = useState(null);
    const { user } = useUser();

    useEffect(() => {
        const getProfileSummary = async () => {
            const rsp = await UsersAPI.getProfileSummary(user?.primaryEmailAddress?.emailAddress)
            if(rsp.length > 0) {
                setProfileSummary(rsp[0]);
            }
        }
        getProfileSummary();
    }, [UsersAPI, user]);

    const formattedProfileSummary = (profileSummary) => {
        if (!profileSummary) return "No profile outline available!";

        // Use regex to extract sections marked with **
        const regex = /\*\*(.*?)\*\*\s*(.*?)(?=\s*\*\*|$)/gs;
        const sections = Array.from(profileSummary.matchAll(regex), match => ({
            title: match[1].trim(),
            content: match[2].trim()
        }));

        if (sections.length === 0) {
            return (
                <div>
                    <p>Issue rendering this profile summary:</p>
                    <pre>{profileSummary}</pre>
                </div>
            );
        }

        return (
            <div>
                {sections.map((section, index) => (
                    <div key={index}>
                        <br />
                        <h2>{section.title}</h2>
                        <p>{section.content}</p>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div>
            {formattedProfileSummary(profileSummary?.profile_outline)}
        </div>
    );
};

export default ProfileSummary;