import React from 'react';
import { GoCodeSquare } from "react-icons/go";
import { IoDocument } from 'react-icons/io5';
import { LuTrophy } from "react-icons/lu";

const RightSidebar = ({ onPanelSelect }) => {
  const handlePanelSelect = (panelName) => {
    onPanelSelect(panelName);
  };

  return (
    <div className='_right-sidebar'>
      <div className='_right-sidebar-content'>
        <div className='_right-sidebar-option' onClick={() => handlePanelSelect('artifacts')}>
          <GoCodeSquare />
          <span>Artifacts</span>
        </div>
        <div className='_right-sidebar-option' onClick={() => handlePanelSelect('sources')}>
          <IoDocument />
          <span>Sources</span>
        </div>
        <div className='_right-sidebar-option' onClick={() => handlePanelSelect('slm_leaderboard')}>
          <LuTrophy />
          <span>SLM Leaderboard</span>
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;
