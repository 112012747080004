import React from 'react';
import { useData } from '../contexts/DataContext';
import { FaChevronRight, FaFile, FaGlobe } from 'react-icons/fa';
import EphorLogo from './custom-icons/EphorLogo';
import GoogleDocsLogo from './custom-icons/GoogleDocsLogo';
import GoogleSheetsLogo from './custom-icons/GoogleSheetsLogo';
import GoogleDriveLogo from './custom-icons/GoogleDriveLogo';

const Sources = () => {
  const { sources, setIsPanelVisible, setSelectedPanel, activeProjectId } = useData();

  const handleHide = () => {
    setSelectedPanel(null);
    setIsPanelVisible(false);
  };

  // Deduplicate sources by ID before the '#' mark and exclude "folder_structure"
  const uniqueSources = [];
  const ids = new Set();

  sources.forEach(source => {
    const idWithoutHash = source.id.split('#')[0];
    if (!ids.has(idWithoutHash) && idWithoutHash !== "folder_structure") {
      ids.add(idWithoutHash);
      uniqueSources.push(source);
    }
  });

  // Patterns to match Google Drive IDs and UUIDs
  const googleDriveIdPattern = /^[a-zA-Z0-9_-]{10,}$/;
  const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[4|5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

  // Function to get the appropriate icon
  const getSourceIcon = (source) => {
    const idWithoutHash = source.id.split('#')[0];

    if (source.mime_type === 'application/vnd.google-apps.document') {
      return <GoogleDocsLogo />
    } else if (source.mime_type === 'application/vnd.google-apps.spreadsheet') {
      return <GoogleSheetsLogo />
    } else if (source.mime_type === 'text/html') {
      return <FaGlobe />;
    } else if (uuidPattern.test(idWithoutHash)) {
      return <EphorLogo />;
    } else if (googleDriveIdPattern.test(idWithoutHash)) {
      return <GoogleDriveLogo />
    } else {
      return <FaFile className="icon default-icon" />;
    }
  };

  // Function to get the link to the document
  const getSourceLink = (source) => {
    const idWithoutHash = source.id.split('#')[0];

    if (source.mime_type === 'application/vnd.google-apps.document') {
      return `https://docs.google.com/document/d/${idWithoutHash}/view`;
    } else if (source.mime_type === 'application/vnd.google-apps.spreadsheet') {
      return `https://docs.google.com/spreadsheets/d/${idWithoutHash}/view`;
    } else if (uuidPattern.test(idWithoutHash)) {
      return `/projects/${activeProjectId}/chat/${idWithoutHash}`;
    } else if (googleDriveIdPattern.test(idWithoutHash)) {
      return `https://drive.google.com/file/d/${idWithoutHash}/view`;
    } else {
      return '';
    }
  };

  return (
    <div className="_sources-container">
      <div className="_header">
        <h2>Sources</h2>
        <button onClick={handleHide}>
          <FaChevronRight />
        </button>
      </div>
      <div className="_sources-list">
        {uniqueSources.map((source, index) => {
          const datePart = source.id.split('#')[1]?.split('__')?.[0] || '';
          const link = getSourceLink(source);
          const IconComponent = getSourceIcon(source);

          return (
            <div
              key={index}
              className="_source"
              onClick={() => link && window.open(link, '_blank')}
            >
              <div className="_source-title">
                {IconComponent}
                <div className="_source-details">
                  <h3 className="_source-name">{source.file_name}</h3>
                  <p><em>{datePart ? new Date(datePart).toLocaleString(
                    'en-US',
                    { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }
                  ) : "No Date Available"}</em></p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Sources;
