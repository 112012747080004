import React, { useState, useEffect } from 'react';
import { LuPlus, LuTrash, LuTrash2, LuRefreshCw } from "react-icons/lu";
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { useData } from '../contexts/DataContext';
import { getRelativeTimeString } from '../lib/utils';
import { toast } from 'react-hot-toast';
import BrainDumpMenu from './BrainDumpMenu';

const Projects = () => {
  const [filterText, setFilterText] = useState('');
  const [projectVisibilityFilter, setProjectVisibilityFilter] = useState('ALL');
  const { projects, isLoading, UsersAPI, ProjectsAPI, ChannelsAPI } = useData();
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);
  const [projectsWithUsers, setProjectsWithUsers] = useState([]);
  const [isUpdatingProjects, setIsUpdatingProjects] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    const fetchProjectsWithUsers = async () => {
      if (projects) {
        console.log(projects);
        const projectsWithUserData = await Promise.all(
          projects.map(async (project) => {
            const user = await UsersAPI.getUserProfile(project.owner_id);
            return { ...project, user };
          })
        );
        setProjectsWithUsers([
          ...projectsWithUserData.filter(project => project.status !== 'deleted'),
          ...projectsWithUserData.filter(project => project.status === 'deleted')
        ]);
        setIsLoadingProjects(false);
      } else {
        setIsLoadingProjects(false);
      }
    };

    fetchProjectsWithUsers();

    // Push event to Google Tag Manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'dashboard_viewed',
      'owner_project_number': projects.filter(p => p.owner_id === user.id).length,
      'joined_project_number': projects.filter(p => p.owner_id !== user.id).length
    });
  }, [projects, UsersAPI]);


  const handleRestoreProject = async (projectId) => {
    try {
      setIsUpdatingProjects(true);
      await ProjectsAPI.restoreProject(projectId);
      toast.success(`Project restored successfully.`);
      setProjectVisibilityFilter('ALL');
      const updatedProjects = projectsWithUsers.map(project => 
        project.project_id === projectId ? { ...project, status: 'active' } : project
      );
      setProjectsWithUsers(updatedProjects);
    } catch (error) {
      console.error(`Failed to restore project ${projectId}:`, error);
    } finally {
      setIsUpdatingProjects(false);
    }
  };

  const handleDeleteProject = async (projectId) => {
    try {
      setIsUpdatingProjects(true);
      await ProjectsAPI.deleteProject(projectId);
      toast.success(`Project deleted successfully.`);
      setProjectVisibilityFilter('ALL');
      const updatedProjects = projectsWithUsers.filter(project => project.project_id !== projectId);
      setProjectsWithUsers(updatedProjects);
    } catch (error) {
      console.error(`Failed to delete project ${projectId}:`, error);
    } finally {
      setIsUpdatingProjects(false);
    }
  };

  const handleExport = async (exportTarget, exportFormat, includeArtifacts, includeSLMData, projectId) => {
    console.log("Export Target:", exportTarget);
    console.log("Export Format:", exportFormat);
    console.log("Include Artifacts:", includeArtifacts);
    console.log("Include SLM Data:", includeSLMData);
    console.log("Project ID:", projectId);
    try {
      const projectName = projectsWithUsers.find(p => p.project_id === projectId)?.name || "unknown_project";
      const fileExtension = "zip";
      const fileName = `${projectName} - ${exportFormat.toUpperCase()}`.trim();
      await ChannelsAPI.exportData(projectId, exportTarget, exportFormat, null, fileName, fileExtension, includeArtifacts, includeSLMData);
      toast.success(`Project export initiated successfully.`);
      
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'download_chat',
        'chat_type': "project",
        'output': exportFormat,
        'include_artifact': includeArtifacts,
        'include_slm_data': includeSLMData
      });
    } catch (error) {
      console.error(`Error exporting project:`, error);
      toast.error("Internal server error. Please try again later.");
    }
  };

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const filteredProjects = projectsWithUsers.filter((item) => {
    const matchesFilterText = !filterText || Object.values(item).some((value) =>
      String(value).toLowerCase().includes(filterText.toLowerCase())
    );

    const matchesVisibilityFilter = 
      (projectVisibilityFilter === 'ALL') ||
      (projectVisibilityFilter === 'USER' && item.owner_id === user.id);

    return matchesFilterText && matchesVisibilityFilter;
  });

  if (isLoading || isLoadingProjects) {
    return <div>Loading projects...</div>;
  }

  return (
    <div className="_projects-container">
      <div className="_project-actions-container">
        <input
          className='_project-action-search'
          placeholder='Search Projects'
          value={filterText}
          onChange={handleFilterChange}
          type='text'
        />
        <span className='_project-action-buttons'>
          <button className="_project-action-add" onClick={() => navigate('/projects/new')}>
            <LuPlus /><span>Create Project</span>
          </button>
          <button className="_project-action-toggle" onClick={() => setProjectVisibilityFilter(projectVisibilityFilter === 'ALL' ? 'USER' : 'ALL')}>
            <span>{projectVisibilityFilter === 'ALL' ? 'View your projects' : 'View all projects'}</span>
          </button>
        </span>
      </div>
      <div className="_projects-explorer hide-scrollbar">
        {filteredProjects.map((item) => (
          item.status !== 'deleted' ? (
            <Link
              key={item.project_id}
              className='_project-card'
              to={`/projects/${item.project_id}`}
              state={{ project: item }}
            >
              <span className='_project-card-title'>{item.name}</span>
              <span className='_project-card-description'>{item.description}</span>
              <span className='_project-card-info-wrapper'>
                <span className='_project-card-info'>Created by {item.user?.name}</span>
                {item.updated_at && (
                  <span className='_project-card-info'>Updated {getRelativeTimeString(new Date(item.updated_at))}</span>
                )}
              </span>
            </Link>
          ) : (
            <div key={item.project_id} className='_project-card _project-delete-card'>
              <div className="flex justify-between w-full">
                <span className='_project-card-title'>{item.name}</span>
                <div className="ml-auto">
                  <BrainDumpMenu 
                    className="_project_brain_dump_menu" 
                    onExport={handleExport} 
                    showExportTarget={false} 
                    alignment="left" 
                    projectId={item.project_id}
                  />
                </div>
              </div>
              <span className='_project-card-description'>{item.description}</span>
              <span className='_project-card-info-wrapper'>
                {item.owner_id === user.id ? (
                  <div className='_project-card-actions'>
                    <button 
                      className='_project-card-button' 
                      onClick={() => handleRestoreProject(item.project_id)}
                      disabled={isUpdatingProjects}
                    >
                      <div className="flex items-center">
                        <LuRefreshCw className="mr-1" />
                        <span>Restore</span>
                      </div>
                    </button>
                    <button 
                      className='_project-card-button' 
                      onClick={() => handleDeleteProject(item.project_id)}
                      disabled={isUpdatingProjects}
                    >
                      <div className="flex items-center">
                        <LuTrash2 className="mr-1" />
                        <span>Delete Permanently</span>
                      </div>
                    </button>
                  </div>
                ) : (
                  <span className='_project-card-info'>Deleted by {item.user?.name}</span>
                )}
                {item.updated_at && (
                  <span className='_project-card-info'>Deleted {getRelativeTimeString(new Date(item.updated_at))}</span>
                )}
              </span>
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default Projects;
