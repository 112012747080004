import { SignIn } from "@clerk/clerk-react";

const SignInContainer = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const redirect = new URLSearchParams(window.location.search).get('redirect');
  const from = new URLSearchParams(window.location.search).get('from');
  const inviteId = urlParams.get("inviteId");
  const joinId = urlParams.get("joinId");

  let signUpUrl = "/onboard";
  if (joinId) {
    signUpUrl = `/onboard?joinId=${joinId}`;
  } else if (inviteId) {
    signUpUrl = `/onboard?inviteId=${inviteId}`;
  }

  const redirectFinder = (joinId, from) => {
    if (joinId) {
      return `/join/${joinId}`;
    }
    else if (from) {
      return from;
    } else {
      return window.location.pathname || "/";
    }
  }

  return (
    <div className="_sign-in-container">
      <div className={`${redirect ? "_signInContainerReconnect" : ""}`}>
        <SignIn
          signUpForceRedirectUrl={signUpUrl}
          forceRedirectUrl={redirectFinder(joinId, from)}
          appearance={{
            elements: {
              rootBox: {
                width: "100%",
                maxWidth: "400px",
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default SignInContainer;
