import React from 'react';
import { motion } from 'framer-motion';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CloseIcon from '@mui/icons-material/Close';

const AutoFixButton = ({ error, onClick, onDiscard }) => {
  return (
    <>
      <motion.button
        className="flex items-center gap-2 bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full shadow-lg"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ type: 'spring', stiffness: 500, damping: 30 }}
        onClick={onClick}
      >
        <AutoFixHighIcon />
        <span>Auto Fix</span>
        <motion.div
          className="absolute inset-0 rounded-full"
          initial={{ scale: 0, opacity: 0.5 }}
          animate={{ scale: [0, 1.2, 1], opacity: [0.5, 0.8, 0] }}
          transition={{ repeat: Infinity, duration: 2 }}
          style={{ border: '2px solid white' }}
        />
      </motion.button>
      <motion.button
        className="bg-gray-600 hover:bg-gray-700 text-white font-bold p-2 rounded-full shadow-lg"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ type: 'spring', stiffness: 500, damping: 30 }}
        onClick={onDiscard}
      >
        <CloseIcon />
      </motion.button>
    </>
  );
};

export default AutoFixButton;
