import dedent from "ts-dedent";

const replacements = [
  { from: '../components/custom/', to: './' },
  { from: '../hooks/', to: './' },
  { from: '../../hooks/', to: './' },
  { from: '../lib/', to: './' },
  { from: '../../lib/', to: './' },
  { from: '../components/ui/', to: '@/components/ui/' },
  { from: '../ui/', to: '@/components/ui/' },
];

export function replaceImportPaths(code) {
  let updatedCode = code;

  for (const replacement of replacements) {
    const regex = new RegExp(`from\\s+(['"])(${replacement.from})`, 'g');
    updatedCode = updatedCode.replace(regex, (fullMatch, quote, path) => {
      return `from ${quote}${replacement.to}`;
    });
  }

  return updatedCode;
}

// Function to add new replacements
export function addReplacement(from, to) {
  replacements.push({ from, to });
}

export function sanitizeCode(code) {
  return replaceImportPaths(code)
}

export function getImportStatement(artifactName) {
    // return "import App from '@/dashboard'";

    if (!artifactName) {
        return "import App from './App'"
    } else {
        return `import App from '@/${toCamelCaseFolderName(artifactName)}'`
    }
}

export function getIndexCode(artifactName) {

    return dedent`
    import React from 'react';
    import ReactDOM from 'react-dom/client';
    import './tailwind.css';
    import reportWebVitals from './reportWebVitals';

    ${getImportStatement(artifactName)}

    const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
    );
    root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
    `
}

/**
 * Extracts the base name from a given path.
 * @param path The full path string.
 * @returns The base name of the path.
 */
export function getBaseName(path) {
  // Remove trailing slashes
  path = path.replace(/[/\\]+$/, '');
  
  // Split the path by slashes or backslashes
  const parts = path.split(/[/\\]/);
  
  // Return the last part (base name)
  return parts[parts.length - 1] || '';
}

/**
 * Converts a name to a camel case valid folder name.
 * @param name The input name, which may contain spaces and mixed case letters.
 * @returns A camel case valid folder name.
 */
export function toCamelCaseFolderName(name) {
  return name
    .split(/[\s-_]+/) // Split on spaces, hyphens, and underscores
    .map((word, index) => {
      if (index === 0) {
        // First word should be lowercase
        return word.toLowerCase();
      }
      // Capitalize the first letter of other words
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('')
    .replace(/[^a-zA-Z0-9]/g, ''); // Remove any non-alphanumeric characters
}
