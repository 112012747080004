const EphorLogo = () =>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.99 184.72">
    <defs>
      <style>
        {`
          .cls-1 {fill: #5a1f5e;}

          .cls-2 {fill: #d7c3df;}

          .cls-3 {fill: #925b95;}
        `}
      </style>
    </defs>
    <g>
      <polygon className="cls-2" points="159.99 83.03 128.15 64.57 159.99 46.19 80 0 64.37 9.11 64.33 9.13 64.33 27.51 48.52 18.23 32.4 27.51 32.36 27.54 32.36 45.92 16.54 36.64 0 46.19 31.9 64.61 0 83.03 31.91 101.45 0 119.88 80 166.06 159.99 119.88 128.08 101.45 159.99 83.03"/>
      <g>
        <polygon className="cls-3" points="127.85 46.06 64.33 9.13 64.33 27.51 111.89 55.28 127.85 46.06"/>
        <polygon className="cls-3" points="95.96 64.48 32.4 27.51 32.36 27.54 32.36 45.92 80 73.69 95.96 64.48"/>
        <polygon className="cls-3" points="159.99 64.77 159.99 46.19 80 92.37 0 46.19 0 138.4 0 138.53 80 184.72 159.99 138.53 159.99 119.95 80 166.06 15.86 129.05 15.86 110.75 80 147.84 159.99 101.65 159.99 83.07 80 129.25 15.9 92.19 15.85 73.91 80 110.96 159.99 64.77"/>
      </g>
    </g>
    <g>
      <polygon className="cls-1" points="31.72 83.09 15.86 73.9 15.9 92.18 31.72 83.09"/>
      <polygon className="cls-1" points="15.83 129.07 31.79 119.96 15.86 110.75 15.83 129.07"/>
      <polygon className="cls-1" points="80.04 147.81 159.99 101.65 159.99 83.07 80.04 129.23 80.04 147.81"/>
      <polygon className="cls-1" points="80.04 184.69 159.99 138.53 159.99 119.95 80.04 166.04 80.04 184.69"/>
      <polygon className="cls-1" points="80.04 92.35 80.04 110.93 159.99 64.77 159.99 46.19 80.04 92.35"/>
    </g>
  </svg>


export default EphorLogo;